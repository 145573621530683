import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

const baseApiUrl = 'api/carers-overview';
const baseSearchApiUrl = 'api/_search/carers?query=';

export default class CarerOverviewService {
  public search(query, incArchivedCarers: boolean, paginationQuery): Promise<any> {
    const urlAddition = incArchivedCarers === true ? '/with-archived-carers' : '';
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`api/_search/carers${urlAddition}?query=${query}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(incArchivedCarers: boolean, paginationQuery?: any): Promise<any> {
    const urlAddition = incArchivedCarers === true ? '/with-archived-carers' : '';
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `${urlAddition}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
