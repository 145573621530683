import { Authority } from '@/shared/security/authority';

const CustomerDetailComponent = () => import('@/customer-detail/customer-detail.vue');

const EnterMessageComponent = () => import('@/usertasks/enter-message/enter-message.vue');
const Questionnaire = () => import('@/questionnaire/questionnaire.vue');
const CreateCarerProposal = () => import('@/usertasks/create-carer-proposal/create-carer-proposal.vue');
const ProposalToCustomer = () => import('@/usertasks/proposal-to-customer/proposal-to-customer.vue');
const CarerProposalCustomerDecision = () => import('@/usertasks/carer-proposal-customer-decision/carer-proposal-customer-decision.vue');
const CarerProposalCustomerDate = () => import('@/usertasks/carer-proposal-customer-date/carer-proposal-customer-date.vue');
const CustomerProposalPrepareDocumentsMainAgency = () =>
  import('@/usertasks/customer-proposal-prepare-documents-main-agency/customer-proposal-prepare-documents-main-agency.vue');
const CustomerProposalPrepareDocumentsSubAgency = () =>
  import('@/usertasks/customer-proposal-prepare-documents-sub-agency/customer-proposal-prepare-documents-sub-agency.vue');
const CarerSelectionComponent = () => import('@/usertasks/carer-selection/carer-selection.vue');
const EnterCustomerSigning = () => import('@/usertasks/enter-customer-signing/enter-customer-signing.vue');
const ForwardDocumentsMainAgencyComponent = () => import('@/usertasks/forward-documents-main-agency/forward-documents-main-agency.vue');
const SendMinijobComponent = () => import('@/usertasks/send-minijob/send-minijob.vue');
const ForwardDocumentsSubAgencyComponent = () => import('@/usertasks/forward-documents-sub-agency/forward-documents-sub-agency.vue');
const CheckInvoicesComponent = () => import('@/usertasks/check-invoices/check-invoices.vue');
const CheckInvoicePaymentsComponent = () => import('@/usertasks/check-invoice-payments/check-invoice-payments.vue');
const TaskReminder = () => import('@/usertasks/task-reminder/task-reminder.vue');
const CustomerTask = () => import('@/usertasks/customer-task/customer-task.vue');

const HealthInsuranceExt = () => import('@/usertasks/health-insurance-ext/health-insurance-ext.vue');
const CreateFirstInvoiceComponent = () => import('@/usertasks/create-first-invoice/create-first-invoice.vue');

const SwitchCarerDoings = () => import('@/usertasks/switch-carer-doings/switch-carer-doings.vue');
const CareAssignmentTerminationStuff = () => import('@/usertasks/care-assignment-termination-stuff/care-assignment-termination-stuff.vue');
const CareAssignmentTerminationStuffSubAgency = () =>
  import('@/usertasks/care-assignment-termination-stuff-sub-agency/care-assignment-termination-stuff-sub-agency.vue');
const CustomerDetail = () => import('@/customer-detail/customer-detail.vue');

export default [
  {
    path: '/usertask/enter-message',
    name: 'enter-message',
    component: EnterMessageComponent,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/check-questionnaire',
    name: 'check-questionnaire',
    component: CustomerDetail,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/fullfill-questionnaire',
    name: 'fullfill-questionnaire',
    component: Questionnaire,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/create-carer-proposal',
    name: 'create-carer-proposal',
    component: CreateCarerProposal,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/carer-proposal-to-customer',
    name: 'carer-proposal-to-customer',
    component: ProposalToCustomer,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/carer-proposal-customer-decision',
    name: 'carer-proposal-customer-decision',
    component: CarerProposalCustomerDecision,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/carer-proposal-customer-date',
    name: 'carer-proposal-customer-date',
    component: CarerProposalCustomerDate,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/customer-proposal-prepare-documents-main-agency',
    name: 'customer-proposal-prepare-documents-main-agency',
    component: CustomerProposalPrepareDocumentsMainAgency,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/customer-proposal-prepare-documents-sub-agency',
    name: 'customer-proposal-prepare-documents-sub-agency',
    component: CustomerProposalPrepareDocumentsSubAgency,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/customer-proposal-prepare-documents-main-agency-split',
    name: 'customer-proposal-prepare-documents-main-agency-split',
    component: CustomerProposalPrepareDocumentsMainAgency,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/customer-proposal-prepare-documents-sub-agency-split',
    name: 'customer-proposal-prepare-documents-sub-agency-split',
    component: CustomerProposalPrepareDocumentsSubAgency,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/enter-customer-signing',
    name: 'enter-customer-signing',
    component: EnterCustomerSigning,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/carer-selection',
    name: 'carer-selection',
    component: CarerSelectionComponent,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/forward-documents-main-agency',
    name: 'forward-documents-main-agency',
    component: ForwardDocumentsMainAgencyComponent,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/send-minijob',
    name: 'send-minijob',
    component: SendMinijobComponent,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/forward-documents-sub-agency',
    name: 'forward-documents-sub-agency',
    component: ForwardDocumentsSubAgencyComponent,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/check-invoices',
    name: 'check-invoices',
    component: CheckInvoicesComponent,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/check-invoice-payments',
    name: 'check-invoice-payments',
    component: CheckInvoicePaymentsComponent,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/task-reminder',
    name: 'task-reminder',
    component: TaskReminder,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/customer-task/:customerId/:tabIndex/:contactPersonId/:patientId',
    name: 'customer-task',
    component: CustomerTask,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/health-insurance-ext',
    name: 'health-insurance-ext',
    component: HealthInsuranceExt,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/create-first-invoice',
    name: 'create-first-invoice',
    component: CreateFirstInvoiceComponent,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/switch-carer-doings',
    name: 'switch-carer-doings',
    component: SwitchCarerDoings,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/care-assignment-termination-stuff',
    name: 'care-assignment-termination-stuff',
    component: CareAssignmentTerminationStuff,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/usertask/:taskId/care-assignment-termination-stuff-sub-agency',
    name: 'care-assignment-termination-stuff-sub-agency',
    component: CareAssignmentTerminationStuffSubAgency,
    meta: { authorities: [Authority.USER] },
  },
];
