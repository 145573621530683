import { Authority } from '@/shared/security/authority';

// prettier-ignore
const AgencyOverview = () => import('@/agency-overview/agency-overview.vue');
const AgencyDetailComponent = () => import('@/agency-detail/agency-detail.vue');
const AgencyCoreUpdate = () => import('@/agency-detail/agency-update-core.vue');
const AgencyContactUpdate = () => import('@/agency-detail/agency-update-contact.vue');
const AgencyUpdateSettingsComponent = () => import('@/agency-detail/agency-update-settings.vue');
export default [
  {
    path: '/agency-overview',
    name: 'AgencyOverview',
    component: AgencyOverview,
    meta: { authorities: [Authority.MANAGER] },
  },
  {
    path: '/agency-detail/:agencyId/editCore',
    name: 'AgencyCoreUpdate',
    component: AgencyCoreUpdate,
    meta: { authorities: [Authority.MANAGER] },
  },
  {
    path: '/agency-detail/:agencyId/editContact',
    name: 'AgencyContactUpdate',
    component: AgencyContactUpdate,
    meta: { authorities: [Authority.MANAGER] },
  },
  {
    path: '/agency-detail/:agencyId/editSettings',
    name: 'AgencySettingsUpdate',
    component: AgencyUpdateSettingsComponent,
    meta: { authorities: [Authority.MANAGER] },
  },
  {
    path: '/agency-detail/:agencyId/:tabIndex',
    name: 'AgencyDetail',
    component: AgencyDetailComponent,
    meta: { authorities: [Authority.MANAGER] },
  },
];
