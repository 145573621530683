export enum FrageAlter {
  KEINE_PRAEFERENZ = 'keine_praeferenz',

  T20_30 = 't20_30',

  T30_40 = 't30_40',

  T40_50 = 't40_50',

  AELTER_ALS_50 = 'aelter_als_50',
}
