export enum TransportVehicle {
  GERMANTRAIN = 'germantrain',

  PKP = 'pkp',

  FLIXBUS = 'flixbus',

  SINDBAD = 'sindbad',

  EUROLINE = 'euroline',

  KAUTRA = 'kautra',
}
