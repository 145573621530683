import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';

/**
 * An utility service for routing.
 */
@Component
export default class RouterUtils extends Vue {
  public push(routeName: string, routeParams: any): Promise<Route> {
    return this.$router.push({
      name: routeName,
      params: routeParams == null ? {} : routeParams,
    });
  }

  public replace(routeName: string, routeParams: any): Promise<Route> {
    return this.$router.replace({
      name: routeName,
      params: routeParams == null ? {} : routeParams,
    });
  }

  public previousState(): void {
    this.$router.go(-1);
  }
}
