import { ICustomer } from '@/shared/model/customer.model';

export interface ISalary {
  id?: number;
  amount?: number | null;
  validFrom?: Date | null;
  text?: string | null;
  customer?: ICustomer | null;
}

export class Salary implements ISalary {
  constructor(
    public id?: number,
    public amount?: number | null,
    public validFrom?: Date | null,
    public text?: string | null,
    public customer?: ICustomer | null
  ) {}
}
