export enum CommunicationType {
  TELEPHONE = 'TELEPHONE',

  MOBILEPHONE = 'MOBILEPHONE',

  EMAIL = 'EMAIL',

  FAX = 'FAX',

  MESSANGER = 'MESSANGER',

  EMERGENCYPHONE = 'EMERGENCYPHONE',

  PRIVATE = 'PRIVATE',

  BUSINESS = 'BUSINESS',

  WHATSAPP = 'WHATSAPP',

  FACEBOOK = 'FACEBOOK',

  INSTAGRAM = 'INSTAGRAM',
}
