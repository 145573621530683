import { IContact } from '@/shared/model/contact.model';

import { CommunicationType } from '@/shared/model/enumerations/communication-type.model';
export interface ICommunication {
  id?: number;
  communicationType?: CommunicationType | null;
  contactValue?: string | null;
  contact?: IContact | null;
}

export class Communication implements ICommunication {
  constructor(
    public id?: number,
    public communicationType?: CommunicationType | null,
    public contactValue?: string | null,
    public contact?: IContact | null
  ) {}
}
