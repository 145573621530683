var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "vue-html2pdf",
        {
          ref: "realCarerSuggestionHtml2Pdf",
          attrs: {
            "enable-download": false,
            "paginate-elements-by-height": 1100,
            "preview-modal": true,
          },
        },
        [
          _c(
            "section",
            { attrs: { slot: "pdf-content" }, slot: "pdf-content" },
            [
              _vm.carerSuggestion
                ? _c("div", { staticClass: "row justify-content-center" }, [
                    _c("div", { staticClass: "col-8" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "b-container",
                            [
                              _c("b-row", [_c("b-col", [_vm._v(" ")])], 1),
                              _vm._v(" "),
                              _c("b-row", [_c("b-col", [_vm._v(" ")])], 1),
                              _vm._v(" "),
                              _c("b-row", [_c("b-col", [_vm._v(" ")])], 1),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                { attrs: { "align-h": "center" } },
                                [
                                  _c("b-col", { staticClass: "text-right" }, [
                                    _c("h2", [
                                      _c(
                                        "span",
                                        {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.carerSuggestion.pdfDocument.title"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Personell suggestion")]
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("b-row", [_c("b-col", [_vm._v(" ")])], 1),
                              _vm._v(" "),
                              _c("b-row"),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm.carerSuggestion.carer &&
                                    _vm.carerSuggestion.carer.image
                                      ? _c("h3", [
                                          _c("img", {
                                            staticStyle: {
                                              "max-height": "200px",
                                            },
                                            attrs: {
                                              alt: "carer image",
                                              src:
                                                "data:" +
                                                _vm.carerSuggestion.carer
                                                  .imageContentType +
                                                ";base64," +
                                                _vm.carerSuggestion.carer.image,
                                            },
                                          }),
                                        ])
                                      : _c("h3", [
                                          _c("img", {
                                            staticStyle: {
                                              "max-height": "200px",
                                            },
                                            attrs: {
                                              alt: "carer image",
                                              src: require("../../../content/images/dummycarer.jpg"),
                                            },
                                          }),
                                        ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-col", { attrs: { cols: "2" } }, [
                                    _vm._v(" "),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: { "align-self": "end", cols: "6" },
                                    },
                                    [
                                      _vm.carerSuggestion.carer
                                        ? _c("h4", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.carerSuggestion.carer
                                                    .contact.firstName +
                                                    " " +
                                                    _vm.carerSuggestion.carer
                                                      .contact.lastName
                                                )
                                              ),
                                            ]),
                                          ])
                                        : _c("h4", [
                                            _c("br"),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "strong",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.carerSuggestion.sugestionWithoutCarerForPdf"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Without carer")]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [_vm._v(" ")]),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticStyle: {
                                                height: "3px",
                                                border: "none",
                                                color: "#a0d3e4",
                                                "background-color": "#a0d3e4",
                                              },
                                            }),
                                          ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("b-row", [_c("b-col", [_vm._v(" ")])], 1),
                              _vm._v(" "),
                              _c("b-row", [_c("b-col", [_vm._v(" ")])], 1),
                              _vm._v(" "),
                              _vm.carerSuggestion.carer
                                ? _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticStyle: {
                                            margin: "0px",
                                            padding: "0px",
                                            "padding-bottom": "5px",
                                            "border-right-style": "solid",
                                            "border-right-width": "3px",
                                            "border-right-color": "#a0d3e4",
                                            "border-bottom-style": "solid",
                                            "border-bottom-width": "3px",
                                            "border-bottom-color": "#a0d3e4",
                                          },
                                          attrs: { cols: "5" },
                                        },
                                        [
                                          _c("strong", [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.carerSuggestion.age"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("age")]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.carerSuggestion.carer
                                        ? _c(
                                            "b-col",
                                            {
                                              staticStyle: {
                                                margin: "0px",
                                                "padding-left": "10px",
                                                "padding-bottom": "5px",
                                                "border-bottom-style": "solid",
                                                "border-bottom-width": "3px",
                                                "border-bottom-color":
                                                  "#a0d3e4",
                                              },
                                              attrs: { cols: "7" },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.calculateCurrentAge(
                                                      _vm.carerSuggestion.carer
                                                        .birthday
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                        "padding-bottom": "5px",
                                        "padding-top": "10px",
                                        "border-right-style": "solid",
                                        "border-right-width": "3px",
                                        "border-right-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carerSuggestion.desiredSalary"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Salary")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        "padding-left": "10px",
                                        "padding-bottom": "5px",
                                        "padding-top": "10px",
                                      },
                                      attrs: { cols: "7" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toCurrency")(
                                              _vm.forceToNumber(
                                                _vm.carerSuggestion
                                                  .desiredSalary
                                              )
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                        "padding-bottom": "10px",
                                        "border-right-style": "solid",
                                        "border-right-width": "3px",
                                        "border-right-color": "#a0d3e4",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carerSuggestion.totalCosts"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("totalCosts")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        "padding-left": "10px",
                                        "padding-bottom": "10px",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "7" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toCurrency")(
                                              _vm.forceToNumber(
                                                _vm.carerSuggestion.totalCosts
                                              )
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-right-style": "solid",
                                        "border-right-width": "3px",
                                        "border-right-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carerSuggestion.travelCosts"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("travelCosts")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        "padding-left": "10px",
                                        "padding-top": "10px",
                                        "padding-bottom": "5px",
                                      },
                                      attrs: { cols: "7" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toCurrency")(
                                              _vm.forceToNumber(
                                                _vm.carerSuggestion.travelCosts
                                              )
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                        "padding-bottom": "10px",
                                        "border-right-style": "solid",
                                        "border-right-width": "3px",
                                        "border-right-color": "#a0d3e4",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carerSuggestion.arrivalFrom"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("arrivalFrom")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        "padding-left": "10px",
                                        "padding-bottom": "10px",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "7" },
                                    },
                                    [
                                      _vm.carerSuggestion.arrivalFrom
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  _vm.carerSuggestion
                                                    .arrivalFrom
                                                )
                                              )
                                            ),
                                          ])
                                        : _c(
                                            "span",
                                            {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.carerSuggestion.defaults.arrivalFrom"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("arrivalForm")]
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-right-style": "solid",
                                        "border-right-width": "3px",
                                        "border-right-color": "#a0d3e4",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carerSuggestion.character"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("character")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        "padding-left": "10px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "7" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.character
                                                ? _vm.character
                                                : _vm.characterDerived
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-right-style": "solid",
                                        "border-right-width": "3px",
                                        "border-right-color": "#a0d3e4",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carerSuggestion.experiences"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("experiences")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        "padding-left": "10px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "7" },
                                    },
                                    [
                                      _vm.carerSuggestion.carer
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.carerSuggestion.carer
                                                  .workExperience
                                              )
                                            ),
                                          ])
                                        : _c(
                                            "span",
                                            {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.carerSuggestion.defaults.workExperience"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("workExperience")]
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-right-style": "solid",
                                        "border-right-width": "3px",
                                        "border-right-color": "#a0d3e4",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carerSuggestion.householdactivities"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("householdactivities")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        "padding-left": "10px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "7" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.carerSuggestion.defaults.practical"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("practical")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-right-style": "solid",
                                        "border-right-width": "3px",
                                        "border-right-color": "#a0d3e4",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carerSuggestion.knowledgeOfGerman"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("knowledgeOfGerman")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        "padding-left": "10px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "7" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.languageSkill
                                                ? _vm.languageSkill
                                                : _vm.languageSkillDerived
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-right-style": "solid",
                                        "border-right-width": "3px",
                                        "border-right-color": "#a0d3e4",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carerSuggestion.assignmentDuration"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("assignmentDuration")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        "padding-left": "10px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "7" },
                                    },
                                    [
                                      _vm.carerSuggestion.assignmentDuration
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.carerSuggestion
                                                  .assignmentDuration
                                              )
                                            ),
                                          ])
                                        : _c(
                                            "span",
                                            {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.carerSuggestion.defaults.assignmentDuration"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("assignmentDuration")]
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-right-style": "solid",
                                        "border-right-width": "3px",
                                        "border-right-color": "#a0d3e4",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carerSuggestion.assignmentRemarks"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Salary")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        "padding-left": "10px",
                                        "padding-top": "10px",
                                        "padding-bottom": "10px",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "3px",
                                        "border-bottom-color": "#a0d3e4",
                                      },
                                      attrs: { cols: "7" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.carerSuggestion.notes)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("b-row", [_c("b-col", [_vm._v(" ")])], 1),
                              _vm._v(" "),
                              _c("b-row", [_c("b-col", [_vm._v(" ")])], 1),
                              _vm._v(" "),
                              _c("b-row", [_c("b-col", [_vm._v(" ")])], 1),
                              _vm._v(" "),
                              _c("b-row", [_c("b-col", [_vm._v(" ")])], 1),
                              _vm._v(" "),
                              _c("b-row", [_c("b-col", [_vm._v(" ")])], 1),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "b-container",
                          [
                            _c(
                              "b-row",
                              { attrs: { "align-h": "end", "no-gutters": "" } },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticStyle: { "text-align": "center" },
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _vm.agencyLogoImage
                                      ? _c("img", {
                                          staticStyle: { "max-height": "80px" },
                                          attrs: {
                                            alt: "agency logo",
                                            src:
                                              "data:" +
                                              _vm.agencyLogoImageContentType +
                                              ";base64," +
                                              _vm.agencyLogoImage,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.agencyLogoImage
                                      ? _c("img", {
                                          staticStyle: { height: "80px" },
                                          attrs: {
                                            alt: "agency logo",
                                            src: require("../../../content/images/Betreuung_Bayern_1000.jpg"),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticStyle: { "font-size": "10px" },
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _c(
                                      "b-container",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "p-0 m-0",
                                                attrs: { cols: "5" },
                                              },
                                              [
                                                _c(
                                                  "b-container",
                                                  { staticClass: "p-0 m-0" },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      {
                                                        staticClass: "p-0 m-0",
                                                        attrs: {
                                                          "align-v": "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            staticClass:
                                                              "p-0 m-0",
                                                            attrs: {
                                                              cols: "4",
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                "max-height":
                                                                  "40px",
                                                              },
                                                              attrs: {
                                                                alt: "address",
                                                                src: require("../../../content/images/icon_address.png"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            staticClass:
                                                              "p-0 m-0",
                                                            attrs: {
                                                              cols: "8",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.agencyStreet
                                                                    ? _vm.agencyStreet
                                                                    : "-"
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm.agencyHouseNumber
                                                                      ? _vm.agencyHouseNumber
                                                                      : "-"
                                                                  )
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("br"),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.agencyPostcode
                                                                    ? _vm.agencyPostcode
                                                                    : "-"
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm.agencyCity
                                                                      ? _vm.agencyCity
                                                                      : "-"
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "p-0 m-0",
                                                attrs: { cols: "7" },
                                              },
                                              [
                                                _c(
                                                  "b-container",
                                                  { staticClass: "p-0 m-0" },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      {
                                                        staticClass: "p-0 m-0",
                                                        attrs: {
                                                          "align-v": "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            staticClass:
                                                              "p-0 m-0",
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                "max-height":
                                                                  "40px",
                                                              },
                                                              attrs: {
                                                                alt: "mail",
                                                                src: require("../../../content/images/icon_mail.png"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            staticClass:
                                                              "p-0 m-0",
                                                            attrs: {
                                                              cols: "9",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.agencyMail
                                                                    ? _vm.agencyMail
                                                                    : "-"
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "p-0 m-0",
                                                attrs: { cols: "5" },
                                              },
                                              [
                                                _c(
                                                  "b-container",
                                                  { staticClass: "p-0 m-0" },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      {
                                                        staticClass: "p-0 m-0",
                                                        attrs: {
                                                          "align-v": "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            staticClass:
                                                              "p-0 m-0",
                                                            attrs: {
                                                              cols: "4",
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                "max-height":
                                                                  "40px",
                                                              },
                                                              attrs: {
                                                                alt: "phone",
                                                                src: require("../../../content/images/icon_phone.png"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            staticClass:
                                                              "p-0 m-0",
                                                            attrs: {
                                                              cols: "8",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.agencyPhone
                                                                    ? _vm.agencyPhone
                                                                    : "-"
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "p-0 m-0",
                                                attrs: { cols: "7" },
                                              },
                                              [
                                                _c(
                                                  "b-container",
                                                  { staticClass: "p-0 m-0" },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      {
                                                        staticClass: "p-0 m-0",
                                                        attrs: {
                                                          "align-v": "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            staticClass:
                                                              "p-0 m-0",
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                "max-height":
                                                                  "40px",
                                                              },
                                                              attrs: {
                                                                alt: "website",
                                                                src: require("../../../content/images/icon_web.png"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            staticClass:
                                                              "p-0 m-0",
                                                            attrs: {
                                                              cols: "9",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.agencyWebsite
                                                                    ? _vm.agencyWebsite
                                                                    : "-"
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }