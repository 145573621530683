export enum ActivityType {
  NOTE = 'note',

  CALL = 'call',

  MEETING = 'meeting',

  ARRIVAL = 'arrival',

  DEPARTURE = 'departure',
}
