import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICarer } from '@/shared/model/carer.model';

const baseApiUrl = 'api/carers';
const baseNewApiUrl = 'api/carersnew';
const baseSearchApiUrl = 'api/_search/carers?query=';
const baseSearchNewApiUrl = 'api/_search/carersnew?query=';

export default class CarerService {
  public search(query, paginationQuery): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseSearchApiUrl}${query}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public searchNew(query, paginationQuery): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseSearchNewApiUrl}${query}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveNew(incArchivedCarers: boolean, paginationQuery?: any): Promise<any> {
    if (paginationQuery == null || paginationQuery == undefined) {
      return this.retrieveAllNew(incArchivedCarers);
    }
    const urlAddition = incArchivedCarers === true ? '/with-archived-carers' : '';
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseNewApiUrl + `${urlAddition}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<ICarer> {
    return new Promise<ICarer>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(incArchivedCarers: boolean, paginationQuery?: any): Promise<any> {
    if (paginationQuery == null || paginationQuery == undefined) {
      return this.retrieveAll(incArchivedCarers);
    }
    const urlAddition = incArchivedCarers === true ? '/with-archived-carers' : '';
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `${urlAddition}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveAll(incArchivedCarers: boolean): Promise<any> {
    const urlAddition = incArchivedCarers === true ? '/with-archived-carers' : '';
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `-all${urlAddition}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveAllNew(incArchivedCarers: boolean): Promise<any> {
    const urlAddition = incArchivedCarers === true ? '/with-archived-carers' : '';
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseNewApiUrl + `-all${urlAddition}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICarer): Promise<ICarer> {
    return new Promise<ICarer>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICarer): Promise<ICarer> {
    return new Promise<ICarer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public archiveCarer(id: number): Promise<ICarer> {
    return new Promise<ICarer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${id}/archive`, null)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public reactivateCarer(id: number): Promise<ICarer> {
    return new Promise<ICarer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${id}/reactivate`, null)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateHealthInsurance(entity: ICarer): Promise<ICarer> {
    return new Promise<ICarer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/healthInsurance/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public cancelHealthInsurance(entity: ICarer): Promise<ICarer> {
    return new Promise<ICarer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/cancelHealthInsurance/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICarer): Promise<ICarer> {
    return new Promise<ICarer>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
