var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isLoadingAll()
    ? _c("div", [
        _vm.infoText != null
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 text-center" }, [
                _c("h2", {}, [_vm._v(_vm._s(_vm.infoText))]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.infoText == null
          ? _c(
              "form",
              {
                staticClass: "fragebogen-form mt-3 mb-3",
                attrs: {
                  name: "onlineFragebogenForm",
                  novalidate: "",
                  role: "form",
                },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-7" }, [
                    _c(
                      "h2",
                      {
                        attrs: {
                          id: "care4YouApp.questionnaire.home.createLabel",
                        },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("care4YouApp.fragebogen.home.title")
                          ),
                        },
                      },
                      [_vm._v("Fragebogen")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-5 text-right" }, [
                    _vm.agencyLogoImageType
                      ? _c("img", {
                          staticStyle: { "max-height": "96px" },
                          attrs: {
                            alt: "agency logo",
                            src:
                              "data:" +
                              _vm.agencyLogoImageType +
                              ";base64," +
                              _vm.agencyLogoImageData,
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "p",
                      {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("care4YouApp.fragebogen.home.header")
                          ),
                        },
                      },
                      [_vm._v("Header")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-2" }, [
                    _c(
                      "span",
                      {
                        staticClass: "font-weight-bold",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("care4YouApp.fragebogen.home.perMail")
                          ),
                        },
                      },
                      [_vm._v("per Post")]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _vm.hasFax()
                      ? _c(
                          "span",
                          {
                            staticClass: "font-weight-bold",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("care4YouApp.fragebogen.home.perFax")
                              ),
                            },
                          },
                          [_vm._v("per Fax")]
                        )
                      : _vm._e(),
                    _vm.hasFax() ? _c("br") : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "font-weight-bold",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("care4YouApp.fragebogen.home.perEMail")
                          ),
                        },
                      },
                      [_vm._v("per Mail")]
                    ),
                    _c("br"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-10" }, [
                    _c(
                      "span",
                      { domProps: { textContent: _vm._s(_vm.getAnschrift()) } },
                      [_vm._v("Anschrift")]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _vm.hasFax()
                      ? _c(
                          "span",
                          { domProps: { textContent: _vm._s(_vm.getFax()) } },
                          [_vm._v("Fax")]
                        )
                      : _vm._e(),
                    _vm.hasFax() ? _c("br") : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      { domProps: { textContent: _vm._s(_vm.getMail()) } },
                      [_vm._v("Mail")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("care4YouApp.fragebogen.sections.begin")
                      ),
                    },
                  },
                  [_vm._v("Betreuungsbeginn")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row border border-dark" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-wunschdatum1",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.wunschdatum1"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "wunschdatum1",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-form-datepicker", {
                                    staticClass: "form-control",
                                    attrs: {
                                      locale: _vm.currentLanguage,
                                      "aria-controls": "wunschdatum1",
                                      "button-only": "",
                                      "close-button": "",
                                      name: "wunschdatum1",
                                      "reset-button": "",
                                      "today-button": "",
                                      disabled: _vm.readonlyMode,
                                    },
                                    model: {
                                      value: _vm.getQuestion(
                                        _vm.attributes,
                                        _vm.QUESTION_WUNSCHDATUM1
                                      ).value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.getQuestion(
                                            _vm.attributes,
                                            _vm.QUESTION_WUNSCHDATUM1
                                          ),
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "getQuestion(attributes, QUESTION_WUNSCHDATUM1).value",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("b-form-input", {
                                staticClass: "form-control text-center",
                                staticStyle: {
                                  height: "1.5rem",
                                  margin: "1rem 0rem 0rem 0rem",
                                },
                                attrs: {
                                  id: "wunschdatum1",
                                  value: _vm.convertDateFromServer(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_WUNSCHDATUM1
                                    ).value
                                  ),
                                  name: "wunschdatum1",
                                  type: "text",
                                  disabled: _vm.readonlyMode,
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.updateDateField(
                                      $event,
                                      _vm.getQuestion(
                                        _vm.attributes,
                                        _vm.QUESTION_WUNSCHDATUM1
                                      ),
                                      "value"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-wunschdatum2",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.wunschdatum2"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "wunschdatum2",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-form-datepicker", {
                                    staticClass: "form-control text-center",
                                    attrs: {
                                      locale: _vm.currentLanguage,
                                      "aria-controls": "wunschdatum2",
                                      "button-only": "",
                                      "close-button": "",
                                      name: "wunschdatum2",
                                      "reset-button": "",
                                      "today-button": "",
                                      disabled: _vm.readonlyMode,
                                    },
                                    model: {
                                      value: _vm.getQuestion(
                                        _vm.attributes,
                                        _vm.QUESTION_WUNSCHDATUM2
                                      ).value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.getQuestion(
                                            _vm.attributes,
                                            _vm.QUESTION_WUNSCHDATUM2
                                          ),
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "getQuestion(attributes, QUESTION_WUNSCHDATUM2).value",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("b-form-input", {
                                staticClass: "form-control text-center",
                                staticStyle: {
                                  height: "1.5rem",
                                  margin: "1rem 0rem 0rem 0rem",
                                },
                                attrs: {
                                  id: "wunschdatum2",
                                  value: _vm.convertDateFromServer(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_WUNSCHDATUM2
                                    ).value
                                  ),
                                  name: "wunschdatum2",
                                  type: "text",
                                  disabled: _vm.readonlyMode,
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.updateDateField(
                                      $event,
                                      _vm.getQuestion(
                                        _vm.attributes,
                                        _vm.QUESTION_WUNSCHDATUM2
                                      ),
                                      "value"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "care4YouApp.fragebogen.sections.contactPerson"
                        ) + _vm.getObligatorySign()
                      ),
                    },
                  },
                  [_vm._v("Ansprechpartner")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-ehepartner",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.ehepartner"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "ehepartner",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ansprechpartnerEhepartner,
                                expression: "ansprechpartnerEhepartner",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "schwager",
                              name: "ehepartner",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.ansprechpartnerEhepartner
                              )
                                ? _vm._i(_vm.ansprechpartnerEhepartner, null) >
                                  -1
                                : _vm.ansprechpartnerEhepartner,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.ansprechpartnerEhepartner,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.ansprechpartnerEhepartner =
                                          $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.ansprechpartnerEhepartner = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.ansprechpartnerEhepartner = $$c
                                  }
                                },
                                function ($event) {
                                  return _vm.onAnsprechpartnerChange(
                                    "ehepartner"
                                  )
                                },
                              ],
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-schwager",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.schwager"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "schwager",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ansprechpartnerSchwager,
                                expression: "ansprechpartnerSchwager",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "schwager",
                              name: "schwager",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.ansprechpartnerSchwager
                              )
                                ? _vm._i(_vm.ansprechpartnerSchwager, null) > -1
                                : _vm.ansprechpartnerSchwager,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.ansprechpartnerSchwager,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.ansprechpartnerSchwager =
                                          $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.ansprechpartnerSchwager = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.ansprechpartnerSchwager = $$c
                                  }
                                },
                                function ($event) {
                                  return _vm.onAnsprechpartnerChange("schwager")
                                },
                              ],
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-sohn",
                            label: _vm.$t("care4YouApp.fragebogen.fields.sohn"),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "sohn",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ansprechpartnerSohn,
                                expression: "ansprechpartnerSohn",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "sohn",
                              disabled: _vm.readonlyMode,
                              name: "sohn",
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.ansprechpartnerSohn)
                                ? _vm._i(_vm.ansprechpartnerSohn, null) > -1
                                : _vm.ansprechpartnerSohn,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.ansprechpartnerSohn,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.ansprechpartnerSohn = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.ansprechpartnerSohn = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.ansprechpartnerSohn = $$c
                                  }
                                },
                                function ($event) {
                                  return _vm.onAnsprechpartnerChange("kind")
                                },
                              ],
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-sonstige",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.sonstige"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "sonstige",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ansprechpartnerSonstige,
                                expression: "ansprechpartnerSonstige",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "sonstige",
                              name: "sonstige",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: { value: _vm.ansprechpartnerSonstige },
                            on: {
                              beforeinput: function ($event) {
                                return _vm.onAnsprechpartnerChange("sonstige")
                              },
                              change: function ($event) {
                                return _vm.onAnsprechpartnerChange("sonstige")
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.ansprechpartnerSonstige =
                                  $event.target.value
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-bottom-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-ansprechpartner_name",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.ansprechpartner_name"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "ansprechpartner_name",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANSPRECHPARTNER_NAME
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANSPRECHPARTNER_NAME).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "ansprechpartner_name",
                              name: "ansprechpartner_name",
                              disabled: _vm.readonlyMode,
                              type: "text",
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_ANSPRECHPARTNER_NAME
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANSPRECHPARTNER_NAME
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 border border-dark border-bottom-0 border-left-0",
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-ansprechpartner_vorname",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.ansprechpartner_vorname"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "ansprechpartner_vorname",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANSPRECHPARTNER_VORNAME
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANSPRECHPARTNER_VORNAME).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "ansprechpartner_vorname",
                              name: "ansprechpartner_vorname",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_ANSPRECHPARTNER_VORNAME
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANSPRECHPARTNER_VORNAME
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-bottom-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-ansprechpartner_strasse",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.ansprechpartner_strasse"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "ansprechpartner_strasse",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANSPRECHPARTNER_STRASSE
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANSPRECHPARTNER_STRASSE).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "ansprechpartner_strasse",
                              name: "ansprechpartner_strasse",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_ANSPRECHPARTNER_STRASSE
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANSPRECHPARTNER_STRASSE
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 border border-dark border-bottom-0 border-left-0",
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-ansprechpartner_hausnummer",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.ansprechpartner_hausnummer"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "ansprechpartner_hausnummer",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANSPRECHPARTNER_HAUSNUMMER
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANSPRECHPARTNER_HAUSNUMMER).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "ansprechpartner_hausnummer",
                              name: "ansprechpartner_hausnummer",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_ANSPRECHPARTNER_HAUSNUMMER
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANSPRECHPARTNER_HAUSNUMMER
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-bottom-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-ansprechpartner_plz",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.ansprechpartner_plz"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "ansprechpartner_plz",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANSPRECHPARTNER_PLZ
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANSPRECHPARTNER_PLZ).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "ansprechpartner_plz",
                              name: "ansprechpartner_plz",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_ANSPRECHPARTNER_PLZ
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANSPRECHPARTNER_PLZ
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 border border-dark border-bottom-0 border-left-0",
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-ansprechpartner_ort",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.ansprechpartner_ort"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "ansprechpartner_ort",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANSPRECHPARTNER_ORT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANSPRECHPARTNER_ORT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "ansprechpartner_ort",
                              name: "ansprechpartner_ort",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_ANSPRECHPARTNER_ORT
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANSPRECHPARTNER_ORT
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-ansprechpartner_rufnummer",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.ansprechpartner_rufnummer"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "ansprechpartner_rufnummer",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANSPRECHPARTNER_TELEFON
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANSPRECHPARTNER_TELEFON).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "ansprechpartner_rufnummer",
                              name: "ansprechpartner_rufnummer",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_ANSPRECHPARTNER_TELEFON
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANSPRECHPARTNER_TELEFON
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-left-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-ansprechpartner_mail",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.ansprechpartner_mail"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "ansprechpartner_mail",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANSPRECHPARTNER_MAIL
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANSPRECHPARTNER_MAIL).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "ansprechpartner_mail",
                              name: "ansprechpartner_mail",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_ANSPRECHPARTNER_MAIL
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANSPRECHPARTNER_MAIL
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("care4YouApp.fragebogen.sections.patient")
                      ),
                    },
                  },
                  [_vm._v("Patient")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-bottom-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-patient_name",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.patient_name"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "patient_name",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PATIENT_NAME
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PATIENT_NAME).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "patient_name",
                              name: "patient_name",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_PATIENT_NAME
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PATIENT_NAME
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 border border-dark border-bottom-0 border-left-0",
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-patient_vorname",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.patient_vorname"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "patient_vorname",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PATIENT_VORNAME
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PATIENT_VORNAME).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "patient_vorname",
                              name: "patient_vorname",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_PATIENT_VORNAME
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PATIENT_VORNAME
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-bottom-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-patient_strasse",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.patient_strasse"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "patient_strasse",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PATIENT_STRASSE
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PATIENT_STRASSE).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "patient_strasse",
                              name: "patient_strasse",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_PATIENT_STRASSE
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PATIENT_STRASSE
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 border border-dark border-bottom-0 border-left-0",
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-patient_hausnummer",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.patient_hausnummer"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "patient_hausnummer",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PATIENT_HAUSNUMMER
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PATIENT_HAUSNUMMER).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "patient_hausnummer",
                              name: "patient_hausnummer",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_PATIENT_HAUSNUMMER
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PATIENT_HAUSNUMMER
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-bottom-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-patient_plz",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.patient_plz"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "patient_plz",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PATIENT_PLZ
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PATIENT_PLZ).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "patient_plz",
                              name: "patient_plz",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_PATIENT_PLZ
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PATIENT_PLZ
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 border border-dark border-bottom-0 border-left-0",
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-patient_ort",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.patient_ort"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "patient_ort",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PATIENT_ORT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PATIENT_ORT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "patient_ort",
                              name: "patient_ort",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_PATIENT_ORT
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PATIENT_ORT
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-bottom-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-patient_krankenkasse",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.patient_krankenkasse"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "patient_krankenkasse",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PATIENT_KRANKENKASSE
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PATIENT_KRANKENKASSE).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "patient_krankenkasse",
                              disabled: _vm.readonlyMode,
                              name: "patient_krankenkasse",
                              required: "",
                              type: "text",
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_PATIENT_KRANKENKASSE
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PATIENT_KRANKENKASSE
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 border border-dark border-bottom-0 border-left-0",
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-patient_geburtsdatum",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.patient_geburtsdatum"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "patient_geburtsdatum",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-form-datepicker", {
                                    staticClass: "form-control",
                                    attrs: {
                                      locale: _vm.currentLanguage,
                                      "aria-controls": "patient_geburtsdatum",
                                      "button-only": "",
                                      "close-button": "",
                                      name: "patient_geburtsdatum",
                                      "reset-button": "",
                                      "today-button": "",
                                      disabled: _vm.readonlyMode,
                                    },
                                    model: {
                                      value: _vm.getQuestion(
                                        _vm.attributes,
                                        _vm.QUESTION_PATIENT_GEBURTSDATUM
                                      ).value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.getQuestion(
                                            _vm.attributes,
                                            _vm.QUESTION_PATIENT_GEBURTSDATUM
                                          ),
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "getQuestion(attributes, QUESTION_PATIENT_GEBURTSDATUM).value",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("b-form-input", {
                                staticClass: "form-control text-center",
                                staticStyle: {
                                  height: "1.5rem",
                                  margin: "1rem 0rem 0rem 0rem",
                                },
                                attrs: {
                                  id: "patient_geburtsdatum",
                                  value: _vm.convertDateFromServer(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PATIENT_GEBURTSDATUM
                                    ).value
                                  ),
                                  name: "patient_geburtsdatum",
                                  type: "text",
                                  disabled: _vm.readonlyMode,
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.updateDateField(
                                      $event,
                                      _vm.getQuestion(
                                        _vm.attributes,
                                        _vm.QUESTION_PATIENT_GEBURTSDATUM
                                      ),
                                      "value"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-patient_groesse",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.patient_groesse"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "patient_groesse",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PATIENT_GROESSE
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PATIENT_GROESSE).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "patient_groesse",
                              name: "patient_groesse",
                              type: "number",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_PATIENT_GROESSE
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PATIENT_GROESSE
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-left-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-patient_gewicht",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.patient_gewicht"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "patient_gewicht",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PATIENT_GEWICHT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PATIENT_GEWICHT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "patient_gewicht",
                              name: "patient_gewicht",
                              type: "number",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_PATIENT_GEWICHT
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PATIENT_GEWICHT
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark" },
                    [
                      _c(
                        "h3",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.pflegegrad"
                              ) + _vm.getObligatorySign()
                            ),
                          },
                        },
                        [_vm._v("Pflegegrad")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-pflegegrad_0",
                            label: _vm.$t(
                              "care4YouApp.FragePflegegrad.PFLEGEGRAD0"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "pflegegrad_0",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGEGRAD).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "pflegegrad_0",
                              name: "pflegegrad",
                              type: "radio",
                              value: "0",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                "0"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGEGRAD
                                  ),
                                  "value",
                                  "0"
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-pflegegrad_1",
                            label: _vm.$t(
                              "care4YouApp.FragePflegegrad.PFLEGEGRAD1"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "pflegegrad_1",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGEGRAD).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "pflegegrad_1",
                              name: "pflegegrad",
                              type: "radio",
                              value: "1",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                "1"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGEGRAD
                                  ),
                                  "value",
                                  "1"
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-pflegegrad_2",
                            label: _vm.$t(
                              "care4YouApp.FragePflegegrad.PFLEGEGRAD2"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "pflegegrad_2",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGEGRAD).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "pflegegrad_2",
                              name: "pflegegrad",
                              type: "radio",
                              value: "2",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                "2"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGEGRAD
                                  ),
                                  "value",
                                  "2"
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-pflegegrad_3",
                            label: _vm.$t(
                              "care4YouApp.FragePflegegrad.PFLEGEGRAD3"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "pflegegrad_3",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGEGRAD).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "pflegegrad_3",
                              name: "pflegegrad",
                              type: "radio",
                              value: "3",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                "3"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGEGRAD
                                  ),
                                  "value",
                                  "3"
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-pflegegrad_4",
                            label: _vm.$t(
                              "care4YouApp.FragePflegegrad.PFLEGEGRAD4"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "pflegegrad_4",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGEGRAD).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "pflegegrad_4",
                              name: "pflegegrad",
                              type: "radio",
                              value: "4",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                "4"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGEGRAD
                                  ),
                                  "value",
                                  "4"
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-pflegegrad_5",
                            label: _vm.$t(
                              "care4YouApp.FragePflegegrad.PFLEGEGRAD5"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "pflegegrad_5",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGEGRAD).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "pflegegrad_5",
                              name: "pflegegrad",
                              type: "radio",
                              value: "5",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGEGRAD
                                ).value,
                                "5"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGEGRAD
                                  ),
                                  "value",
                                  "5"
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-left-0" },
                    [
                      _c(
                        "h3",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("care4YouApp.fragebogen.fields.diagnosen")
                            ),
                          },
                        },
                        [_vm._v("Diagnosen")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-diagnose_altersschwaeche",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.diagnose_altersschwaeche"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "diagnose_altersschwaeche",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_ALTERSSCHWAECHE
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_DIAGNOSE_ALTERSSCHWAECHE).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "diagnose_altersschwaeche",
                              name: "diagnose_altersschwaeche",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_ALTERSSCHWAECHE
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_ALTERSSCHWAECHE
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_ALTERSSCHWAECHE
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_ALTERSSCHWAECHE
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_ALTERSSCHWAECHE
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_ALTERSSCHWAECHE
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_ALTERSSCHWAECHE
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-diagnose_demenz",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.diagnose_demenz"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "diagnose_demenz",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_DEMENZ
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_DIAGNOSE_DEMENZ).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "diagnose_demenz",
                              name: "diagnose_demenz",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_DEMENZ
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_DEMENZ
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_DEMENZ
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_DEMENZ
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_DEMENZ
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_DEMENZ
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_DEMENZ
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-diagnose_krebs",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.diagnose_krebs"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "diagnose_krebs",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_KREBS
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_DIAGNOSE_KREBS).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "diagnose_krebs",
                              name: "diagnose_krebs",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_KREBS
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_KREBS
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_KREBS
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_KREBS
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_KREBS
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_KREBS
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_KREBS
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-diagnose_schlaganfall",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.diagnose_schlaganfall"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "diagnose_schlaganfall",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_SCHLAGANFALL
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_DIAGNOSE_SCHLAGANFALL).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "diagnose_schlaganfall",
                              name: "diagnose_schlaganfall",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_SCHLAGANFALL
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_SCHLAGANFALL
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_SCHLAGANFALL
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_SCHLAGANFALL
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_SCHLAGANFALL
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_SCHLAGANFALL
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_SCHLAGANFALL
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-diagnose_einsamkeit",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.diagnose_einsamkeit"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "diagnose_einsamkeit",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_EINSAMKEIT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_DIAGNOSE_EINSAMKEIT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "diagnose_einsamkeit",
                              name: "diagnose_einsamkeit",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_EINSAMKEIT
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_EINSAMKEIT
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_EINSAMKEIT
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_EINSAMKEIT
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_EINSAMKEIT
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_EINSAMKEIT
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_EINSAMKEIT
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-diagnose_angstzustaende",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.diagnose_angstzustaende"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "diagnose_angstzustaende",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_ANGSTZUSTAENDE
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_DIAGNOSE_ANGSTZUSTAENDE).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "diagnose_angstzustaende",
                              name: "diagnose_angstzustaende",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_DIAGNOSE_ANGSTZUSTAENDE
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_ANGSTZUSTAENDE
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_ANGSTZUSTAENDE
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DIAGNOSE_ANGSTZUSTAENDE
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_ANGSTZUSTAENDE
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_DIAGNOSE_ANGSTZUSTAENDE
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_DIAGNOSE_ANGSTZUSTAENDE
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark" },
                    [
                      _c(
                        "h3",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.vorhandene_hilfsmittel"
                              )
                            ),
                          },
                        },
                        [_vm._v("Vorhandene Hilfsmittel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-hilfsmittel_rollstuhl",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.hilfsmittel_rollstuhl"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "hilfsmittel_rollstuhl",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HILFSMITTEL_ROLLSTUHL
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_HILFSMITTEL_ROLLSTUHL).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "hilfsmittel_rollstuhl",
                              name: "hilfsmittel_rollstuhl",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HILFSMITTEL_ROLLSTUHL
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HILFSMITTEL_ROLLSTUHL
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HILFSMITTEL_ROLLSTUHL
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HILFSMITTEL_ROLLSTUHL
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HILFSMITTEL_ROLLSTUHL
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HILFSMITTEL_ROLLSTUHL
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HILFSMITTEL_ROLLSTUHL
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-hilfsmittel_rollator",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.hilfsmittel_rollator"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "hilfsmittel_rollator",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HILFSMITTEL_ROLLATOR
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_HILFSMITTEL_ROLLATOR).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "hilfsmittel_rollator",
                              name: "hilfsmittel_rollator",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HILFSMITTEL_ROLLATOR
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HILFSMITTEL_ROLLATOR
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HILFSMITTEL_ROLLATOR
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HILFSMITTEL_ROLLATOR
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HILFSMITTEL_ROLLATOR
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HILFSMITTEL_ROLLATOR
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HILFSMITTEL_ROLLATOR
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-hilfsmittel_treppenlift",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.hilfsmittel_treppenlift"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "hilfsmittel_treppenlift",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HILFSMITTEL_TREPPENLIFT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_HILFSMITTEL_TREPPENLIFT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "hilfsmittel_treppenlift",
                              name: "hilfsmittel_treppenlift",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HILFSMITTEL_TREPPENLIFT
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HILFSMITTEL_TREPPENLIFT
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HILFSMITTEL_TREPPENLIFT
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HILFSMITTEL_TREPPENLIFT
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HILFSMITTEL_TREPPENLIFT
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HILFSMITTEL_TREPPENLIFT
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HILFSMITTEL_TREPPENLIFT
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-hilfsmittel_patientenlifter",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.hilfsmittel_patientenlifter"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "hilfsmittel_patientenlifter",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HILFSMITTEL_PATIENTENLIFTER
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_HILFSMITTEL_PATIENTENLIFTER).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "hilfsmittel_patientenlifter",
                              name: "hilfsmittel_patientenlifter",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HILFSMITTEL_PATIENTENLIFTER
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HILFSMITTEL_PATIENTENLIFTER
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HILFSMITTEL_PATIENTENLIFTER
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HILFSMITTEL_PATIENTENLIFTER
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HILFSMITTEL_PATIENTENLIFTER
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HILFSMITTEL_PATIENTENLIFTER
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HILFSMITTEL_PATIENTENLIFTER
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-hilfsmittel_pflegebett",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.hilfsmittel_pflegebett"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "hilfsmittel_pflegebett",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HILFSMITTEL_PFLEGEBETT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_HILFSMITTEL_PFLEGEBETT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "hilfsmittel_pflegebett",
                              name: "hilfsmittel_pflegebett",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HILFSMITTEL_PFLEGEBETT
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HILFSMITTEL_PFLEGEBETT
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HILFSMITTEL_PFLEGEBETT
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HILFSMITTEL_PFLEGEBETT
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HILFSMITTEL_PFLEGEBETT
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HILFSMITTEL_PFLEGEBETT
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HILFSMITTEL_PFLEGEBETT
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-left-0" },
                    [
                      _c(
                        "h3",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("care4YouApp.fragebogen.fields.zustand")
                            ),
                          },
                        },
                        [_vm._v("Zustand")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-zustand_bettlaegerig",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.zustand_bettlaegerig"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "zustand_bettlaegerig",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ZUSTAND_BETTLAEGERIG
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ZUSTAND_BETTLAEGERIG).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "zustand_bettlaegerig",
                              name: "zustand_bettlaegerig",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ZUSTAND_BETTLAEGERIG
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ZUSTAND_BETTLAEGERIG
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ZUSTAND_BETTLAEGERIG
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ZUSTAND_BETTLAEGERIG
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ZUSTAND_BETTLAEGERIG
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ZUSTAND_BETTLAEGERIG
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ZUSTAND_BETTLAEGERIG
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-zustand_hilfe_stehen",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.zustand_hilfe_stehen"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "zustand_hilfe_stehen",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ZUSTAND_HILFESTEHEN
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ZUSTAND_HILFESTEHEN).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "zustand_hilfe_stehen",
                              name: "zustand_hilfe_stehen",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ZUSTAND_HILFESTEHEN
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ZUSTAND_HILFESTEHEN
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ZUSTAND_HILFESTEHEN
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ZUSTAND_HILFESTEHEN
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ZUSTAND_HILFESTEHEN
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ZUSTAND_HILFESTEHEN
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ZUSTAND_HILFESTEHEN
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-zustand_hilfe_gehen",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.zustand_hilfe_gehen"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "zustand_hilfe_gehen",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ZUSTAND_HILFEGEHEN
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ZUSTAND_HILFEGEHEN).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "zustand_hilfe_gehen",
                              name: "zustand_hilfe_gehen",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ZUSTAND_HILFEGEHEN
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ZUSTAND_HILFEGEHEN
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ZUSTAND_HILFEGEHEN
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ZUSTAND_HILFEGEHEN
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ZUSTAND_HILFEGEHEN
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ZUSTAND_HILFEGEHEN
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ZUSTAND_HILFEGEHEN
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-zustand_sehen",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.zustand_sehen"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "zustand_sehen",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ZUSTAND_EINGECHRAENKTSEHEN
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ZUSTAND_EINGECHRAENKTSEHEN).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "zustand_sehen",
                              name: "zustand_sehen",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ZUSTAND_EINGECHRAENKTSEHEN
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ZUSTAND_EINGECHRAENKTSEHEN
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ZUSTAND_EINGECHRAENKTSEHEN
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ZUSTAND_EINGECHRAENKTSEHEN
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ZUSTAND_EINGECHRAENKTSEHEN
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ZUSTAND_EINGECHRAENKTSEHEN
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ZUSTAND_EINGECHRAENKTSEHEN
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-zustand_hoeren",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.zustand_hoeren"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "zustand_hoeren",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ZUSTAND_EINGECHRAENKTHOEREN
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ZUSTAND_EINGECHRAENKTHOEREN).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "zustand_hoeren",
                              name: "zustand_hoeren",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ZUSTAND_EINGECHRAENKTHOEREN
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ZUSTAND_EINGECHRAENKTHOEREN
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ZUSTAND_EINGECHRAENKTHOEREN
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ZUSTAND_EINGECHRAENKTHOEREN
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ZUSTAND_EINGECHRAENKTHOEREN
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ZUSTAND_EINGECHRAENKTHOEREN
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ZUSTAND_EINGECHRAENKTHOEREN
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("care4YouApp.fragebogen.sections.careDoings")
                      ),
                    },
                  },
                  [_vm._v("Grundpflegerische- und Betreuungstätigkeiten")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark" },
                    [
                      _c(
                        "h3",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.grundpflegerische_taetigkeiten"
                              )
                            ),
                          },
                        },
                        [_vm._v("Grundpflegerische Tätigkeiten")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-koerperwaesche",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.koerperwaesche"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "koerperwaesche",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "koerperwaesche",
                              name: "koerperwaesche",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-toilettengang",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.toilettengang"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "toilettengang",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "toilettengang",
                              name: "toilettengang",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-an_und_auskleiden",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.an_und_auskleiden"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "an_und_auskleiden",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_KLEIDEN
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_KLEIDEN).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "an_und_auskleiden",
                              name: "an_und_auskleiden",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_KLEIDEN
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_KLEIDEN
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_KLEIDEN
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_KLEIDEN
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_KLEIDEN
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_KLEIDEN
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_KLEIDEN
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-intimpflege",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.intimpflege"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "intimpflege",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "intimpflege",
                              name: "intimpflege",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-trinkueberwachung",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.trinkueberwachung"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "trinkueberwachung",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "trinkueberwachung",
                              name: "trinkueberwachung",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-hilfe_nahrungsaufnahme",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.hilfe_nahrungsaufnahme"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "hilfe_nahrungsaufnahme",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "hilfe_nahrungsaufnahme",
                              name: "hilfe_nahrungsaufnahme",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 border border-dark border-left-0" },
                    [
                      _c(
                        "h3",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.betreuungstaetigkeiten"
                              )
                            ),
                          },
                        },
                        [_vm._v("Betreuungstätigkeiten")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-spazierengehen",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.spazierengehen"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "spazierengehen",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "spazierengehen",
                              name: "spazierengehen",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-handarbeit",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.handarbeit"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "handarbeit",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "handarbeit",
                              name: "handarbeit",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-spiele_spielen",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.spiele_spielen"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "spiele_spielen",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_SPIELEN
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_SPIELEN).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "spiele_spielen",
                              name: "spiele_spielen",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_SPIELEN
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_SPIELEN
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_SPIELEN
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_SPIELEN
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_SPIELEN
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_PFLEGETAETIGKEITEN_SPIELEN
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_PFLEGETAETIGKEITEN_SPIELEN
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-betreuungstaetigkeiten_sonstige",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.betreuungstaetigkeiten_sonstige"
                            ),
                            "content-cols": "8",
                            "label-cols": "4",
                            "label-for": "betreuungstaetigkeiten_sonstige",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_PFLEGETAETIGKEITEN_SONSTIGES
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_SONSTIGES).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "betreuungstaetigkeiten_sonstige",
                              name: "betreuungstaetigkeiten_sonstige",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_PFLEGETAETIGKEITEN_SONSTIGES
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_PFLEGETAETIGKEITEN_SONSTIGES
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "care4YouApp.fragebogen.sections.householdDoings"
                        )
                      ),
                    },
                  },
                  [_vm._v("Haushaltstätigkeiten")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 border border-dark" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-kochen_fuer_personen",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.kochen_fuer_personen"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "4",
                            "label-cols": "4",
                            "label-for": "kochen_fuer_personen",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HAUSHALT_KOCHEN
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_HAUSHALT_KOCHEN).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "kochen_fuer_personen",
                              name: "kochen_fuer_personen",
                              type: "number",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_HAUSHALT_KOCHEN
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HAUSHALT_KOCHEN
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-waschen_putzen_buegeln",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.waschen_putzen_buegeln"
                            ),
                            "content-cols": "4",
                            "label-cols": "4",
                            "label-for": "waschen_putzen_buegeln",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HAUSHALT_WASCHEN
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_HAUSHALT_WASCHEN).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "waschen_putzen_buegeln",
                              name: "waschen_putzen_buegeln",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HAUSHALT_WASCHEN
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HAUSHALT_WASCHEN
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HAUSHALT_WASCHEN
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HAUSHALT_WASCHEN
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HAUSHALT_WASCHEN
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HAUSHALT_WASCHEN
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HAUSHALT_WASCHEN
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-einkaufen",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.einkaufen"
                            ),
                            "content-cols": "4",
                            "label-cols": "4",
                            "label-for": "einkaufen",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HAUSHALT_EINKAUFEN
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_HAUSHALT_EINKAUFEN).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "einkaufen",
                              name: "einkaufen",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HAUSHALT_EINKAUFEN
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HAUSHALT_EINKAUFEN
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HAUSHALT_EINKAUFEN
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HAUSHALT_EINKAUFEN
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HAUSHALT_EINKAUFEN
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_HAUSHALT_EINKAUFEN
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_HAUSHALT_EINKAUFEN
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-reinigen",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.reinigen"
                            ),
                            "content-cols": "4",
                            "label-cols": "4",
                            "label-for": "reinigen",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HAUSHALT_REINIGEN
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_HAUSHALT_REINIGEN).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "reinigen",
                              name: "reinigen",
                              type: "number",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_HAUSHALT_REINIGEN
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HAUSHALT_REINIGEN
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-haustiere",
                            label:
                              _vm.$t(
                                "care4YouApp.fragebogen.fields.haustiere"
                              ) + _vm.getObligatorySign(),
                            "content-cols": "4",
                            "label-cols": "4",
                            "label-for": "haustiere",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HAUSHALT_HAUSTIERE
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_HAUSHALT_HAUSTIERE).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "haustiere",
                              name: "haustiere",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_HAUSHALT_HAUSTIERE
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HAUSHALT_HAUSTIERE
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-haushaltstaetigkeiten_sonstiges",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.haushaltstaetigkeiten_sonstiges"
                            ),
                            "content-cols": "4",
                            "label-cols": "4",
                            "label-for": "haushaltstaetigkeiten_sonstiges",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_HAUSHALT_SONSTIGES
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_HAUSHALT_SONSTIGES).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "haushaltstaetigkeiten_sonstiges",
                              name: "haushaltstaetigkeiten_sonstiges",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_HAUSHALT_SONSTIGES
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_HAUSHALT_SONSTIGES
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("care4YouApp.fragebogen.sections.livingSpace")
                      ),
                    },
                  },
                  [_vm._v("Wohnraum für die Betreuungskraft")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 border border-dark" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-eigenes_zimmer",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.eigenes_zimmer"
                            ),
                            "content-cols": "4",
                            "label-cols": "4",
                            "label-for": "eigenes_zimmer",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_WOHNRAUM_EIGENESZIMMER
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_WOHNRAUM_EIGENESZIMMER).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "eigenes_zimmer",
                              name: "eigenes_zimmer",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_WOHNRAUM_EIGENESZIMMER
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_WOHNRAUM_EIGENESZIMMER
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_WOHNRAUM_EIGENESZIMMER
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_WOHNRAUM_EIGENESZIMMER
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_WOHNRAUM_EIGENESZIMMER
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_WOHNRAUM_EIGENESZIMMER
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_WOHNRAUM_EIGENESZIMMER
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-eigenes_bett_schrank",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.eigenes_bett_schrank"
                            ),
                            "content-cols": "4",
                            "label-cols": "4",
                            "label-for": "eigenes_bett_schrank",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_WOHNRAUM_EIGENESBETT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_WOHNRAUM_EIGENESBETT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "eigenes_bett_schrank",
                              name: "eigenes_bett_schrank",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_WOHNRAUM_EIGENESBETT
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_WOHNRAUM_EIGENESBETT
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_WOHNRAUM_EIGENESBETT
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_WOHNRAUM_EIGENESBETT
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_WOHNRAUM_EIGENESBETT
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_WOHNRAUM_EIGENESBETT
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_WOHNRAUM_EIGENESBETT
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-eigenes_bad",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.eigenes_bad"
                            ),
                            "content-cols": "4",
                            "label-cols": "4",
                            "label-for": "eigenes_bad",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_WOHNRAUM_EIGENESBAD
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_WOHNRAUM_EIGENESBAD).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "eigenes_bad",
                              name: "eigenes_bad",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_WOHNRAUM_EIGENESBAD
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_WOHNRAUM_EIGENESBAD
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_WOHNRAUM_EIGENESBAD
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_WOHNRAUM_EIGENESBAD
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_WOHNRAUM_EIGENESBAD
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_WOHNRAUM_EIGENESBAD
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_WOHNRAUM_EIGENESBAD
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-internet",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.internet"
                            ),
                            "content-cols": "4",
                            "label-cols": "4",
                            "label-for": "internet",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_WOHNRAUM_INTERNET
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_WOHNRAUM_INTERNET).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "internet",
                              name: "internet",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_WOHNRAUM_INTERNET
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_WOHNRAUM_INTERNET
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_WOHNRAUM_INTERNET
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_WOHNRAUM_INTERNET
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_WOHNRAUM_INTERNET
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_WOHNRAUM_INTERNET
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_WOHNRAUM_INTERNET
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("care4YouApp.fragebogen.sections.requirements")
                      ),
                    },
                  },
                  [_vm._v("Anforderung an die Betreuungskraft")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-3 border border-dark" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-geschlecht_maennlich",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.geschlecht_maennlich"
                            ),
                            "content-cols": "6",
                            "label-cols": "6",
                            "label-for": "geschlecht_maennlich",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_GESCHLECHT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANFORDERUNGEN_GESCHLECHT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "geschlecht_maennlich",
                              name: "geschlecht",
                              type: "radio",
                              value: "maennlich",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_GESCHLECHT
                                ).value,
                                "maennlich"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_GESCHLECHT
                                  ),
                                  "value",
                                  "maennlich"
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-geschlecht_weiblich",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.geschlecht_weiblich"
                            ),
                            "content-cols": "6",
                            "label-cols": "6",
                            "label-for": "geschlecht_weiblich",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_GESCHLECHT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANFORDERUNGEN_GESCHLECHT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "geschlecht_weiblich",
                              name: "geschlecht",
                              type: "radio",
                              value: "weiblich",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_GESCHLECHT
                                ).value,
                                "weiblich"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_GESCHLECHT
                                  ),
                                  "value",
                                  "weiblich"
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-geschlecht_egal",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.geschlecht_egal"
                            ),
                            "content-cols": "6",
                            "label-cols": "6",
                            "label-for": "geschlecht_egal",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_GESCHLECHT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANFORDERUNGEN_GESCHLECHT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "geschlecht_egal",
                              name: "geschlecht",
                              type: "radio",
                              value: "egal",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_GESCHLECHT
                                ).value,
                                "egal"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_GESCHLECHT
                                  ),
                                  "value",
                                  "egal"
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-3 border border-dark border-left-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-alter30",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.alter30"
                            ),
                            "content-cols": "6",
                            "label-cols": "6",
                            "label-for": "alter30",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_30BIS40
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANFORDERUNGEN_30BIS40).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "alter30",
                              name: "alter30",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_30BIS40
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_30BIS40
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_30BIS40
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_30BIS40
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_30BIS40
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_30BIS40
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_30BIS40
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-alter40",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.alter40"
                            ),
                            "content-cols": "6",
                            "label-cols": "6",
                            "label-for": "alter40",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_40BIS50
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANFORDERUNGEN_40BIS50).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "alter40",
                              name: "alter40",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_40BIS50
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_40BIS50
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_40BIS50
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_40BIS50
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_40BIS50
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_40BIS50
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_40BIS50
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-alter50",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.alter50"
                            ),
                            "content-cols": "6",
                            "label-cols": "6",
                            "label-for": "alter50",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_AB50
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANFORDERUNGEN_AB50).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "alter50",
                              name: "alter50",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_AB50
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_AB50
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_AB50
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_AB50
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_AB50
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_AB50
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_AB50
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-3 border border-dark border-left-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-deutsch_ausreichend",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.deutsch_ausreichend"
                            ),
                            "content-cols": "6",
                            "label-cols": "6",
                            "label-for": "deutsch_ausreichend",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "deutsch_ausreichend",
                              name: "deutsch_ausreichend",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-deutsch_gut",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.deutsch_gut"
                            ),
                            "content-cols": "6",
                            "label-cols": "6",
                            "label-for": "deutsch_gut",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_DEUTSCHGUT
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANFORDERUNGEN_DEUTSCHGUT).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "deutsch_gut",
                              name: "deutsch_gut",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_DEUTSCHGUT
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_DEUTSCHGUT
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_DEUTSCHGUT
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_DEUTSCHGUT
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_DEUTSCHGUT
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_DEUTSCHGUT
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_DEUTSCHGUT
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-3 border border-dark border-left-0" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-raucher",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.raucher"
                            ),
                            "content-cols": "6",
                            "label-cols": "6",
                            "label-for": "raucher",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_RAUCHER
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANFORDERUNGEN_RAUCHER).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "raucher",
                              name: "raucher",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_RAUCHER
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_RAUCHER
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_RAUCHER
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_RAUCHER
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_RAUCHER
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_RAUCHER
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_RAUCHER
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-nichtraucher",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.nichtraucher"
                            ),
                            "content-cols": "6",
                            "label-cols": "6",
                            "label-for": "nichtraucher",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_NICHTRAUCHER
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANFORDERUNGEN_NICHTRAUCHER).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "nichtraucher",
                              name: "nichtraucher",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_NICHTRAUCHER
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_NICHTRAUCHER
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_NICHTRAUCHER
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_NICHTRAUCHER
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_NICHTRAUCHER
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_NICHTRAUCHER
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_NICHTRAUCHER
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-raucher_egal",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.raucher_egal"
                            ),
                            "content-cols": "6",
                            "label-cols": "6",
                            "label-for": "raucher_egal",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_RAUCHEREGAL
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_ANFORDERUNGEN_RAUCHEREGAL).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "raucher_egal",
                              name: "raucher_egal",
                              type: "checkbox",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_ANFORDERUNGEN_RAUCHEREGAL
                                ).value
                              )
                                ? _vm._i(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_RAUCHEREGAL
                                    ).value,
                                    null
                                  ) > -1
                                : _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_RAUCHEREGAL
                                  ).value,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_ANFORDERUNGEN_RAUCHEREGAL
                                  ).value,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_RAUCHEREGAL
                                        ),
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getQuestion(
                                          _vm.attributes,
                                          _vm.QUESTION_ANFORDERUNGEN_RAUCHEREGAL
                                        ),
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.getQuestion(
                                      _vm.attributes,
                                      _vm.QUESTION_ANFORDERUNGEN_RAUCHEREGAL
                                    ),
                                    "value",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("care4YouApp.fragebogen.sections.recommendation")
                      ),
                    },
                  },
                  [_vm._v("Wer hat Ihnen die Betreuungsagentur empfohlen")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 border border-dark" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-empfehlung_pflegedienst",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.empfehlung_pflegedienst"
                            ),
                            "content-cols": "10",
                            "label-cols": "2",
                            "label-for": "empfehlung_pflegedienst",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_EMPFEHLUNG_PFLEGEDIENST
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_EMPFEHLUNG_PFLEGEDIENST).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "empfehlung_pflegedienst",
                              name: "empfehlung_pflegedienst",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_EMPFEHLUNG_PFLEGEDIENST
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_EMPFEHLUNG_PFLEGEDIENST
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-empfehlung_krankenhaus",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.empfehlung_krankenhaus"
                            ),
                            "content-cols": "10",
                            "label-cols": "2",
                            "label-for": "empfehlung_krankenhaus",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_EMPFEHLUNG_KRANKENHAUS
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_EMPFEHLUNG_KRANKENHAUS).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "empfehlung_krankenhaus",
                              name: "empfehlung_krankenhaus",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_EMPFEHLUNG_KRANKENHAUS
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_EMPFEHLUNG_KRANKENHAUS
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-empfehlung_sonstige",
                            label: _vm.$t(
                              "care4YouApp.fragebogen.fields.empfehlung_sonstige"
                            ),
                            "content-cols": "10",
                            "label-cols": "2",
                            "label-for": "empfehlung_sonstige",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.getQuestion(
                                  _vm.attributes,
                                  _vm.QUESTION_EMPFEHLUNG_SONSTIGE
                                ).value,
                                expression:
                                  "getQuestion(attributes, QUESTION_EMPFEHLUNG_SONSTIGE).value",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              height: "1.5rem",
                              margin: "1rem 0rem 0rem 0rem",
                            },
                            attrs: {
                              id: "empfehlung_sonstige",
                              name: "empfehlung_sonstige",
                              type: "text",
                              disabled: _vm.readonlyMode,
                            },
                            domProps: {
                              value: _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_EMPFEHLUNG_SONSTIGE
                              ).value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_EMPFEHLUNG_SONSTIGE
                                  ),
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("h3", {
                  staticClass: "text-center",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("care4YouApp.fragebogen.sections.dataPrivacy")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("h5", {
                  staticClass: "text-center",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("care4YouApp.fragebogen.additions.dataPrivacy")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-1" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getQuestion(
                            _vm.attributes,
                            _vm.QUESTION_DATENSCHUTZ_AKZEPTIERT
                          ).value,
                          expression:
                            "getQuestion(attributes, QUESTION_DATENSCHUTZ_AKZEPTIERT).value",
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: {
                        height: "1.5rem",
                        margin: "1rem 0rem 0rem 0rem",
                      },
                      attrs: {
                        id: "datenschutz_akzeptiert",
                        name: "datenschutz_akzeptiert",
                        type: "checkbox",
                        disabled: _vm.readonlyMode,
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.getQuestion(
                            _vm.attributes,
                            _vm.QUESTION_DATENSCHUTZ_AKZEPTIERT
                          ).value
                        )
                          ? _vm._i(
                              _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_DATENSCHUTZ_AKZEPTIERT
                              ).value,
                              null
                            ) > -1
                          : _vm.getQuestion(
                              _vm.attributes,
                              _vm.QUESTION_DATENSCHUTZ_AKZEPTIERT
                            ).value,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.getQuestion(
                              _vm.attributes,
                              _vm.QUESTION_DATENSCHUTZ_AKZEPTIERT
                            ).value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DATENSCHUTZ_AKZEPTIERT
                                  ),
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.getQuestion(
                                    _vm.attributes,
                                    _vm.QUESTION_DATENSCHUTZ_AKZEPTIERT
                                  ),
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.getQuestion(
                                _vm.attributes,
                                _vm.QUESTION_DATENSCHUTZ_AKZEPTIERT
                              ),
                              "value",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-11" }, [
                    _c("h5", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "care4YouApp.fragebogen.fields.datenschutz_ueberschrift"
                          ) + _vm.getObligatorySign()
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "mb-0",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "care4YouApp.fragebogen.fields.datenschutz_text"
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("p", [
                      _c("a", {
                        attrs: { href: _vm.agencyWebsite, target: "_blank" },
                        domProps: { textContent: _vm._s(_vm.agencyWebsite) },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm.isSaveButtonVisible()
                  ? _c(
                      "b-button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          id: "submitFragebogen",
                          disabled:
                            _vm.getQuestion(
                              _vm.attributes,
                              _vm.QUESTION_DATENSCHUTZ_AKZEPTIERT
                            ).value != true || _vm.isSaving,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submitFragebogen()
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "save" } }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "d-none d-md-inline",
                            domProps: {
                              textContent: _vm._s(_vm.$t("entity.action.save")),
                            },
                          },
                          [_vm._v("Save")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }