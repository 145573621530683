import { Component, Inject, Prop, Provide, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import JhiDataUtils from '@/shared/data/data-utils.service';
import CareUtils from '@/shared/utils/care-utils.service';
import Vue2Filters from 'vue2-filters';
import AlertService from '@/shared/alert/alert.service';
import WatcherService from '@/entities/watcher/watcher.service';
import { Watcher } from '@/shared/model/watcher.model';

@Component({
  mixins: [Vue2Filters.mixin],
  components: {},
})
export default class WatcherComponent extends mixins(JhiDataUtils, CareUtils) {
  @Prop({ required: true }) readonly watcherKey: string;
  @Prop({ required: false }) readonly parameter: string;
  @Prop({ required: true }) readonly watcherTitle: string;
  @Prop({ required: true }) readonly isDisabled: boolean;
  @Inject('alertService') protected alertService: () => AlertService;
  @Provide('watcherService') private watcherService = () => new WatcherService();

  public hasUserWatcher = false;

  @Watch('watcherKey')
  onDataChangedWatcherKey(value: string, oldValue: string) {
    if (value != null) {
      this.getHasUserWatcher(value, this.parameter);
    }
  }

  @Watch('parameter')
  onDataChangedParameter(value: string, oldValue: string) {
    if (value != null) {
      this.getHasUserWatcher(this.watcherKey, value);
    }
  }

  public getHasUserWatcher(watcherKey: string, parameter: string): void {
    this.watcherService()
      .hasUserWatcher(watcherKey, parameter)
      .then(res => {
        this.hasUserWatcher = res;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public addUserWatcher() {
    const newWatcher = new Watcher();
    newWatcher.watcherKey = this.watcherKey;
    newWatcher.parameter = this.parameter;
    this.watcherService()
      .addWatcherForCurrentUser(newWatcher)
      .then(res => {
        this.hasUserWatcher = res != null;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public removeUserWatcher() {
    this.watcherService()
      .removeWatcherForCurrentUser(this.watcherKey, this.parameter)
      .then(res => {
        this.hasUserWatcher = false;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public getTranslationRemoveWatcher(): string {
    return this.$t('care4YouApp.watcherComponent.removeWatcher', { param: this.watcherTitle }).toString();
  }
  public getTranslationAddWatcher(): string {
    return this.$t('care4YouApp.watcherComponent.addWatcher', { param: this.watcherTitle }).toString();
  }
}
