export enum FrageDiagnostizierteKrankheit {
  GEHSCHWAECHE = 'gehschwaeche',

  SCHLAGANFALL = 'schlaganfall',

  HERZRHYTHMUSSTROERUNG = 'herzrhythmusstoerung',

  HERZINSUFFIZIENZ = 'herzinsuffizienz',

  HYPERTONIE = 'hypertonie',

  HERZINFARKT = 'herzinfarkt',

  ASTHMA = 'asthma',

  OSTEOPOROSE = 'osteoporose',

  RHEUMA = 'rheuma',

  DEKUBITUS = 'dekubitus',

  INKONTINENZ = 'inkontinenz',

  STOMA = 'stoma',

  BEGINNENDE_DEMENZ = 'beginnende_demenz',

  DEMENZ = 'demenz',

  ALZHEIMER = 'alzheimer',

  DEPRESSION = 'depression',

  PARKINSON = 'parkinson',

  MULTIPLE_SKLEROSE = 'multiple_sklerose',

  DIABETES = 'diabetes',

  DIABETES_INSULINPFLICHTIG = 'diabetes_insulinpflichtig',

  ALLERGIEN = 'allergien',

  CHRONISCHE_DURCHFAELLE = 'chronische_durchfaelle',

  TUMOR = 'tumor',

  COPD = 'copd',

  FRAKTUR_NACH_STURZ = 'fraktur_nach_sturz',
}
