import { Component, Inject, Vue } from 'vue-property-decorator';
import PostboxService from '@/postbox/postbox.service';
import BpmProcessManagementService from '@/shared/data/bpm-process-management.service';
import AlertService from '@/shared/alert/alert.service';
import VueI18n from 'vue-i18n';
import { UserTaskDecision } from '@/shared/model/enumerations/user-task-decision.model';

export class EntityData {
  type: string;
  entries: any[];

  public constructor(type: string, entries: any[]) {
    this.type = type;
    this.entries = entries;
  }
}

export class ProcessVariable {
  key: string;
  value: any;

  public constructor(key: string, value: any) {
    this.key = key;
    this.value = value;
  }
}

export class TaskData {
  completeTask: boolean;
  processVariables: ProcessVariable[];
  entities: EntityData[];
  entitiesToDelete: EntityData[];
  task: Task;

  public constructor(complete: boolean, variables: ProcessVariable[], entities: EntityData[], entitiesToDelete: EntityData[]) {
    this.completeTask = complete;
    this.processVariables = variables;
    this.entities = entities;
    this.entitiesToDelete = entitiesToDelete;
  }
}

export class Task {
  taskId: number;
  title: string;
  description: string;
  user: string;
  userGroup: string;
  taskType: string;
  dueDate: string;
}

@Component
export default class TaskDataUtils extends Vue {
  public isLoadingTaskData = false;
  public isSavingTaskData = false;
  protected taskData: TaskData = null;
  protected taskId = null;
  protected isActionMenuOpen = true;
  public isNoInterestQuestionPopUpOpen = false;
  public notInterestPopUpAnswer = '';
  @Inject('postboxService') protected postboxService: () => PostboxService;
  @Inject('bpmProcessManagementService') public bpmProcessManagementService: () => BpmProcessManagementService;
  @Inject('alertService') protected alertService: () => AlertService;

  toggleActionMenu() {
    this.isActionMenuOpen = !this.isActionMenuOpen;
  }

  public completeWithNoInterest(): void {
    this.isNoInterestQuestionPopUpOpen = true;
  }

  public confirmNoInterestPopUp(): void {
    const data = this.buildDataObject(
      true,
      [
        new ProcessVariable('userTaskDecision', UserTaskDecision.ENDPROCESS),
        new ProcessVariable('noInterestAnswer', this.notInterestPopUpAnswer),
      ],
      null,
      null
    );
    this.callService(data, this.$t('care4YouApp.carerSuggestion.customerEndedProcess'));
  }

  public closeNoInterestPopUp(): void {
    this.isNoInterestQuestionPopUpOpen = false;
    this.notInterestPopUpAnswer = '';
  }

  public completeTaskWithNothingSpecialToSave(message) {
    const data = this.buildDataObject(true, null, null, null);

    this.callService(data, message);
  }

  buildDataObject(
    completeTask: boolean,
    processVariables: ProcessVariable[],
    entities: EntityData[],
    entitiesToDelete: EntityData[]
  ): TaskData {
    return new TaskData(completeTask, processVariables, entities, entitiesToDelete);
  }

  getProcessVariableFromTaskData(taskData: TaskData, variable: string): any {
    const variableVar = taskData.processVariables.filter(value => value['key'] == variable).shift();
    if (variableVar !== undefined) {
      return JSON.parse(variableVar['value']);
    }

    return null;
  }

  getProcessVariableFromTaskDataWithDefault(taskData: TaskData, variable: string, defVal): any {
    const variableVar = taskData.processVariables.filter(value => value['key'] == variable).shift();
    if (variableVar !== undefined) {
      return JSON.parse(variableVar['value']);
    }

    return defVal;
  }

  readTaskData(taskId: number, additionalLogic?): void {
    this.isLoadingTaskData = true;
    this.bpmProcessManagementService()
      .getTaskWithVariables(taskId)
      .then(res => {
        this.taskData = res.data;
        this.taskId = taskId;
        if (additionalLogic !== null && additionalLogic !== undefined) {
          additionalLogic(this.taskData);
        }
        this.isLoadingTaskData = false;
      })
      .catch(error => {
        this.isLoadingTaskData = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public callService(data, message: VueI18n.TranslateResult, goBack?: boolean): void {
    this.isSavingTaskData = true;

    this.bpmProcessManagementService()
      .finishTaskWithData(this.taskId, data)
      .then(param => {
        this.isSavingTaskData = false;

        if (param && param.id != this.taskId) {
          this.$router.replace({ name: param.taskType, params: { taskId: param.id } });
        } else {
          if (typeof goBack === 'undefined' || goBack === true) {
            this.$router.replace({ name: 'Postbox' });
          }
        }

        return (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-bottom-right',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.isSavingTaskData = false;
        this.alertService().showHttpError(this, error.response);
      });
  }
}
