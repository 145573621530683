import { IContact } from '@/shared/model/contact.model';
import { ICustomer } from '@/shared/model/customer.model';
import { ICarer } from '@/shared/model/carer.model';
import { IApplicationUser } from '@/shared/model/application-user.model';

export interface IAgency {
  id?: number;
  name?: string | null;
  managingDirector?: string | null;
  agencyContact?: IContact | null;
  contacts?: IContact[] | null;
  offices?: IAgency[] | null;
  customers?: ICustomer[] | null;
  carers?: ICarer[] | null;
  applicationUsers?: IApplicationUser[] | null;
  baseOffice?: IAgency | null;
}

export class Agency implements IAgency {
  constructor(
    public id?: number,
    public name?: string | null,
    public managingDirector?: string | null,
    public agencyContact?: IContact | null,
    public contacts?: IContact[] | null,
    public offices?: IAgency[] | null,
    public customers?: ICustomer[] | null,
    public carers?: ICarer[] | null,
    public applicationUsers?: IApplicationUser[] | null,
    public baseOffice?: IAgency | null
  ) {}
}
