var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "vue-html2pdf",
        {
          ref: "invoice2Pdf",
          attrs: {
            "enable-download": false,
            "paginate-elements-by-height": 1100,
            "preview-modal": true,
          },
        },
        [
          _c(
            "section",
            { attrs: { slot: "pdf-content" }, slot: "pdf-content" },
            [
              _vm.selectedInvoice
                ? _c("div", { staticClass: "row justify-content-center" }, [
                    _c(
                      "div",
                      { staticClass: "col-10 mt-5" },
                      [
                        _c(
                          "b-container",
                          [
                            _c(
                              "b-row",
                              { attrs: { "align-h": "end" } },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "5" },
                                  },
                                  [
                                    _vm.selectedInvoice.agencyLogoImageType
                                      ? _c("img", {
                                          staticStyle: { "max-height": "96px" },
                                          attrs: {
                                            alt: "agency logo",
                                            src:
                                              "data:" +
                                              _vm.selectedInvoice
                                                .agencyLogoImageType +
                                              ";base64," +
                                              _vm.selectedInvoice
                                                .agencyLogoImageData,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-muted",
                                      staticStyle: { "font-size": "10px" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.selectedInvoice.agencyName) +
                                          " - " +
                                          _vm._s(
                                            _vm.selectedInvoice.agencyStreet
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.selectedInvoice
                                              .agencyHouseNumber
                                          ) +
                                          " -\n                  " +
                                          _vm._s(
                                            _vm.selectedInvoice.agencyPostcode
                                          ) +
                                          " " +
                                          _vm._s(_vm.selectedInvoice.agencyCity)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "care4YouApp.Salutation." +
                                            _vm.selectedInvoice
                                              .customerSalutation
                                        )
                                      ) +
                                        " " +
                                        _vm._s(
                                          _vm.selectedInvoice.customerTitle
                                        ) +
                                        "\n                  " +
                                        _vm._s(
                                          _vm.selectedInvoice.customerFirstName
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.selectedInvoice.customerLastName
                                        )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedInvoice.customerStreet
                                      ) +
                                        " " +
                                        _vm._s(
                                          _vm.selectedInvoice
                                            .customerHouseNumber
                                        )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedInvoice.customerPostcode
                                      ) +
                                        " " +
                                        _vm._s(_vm.selectedInvoice.customerCity)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedInvoice.agencyStreet
                                        ) +
                                          " " +
                                          _vm._s(
                                            _vm.selectedInvoice
                                              .agencyHouseNumber
                                          )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedInvoice.agencyPostcode
                                        ) +
                                          " " +
                                          _vm._s(_vm.selectedInvoice.agencyCity)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "Tel. " +
                                          _vm._s(
                                            _vm.selectedInvoice.agencyPhone
                                          )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.selectedInvoice.agencyMail)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedInvoice.agencyWebsite
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              { attrs: { "align-h": "end" } },
                              [
                                _c("b-col", { attrs: { cols: "9" } }, [
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("Rechnung")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Rechnung Nr: " +
                                          _vm._s(
                                            _vm.selectedInvoice.invoiceNumber
                                          )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            _vm.selectedInvoice.invoiceDate
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              [
                                _c("b-col", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "white-space": "pre-wrap",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.replaceWhiteSpace(
                                            _vm.replaceVariables(
                                              _vm.selectedInvoice
                                                .invoiceIntroduction
                                            )
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              { staticClass: "border-bottom" },
                              [
                                _c("b-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v("Beschreibung")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "12px" } },
                                      [_vm._v("Einzelpreis")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "12px" } },
                                      [_vm._v("Gesamtpreis")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              { staticClass: "border-bottom pt-1 pb-1" },
                              [
                                _c("b-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        "Vermittlung einer Betreuungskraft"
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            _vm.selectedInvoice.invoiceAmount /
                                              1.19
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            _vm.selectedInvoice.invoiceAmount /
                                              1.19
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { cols: "7" } }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.replaceVariables(
                                          _vm.selectedInvoice
                                            .invoicePerformancePeriod
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "border-bottom",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c("span", [_vm._v("Gesamtsumme")]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("zzgl. 19% MwSt")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "border-bottom text-right",
                                    attrs: { cols: "2" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            _vm.selectedInvoice.invoiceAmount /
                                              1.19
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            (_vm.selectedInvoice.invoiceAmount /
                                              119) *
                                              19
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { cols: "7" } }),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "border-bottom text-center",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Endbetrag")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "border-bottom text-right",
                                    attrs: { cols: "2" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toCurrency")(
                                              _vm.selectedInvoice.invoiceAmount
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              [
                                _c("b-col", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "white-space": "pre-wrap",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.replaceWhiteSpace(
                                            _vm.replaceVariables(
                                              _vm.selectedInvoice.invoiceFooter
                                            )
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              {},
                              [
                                _c("b-col", [
                                  _vm.selectedInvoice.agencySigningImageType
                                    ? _c("img", {
                                        staticStyle: { "max-height": "100px" },
                                        attrs: {
                                          alt: "agency logo",
                                          src:
                                            "data:" +
                                            _vm.selectedInvoice
                                              .agencySigningImageType +
                                            ";base64," +
                                            _vm.selectedInvoice
                                              .agencySigningImageData,
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              { staticStyle: { "margin-top": "-20px" } },
                              [
                                _c("b-col", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.selectedInvoice.agencyName)
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "border-top text-center text-muted",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "white-space": "pre-wrap",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.replaceWhiteSpace(
                                              _vm.selectedInvoice
                                                .invoicePageFooter
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }