import Vuex from 'vuex';
import VueI18n, { DateTimeFormats } from 'vue-i18n';
import JhiFormatter from './formatter';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAmbulance } from '@fortawesome/free-solid-svg-icons/faAmbulance';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArchive } from '@fortawesome/free-solid-svg-icons/faArchive';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBellSlash } from '@fortawesome/free-solid-svg-icons/faBellSlash';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCircleDown } from '@fortawesome/free-solid-svg-icons/faCircleDown';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons/faCircleRight';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faFax } from '@fortawesome/free-solid-svg-icons/faFax';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons/faFileExcel';
import { faFileWord } from '@fortawesome/free-solid-svg-icons/faFileWord';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faIndustry } from '@fortawesome/free-solid-svg-icons/faIndustry';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faMessage } from '@fortawesome/free-solid-svg-icons/faMessage';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMobile } from '@fortawesome/free-solid-svg-icons/faMobile';
import { faNoteSticky } from '@fortawesome/free-solid-svg-icons/faNoteSticky';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons/faEuroSign';
import { faPersonRunning } from '@fortawesome/free-solid-svg-icons/faPersonRunning';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faRoad } from '@fortawesome/free-solid-svg-icons/faRoad';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons/faRotateLeft';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSection } from '@fortawesome/free-solid-svg-icons/faSection';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons/faThumbsDown';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faRepeat } from '@fortawesome/free-solid-svg-icons/faRepeat';

import VueCookie from 'vue-cookie';
import Vuelidate from 'vuelidate';
import Vue2Filters from 'vue2-filters';

import * as filters from '@/shared/date/filters';
import { accountStore } from '@/shared/config/store/account-store';
import { translationStore } from '@/shared/config/store/translation-store';
import {
  faArrowRight,
  faCheck,
  faCircleInfo,
  faCircleQuestion,
  faCircleUp,
  faCircleXmark,
  faUserPen,
} from '@fortawesome/free-solid-svg-icons';
import { faUserNurse } from '@fortawesome/free-solid-svg-icons/faUserNurse';
import { selectionCriteriaStore } from '@/shared/config/store/selection-criteria-store';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare';

const dateTimeFormats: DateTimeFormats = {
  de: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  ro: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  // jhipster-needle-i18n-language-date-time-format - JHipster will add/remove format options in this object
};

export function initVueApp(vue) {
  vue.use(VueCookie);
  vue.use(Vuelidate);
  vue.use(Vue2Filters);
  filters.initFilters();
}

export function initFortAwesome(vue) {
  library.add(
    faAmbulance,
    faArrowLeft,
    faArrowRight,
    faArrowUpRightFromSquare,
    faArchive,
    faAsterisk,
    faBan,
    faBars,
    faBell,
    faBellSlash,
    faBook,
    faCalendar,
    faChartPie,
    faCheck,
    faCircleRight,
    faCircleDown,
    faCircleUp,
    faCircleInfo,
    faCircleQuestion,
    faCircleXmark,
    faClock,
    faCloud,
    faCogs,
    faDatabase,
    faDownload,
    faEnvelope,
    faStar,
    faEuroSign,
    faEye,
    faFax,
    faFacebook,
    faFile,
    faFilePdf,
    faFileExcel,
    faFileWord,
    faInfo,
    faFlag,
    faGear,
    faHeart,
    faHome,
    faBuilding,
    faIndustry,
    faList,
    faLock,
    faMagnifyingGlass,
    faMessage,
    faMinus,
    faMobile,
    faNoteSticky,
    faPencilAlt,
    faPersonRunning,
    faPhone,
    faPlus,
    faPrint,
    faRoad,
    faRotateLeft,
    faSave,
    faSearch,
    faSection,
    faShare,
    faSignInAlt,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faThumbsDown,
    faThumbsUp,
    faTimes,
    faTimesCircle,
    faTrash,
    faUser,
    faUserNurse,
    faUserPen,
    faUserPlus,
    faUsers,
    faUsersCog,
    faWhatsapp,
    faWrench,
    faXmark,
    faRepeat
  );
}

export function initI18N(vue) {
  vue.use(VueI18n);
  return new VueI18n({
    dateTimeFormats,
    silentTranslationWarn: true,
    formatter: new JhiFormatter(),
  });
}

export function initVueXStore(vue) {
  vue.use(Vuex);
  return new Vuex.Store({
    modules: {
      accountStore,
      translationStore,
      selectionCriteriaStore,
    },
  });
}
