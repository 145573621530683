var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.watcherKey
    ? _c(
        "span",
        [
          _vm.hasUserWatcher
            ? _c("font-awesome-icon", {
                staticStyle: { cursor: "pointer" },
                attrs: {
                  icon: "bell",
                  size: "lg",
                  disabled: _vm.isDisabled,
                  title: _vm.getTranslationRemoveWatcher(),
                },
                on: { click: _vm.removeUserWatcher },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasUserWatcher
            ? _c("font-awesome-icon", {
                staticStyle: { cursor: "pointer" },
                attrs: {
                  icon: "bell-slash",
                  size: "lg",
                  disabled: _vm.isDisabled,
                  title: _vm.getTranslationAddWatcher(),
                },
                on: { click: _vm.addUserWatcher },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }