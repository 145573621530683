export enum CareDuration {
  ENDLESS = 'endless',

  MONTH1 = 'month1',

  MONTH2 = 'month2',

  MONTH3 = 'month3',

  MONTH4 = 'month4',

  MONTH5 = 'month5',

  MONTH6 = 'month6',

  MONTH7 = 'month7',

  MONTH8 = 'month8',

  MONTH9 = 'month9',

  MONTH10 = 'month10',

  MONTH11 = 'month11',

  MONTH12 = 'month12',
}
