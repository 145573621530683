import { Component, Inject, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import JhiDataUtils from '@/shared/data/data-utils.service';
import CareUtils from '@/shared/utils/care-utils.service';
import Vue2Filters from 'vue2-filters';
import { CarerSuggestion } from '@/shared/model/carer-suggestion.model';
import AgencyService from '@/entities/agency/agency.service';
import AlertService from '@/shared/alert/alert.service';

@Component({
  mixins: [Vue2Filters.mixin],
  components: {},
})
export default class CarerSuggestion2PdfComponent extends mixins(JhiDataUtils, CareUtils) {
  @Inject('alertService') protected alertService: () => AlertService;
  @Prop({ required: true }) readonly carerSuggestion: CarerSuggestion;
  @Prop({ required: false }) readonly languageSkill: string;
  @Prop({ required: false }) readonly character: string;
  private agencyName = '';
  private agencyWebsite = '';
  private agencyPhone = '';
  private agencyMail = '';
  private agencyPostcode = '';
  private agencyCity = '';
  private agencyHouseNumber = '';
  private agencyStreet = '';
  private agencyLogoImage = '';
  private agencyLogoImageContentType = '';
  private characterDerived = 'Freundlich, gutmütig, ruhig';
  private languageSkillDerived = 'Gute Kenntnisse';
  private printingDataLoaded = false;

  public generatePdf(suggestion) {
    if (suggestion != null) {
      if (suggestion.customer == null || suggestion.customer.agency == null || suggestion.customer.agency.id == null) {
        this.alertService().showError(this, 'global.messages.validate.agency.required');
      } else {
        this.loadPrintingData(suggestion);
      }
    }
  }

  private getGermanLanguageSkill(instance: CarerSuggestion): number {
    if (instance.carer != null && instance.carer.languageSkills != null) {
      const filteredSkills = instance.carer.languageSkills.filter(value => {
        return value.language.valueOf() == 'GERMAN';
      });
      if (filteredSkills.length > 0) {
        return filteredSkills[0].level;
      }
    }
    return 0;
  }

  private forceToNumber(val): number {
    const num: number = val;
    return num;
  }

  private generateReport(instance: CarerSuggestion) {
    this.printingDataLoaded = false;
    this.agencyService()
      .retrieveAgencyDataForProposalPrinting(instance.customer.agency.id)
      .then(
        res => {
          const data = res;
          if (data && data['agencyLogoImageData']) {
            this.agencyLogoImage = data['agencyLogoImageData'];
          }
          if (data && data['agencyLogoImageType']) {
            this.agencyLogoImageContentType = data['agencyLogoImageType'];
          }
          if (data && data['agencyName']) {
            this.agencyName = data['agencyName'];
          }
          if (data && data['agencyStreet']) {
            this.agencyStreet = data['agencyStreet'];
          }
          if (data && data['agencyHouseNumber']) {
            this.agencyHouseNumber = data['agencyHouseNumber'];
          }
          if (data && data['agencyPostcode']) {
            this.agencyPostcode = data['agencyPostcode'];
          }
          if (data && data['agencyCity']) {
            this.agencyCity = data['agencyCity'];
          }
          if (data && data['agencyWebsite']) {
            this.agencyWebsite = data['agencyWebsite'];
          }
          if (data && data['agencyMail']) {
            this.agencyMail = data['agencyMail'];
          }
          if (data && data['agencyPhone']) {
            this.agencyPhone = data['agencyPhone'];
          }
          this.printingDataLoaded = true;

          // @ts-ignore
          this.$refs.realCarerSuggestionHtml2Pdf.generatePdf();
        },
        error => {
          this.alertService().showHttpError(this, error.response);
        }
      );
  }

  @Provide('agencyService') private agencyService = () => new AgencyService();

  private loadPrintingData(suggestion: CarerSuggestion) {
    this.languageSkillDerived = this.$t('care4YouApp.languageSkill.levels.' + this.getGermanLanguageSkill(suggestion)).toString();
    this.characterDerived = suggestion?.carer?.character;
    this.generateReport(suggestion);
  }
}
