import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IPatient } from '@/shared/model/patient.model';
import {IDynamicField} from "@/shared/model/dynamic-field.model";

const baseApiUrl = 'api/patients';
const baseSearchApiUrl = 'api/_search/patients?query=';
const patientDynamicFieldsApiUrl = 'dynamic-fields'

export default class PatientService {
  public search(query, paginationQuery): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseSearchApiUrl}${query}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<IPatient> {
    return new Promise<IPatient>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IPatient): Promise<IPatient> {
    return new Promise<IPatient>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IPatient): Promise<IPatient> {
    return new Promise<IPatient>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IPatient): Promise<IPatient> {
    return new Promise<IPatient>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByTaskId(taskId: number): Promise<IPatient[]> {
    return new Promise<IPatient[]>((resolve, reject) => {
      axios
        .get(`api/patient-by-task-id/${taskId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByCustomerId(custId: number): Promise<IPatient[]> {
    return new Promise<IPatient[]>((resolve, reject) => {
      axios
        .get(`api/patient-by-customer-id/${custId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findFull(id: number): Promise<IPatient> {
    return new Promise<IPatient>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}-full/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateForCustomer(entity: IPatient): Promise<IPatient> {
    return new Promise<IPatient>((resolve, reject) => {
      axios
        .put(`api/customer-patients/patient/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createForCustomer(custId: number, entity: IPatient): Promise<IPatient> {
    return new Promise<IPatient>((resolve, reject) => {
      axios
        .put(`api/customer-patients/${custId}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getPatientDynamicFields(id: number): Promise<IDynamicField[]> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}/${patientDynamicFieldsApiUrl}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // Additional method
  public addDynamicFieldsToPatient(id: number, dynamicFields: IDynamicField[]): Promise<IDynamicField[]> {
    return new Promise<IDynamicField[]>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/${id}/${patientDynamicFieldsApiUrl}`, dynamicFields)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteDynamicField(id: number, key: string) {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}/dynamicFields/${key}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
