export enum ValueType {
  BOOLEAN = 'boolean',

  INTEGER = 'integer',

  STRING = 'string',

  DATE = 'date',

  TIME = 'time',

  TIMERANGE = 'timerange',

  FLOAT = 'float',

  ENUM = 'enumsingle',

  ENUMLIST = 'enumlist',
}
