export enum FrageMobilitaetshilfe {
  GEHSTOCK = 'gehstock',

  ROLLATOR = 'rollator',

  PATIENTENLIFTER = 'patientenlifter',

  ROLLSTUHL = 'rollstuhl',

  TOILETTENSITZERHOEHUNG = 'toilettensitzerhoehung',

  TOILETTENSTUHL = 'toilettenstuhl',

  DUSCHSTUHL = 'duschstuhl',

  BADEWANNENLIFTER = 'badewannenlifter',

  DEKUBITUSMATRATZE = 'dekubitusmatratze',

  PFLEGEBETT = 'pflegebett',

  TREPPENLIFT = 'treppenlift',

  KOMPRESSIONSTRUEMPFE = 'kompressionsstruempfe',
}
