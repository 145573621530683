var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-navbar",
    {
      staticClass: "cfooter bg-dark",
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _c(
        "b-collapse",
        { attrs: { id: "header-tabs", "is-nav": "" } },
        [
          _c(
            "b-navbar-nav",
            [
              _c("b-nav-item", [
                _c(
                  "span",
                  {
                    domProps: {
                      textContent: _vm._s(_vm.$t("footer.copyright")),
                    },
                  },
                  [_vm._v("© app:team:al")]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c("b-nav-item", { attrs: { exact: "", to: "/impressum" } }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", { attrs: { icon: "section" } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.menu.impressum")),
                        },
                      },
                      [_vm._v("Impressum")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }