import { CareTerminationReason } from '@/shared/model/enumerations/care-termination-reason';

export interface ICareAssignmentTermination {
  userTaskDate?: string | null;
  terminationDate?: string | null;
  careAssignmentId?: number | null;
  note?: string | null;
  terminationReason?: CareTerminationReason | null;
}

export class CareAssignmentTermination implements ICareAssignmentTermination {
  constructor(
    public userTaskDate?: string | null,
    public terminationDate?: string | null,
    public careAssignmentId?: number | null,
    public note?: string | null,
    public terminationReason?: CareTerminationReason | null
  ) {}
}
