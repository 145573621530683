import { Component, Inject, Provide, Vue } from 'vue-property-decorator';
import { onUpdated } from 'vue';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';
import TranslationService from '@/locale/translation.service';

import EntitiesMenu from '@/entities/entities-menu.vue';
import BpmProcessManagementService from '@/shared/data/bpm-process-management.service';
import AlertService from '@/shared/alert/alert.service';
import dayjs from 'dayjs';
import NotificationService from '@/entities/notification/notification.service';

@Component({
  components: {
    'entities-menu': EntitiesMenu,
  },
})
export default class JhiNavbar extends Vue {
  @Inject('loginService')
  private loginService: () => LoginService;
  @Inject('translationService') private translationService: () => TranslationService;

  @Inject('accountService') private accountService: () => AccountService;
  @Inject('bpmProcessManagementService') private bpmProcessManagementService: () => BpmProcessManagementService;
  @Inject('alertService') protected alertService: () => AlertService;
  @Provide('notificationService') private notificationService = () => new NotificationService();
  public version = 'v' + VERSION;
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValues = {};
  public notificationCount = 0;

  created() {
    const currentLanguage = Object.keys(this.languages).includes(navigator.language) ? navigator.language : this.currentLanguage;
    this.translationService().refreshTranslation(currentLanguage);
    this.getNotificationCount();
    onUpdated(() => this.getNotificationCount());
  }

  public getNotificationCount() {
    if (this.authenticated) {
      this.notificationService()
        .retrieveCountForCurrentUser()
        .then(
          value => {
            this.notificationCount = value.data;
          },
          reason => {
            this.notificationCount = -1;
          }
        );
    }
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): Promise<any> {
    return this.loginService()
      .logout()
      .then(response => {
        this.$store.commit('logout');
        window.location.href = response.data.logoutUrl;
        const next = response.data?.logoutUrl ?? '/';
        if (this.$route.path !== next) {
          return this.$router.push(next);
        }
      });
    return Promise.resolve(this.$router.currentRoute);
  }

  public openLogin(): void {
    this.loginService().login();
  }

  public scheduleCheckInvoices(): void {
    this.bpmProcessManagementService()
      .startProcess('check-invoices-process', {
        CALCULATED_TO: JSON.stringify(dayjs(Date.UTC(2023, 4, 31)).format('YYYY-MM-DD')),
        USER_TASK_DUE_DATE: JSON.stringify(dayjs(Date.UTC(2023, 4, 31)).format('YYYY-MM-DD')),
      })
      .then(res => {
        return (this.$root as any).$bvToast.toast('Rechnungsprüfung eingeplant', {
          toaster: 'b-toaster-bottom-right',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public scheduleCheckInvoicePayments(): void {
    this.bpmProcessManagementService()
      .startProcess('check-invoice-payments-process', {})
      .then(res => {
        return (this.$root as any).$bvToast.toast('Zahlungseingangsprüfung eingeplant', {
          toaster: 'b-toaster-bottom-right',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        if (this.hasAnyAuthorityValues[authorities] !== value) {
          this.hasAnyAuthorityValues = { ...this.hasAnyAuthorityValues, [authorities]: value };
        }
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }

  public get username(): string {
    return this.$store.getters.account?.login ?? '';
  }
}
