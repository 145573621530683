import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICareAssignment } from '@/shared/model/care-assignment.model';

const baseApiUrl = 'api/care-assignments';
const multipleApiUrl = 'api/multiple-care-assignments';
const baseSearchApiUrl = 'api/_search/care-assignments?query=';

export default class CareAssignmentService {
  public search(query, paginationQuery): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseSearchApiUrl}${query}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<ICareAssignment> {
    return new Promise<ICareAssignment>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findFull(id: number): Promise<ICareAssignment> {
    return new Promise<ICareAssignment>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}-full/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICareAssignment): Promise<ICareAssignment> {
    return new Promise<ICareAssignment>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICareAssignment): Promise<ICareAssignment> {
    return new Promise<ICareAssignment>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createForCustomer(entity: ICareAssignment): Promise<ICareAssignment> {
    return new Promise<ICareAssignment>((resolve, reject) => {
      axios
        .post(`api/customer-care-assignments`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateForCustomer(entity: ICareAssignment): Promise<ICareAssignment> {
    return new Promise<ICareAssignment>((resolve, reject) => {
      axios
        .put(`api/customer-care-assignments/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICareAssignment): Promise<ICareAssignment> {
    return new Promise<ICareAssignment>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getInitialCareAssignmentsForCustomer(customerId: number): Promise<ICareAssignment[]> {
    return new Promise<ICareAssignment[]>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}-for-customer/${customerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createMultiple(entities: ICareAssignment[]): Promise<ICareAssignment[]> {
    return new Promise<ICareAssignment[]>((resolve, reject) => {
      axios
        .post(`${multipleApiUrl}`, entities)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
