import { Authority } from '@/shared/security/authority';

const MonthSalesComponent = () => import('@/statistic/month-sales/month-sales.vue');
export default [
  {
    path: '/statistics/month-sales',
    name: 'month-sales',
    component: MonthSalesComponent,
    meta: { authorities: [Authority.MANAGER] },
  },
];
