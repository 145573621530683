import Vue from 'vue';
import Component from 'vue-class-component';
import Router from 'vue-router';
import admin from '@/router/admin';
import entities from '@/router/entities';
import pages from '@/router/pages';
import { Authority } from '@/shared/security/authority';
import usertasks from '@/router/usertasks';
import customer from '@/router/customer';
import carer from '@/router/carer';
import statistics from '@/router/statistics';
import agency from '@/router/agency';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);

const Home = () => import('@/core/home/home.vue');
const Error = () => import('@/core/error/error.vue');
const Postbox = () => import('@/postbox/postbox.vue');
const Changelog = () => import('@/changelog/changelog.vue');
const Questionnaire = () => import('@/questionnaire/questionnaire.vue');
const Impressum = () => import('@/impressum/impressum.vue');
const HandleTaskComponent = () => import('@/handle-task/handle-task.vue');
const EditTaskDataComponent = () => import('@/edit-task/edit-task.vue');
const CustomerTaskComponent = () => import('@/customer-task/customer-task.vue');
const CheckInvoicesComponent = () => import('@/usertasks/check-invoices/check-invoices.vue');
const CheckInvoicePaymentsComponent = () => import('@/usertasks/check-invoice-payments/check-invoice-payments.vue');
const NotificationOverview = () => import('@/notification-overview/notification-overview.vue');
const UserDetailComponent = () => import('@/user-detail/user-detail.vue');
const OnlineFragebogen = () => import('@/online-fragebogen/online-fragebogen.vue');

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: Error,
      meta: { error403: true }
    },
    {
      path: '/not-found',
      name: 'NotFound',
      component: Error,
      meta: { error404: true }
    },
    {
      path: '/changelog',
      name: 'Changelog',
      component: Changelog
    },
    {
      path: '/postbox/:id',
      name: 'PostboxEntry',
      component: Postbox,
      meta: { authorities: [Authority.USER] }
    },
    {
      path: '/postbox',
      name: 'Postbox',
      component: Postbox,
      meta: { authorities: [Authority.USER] }
    },
    {
      path: '/questionnaire/:qtype',
      name: 'Questionnaire',
      component: Questionnaire,
      meta: { authorities: [Authority.USER] }
    },
    {
      path: '/online-fragebogen/:nummer',
      name: 'OnlineFragebogen',
      component: OnlineFragebogen,
      meta: { renderHeader: false, withinCard: false, renderFooter: false }
    },
    {
      path: '/create-task',
      name: 'CreateTask',
      component: HandleTaskComponent,
      meta: { authorities: [Authority.USER] }
    },
    {
      path: '/edit-task-data/:taskId',
      name: 'EditTaskData',
      component: EditTaskDataComponent,
      meta: { authorities: [Authority.USER] }
    },
    {
      path: '/create-customer-task/:customerId/:customerName',
      name: 'CreateCustomerTask',
      component: CustomerTaskComponent,
      meta: { authorities: [Authority.USER] }
    },
    {
      path: '/check-invoices',
      name: 'CheckInvoices',
      component: CheckInvoicesComponent,
      meta: { authorities: [Authority.USER] }
    },
    {
      path: '/check-invoice-payments',
      name: 'CheckInvoicePayments',
      component: CheckInvoicePaymentsComponent,
      meta: { authorities: [Authority.USER] }
    },
    {
      path: '/impressum',
      name: 'Impressum',
      component: Impressum
    },
    {
      path: '/notification-overview',
      name: 'NotificationOverview',
      component: NotificationOverview,
    },
    {
      path: '/account/settings/:tabIndex',
      name: 'AccountSettings',
      component: UserDetailComponent,
      meta: { authorities: [Authority.USER] }
    },
    ...agency,
    ...customer,
    ...carer,
    ...admin,
    entities,
    ...pages,
    ...usertasks,
    ...statistics
  ]
});

export default router;
