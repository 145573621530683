var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c(
      "div",
      [
        _c("carerSuggestion2Pdf", {
          ref: "realSuggestionPdf",
          attrs: { "carer-suggestion": _vm.suggestionForPrinting },
        }),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.isLoading
      ? _c("div", { staticClass: "col-10" }, [
          !_vm.ausweis()
            ? _c("div", [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-weight": "bold",
                      color: "red",
                      "text-align": "center",
                    },
                  },
                  [_vm._v(">>> !!! Bitte Ausweis anfügen !!! <<<")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            { attrs: { name: "editForm", novalidate: "", role: "form" } },
            [
              _c(
                "h2",
                { attrs: { id: "page-heading" } },
                [
                  _c(
                    "span",
                    {
                      attrs: { id: "care4YouApp.carerDetail.home.createLabel" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("care4YouApp.carerDetail.home.title")
                        ),
                      },
                    },
                    [_vm._v(" Carer Detail ")]
                  ),
                  _vm._v(" "),
                  _vm.paramsForOverview
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "CarerDetail",
                              params: { carerId: _vm.carer.id, tabIndex: 0 },
                            },
                            custom: "",
                            target: "_blank",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              title: _vm.translate("entity.action.openNewTab"),
                              icon: "arrow-up-right-from-square",
                              size: "sm",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tabs",
                {
                  attrs: { pills: "", vertical: "" },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t(
                          "care4YouApp.carerDetail.sections.generalInformation"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchTab(0)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "row justify-content-center" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _vm.carer
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass: "jh-entity-heading",
                                      attrs: {
                                        "data-cy": "carerDetailsHeading",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.carer.contact.lastName) +
                                          " " +
                                          _vm._s(_vm.carer.contact.firstName) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "dl",
                                    { staticClass: "row jh-entity-details" },
                                    [
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carer.image"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Image")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _vm.carer.image
                                          ? _c("div", [
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openFile(
                                                        _vm.carer
                                                          .imageContentType,
                                                        _vm.carer.image
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      "max-width": "200px",
                                                    },
                                                    attrs: {
                                                      src:
                                                        "data:" +
                                                        _vm.carer
                                                          .imageContentType +
                                                        ";base64," +
                                                        _vm.carer.image,
                                                      alt: "carer image",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.customer.agency"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Agency")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.agencyName) +
                                            "\n                  "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carer.birthday"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Birthday")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                _vm.carer.birthday
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carer.smoking"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Smoking")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.carer.smoking,
                                              expression: "carer.smoking",
                                            },
                                          ],
                                          staticClass: "form-check",
                                          attrs: {
                                            type: "checkbox",
                                            name: "drivingLicence",
                                            disabled: "true",
                                            id: "carer-drivingLicence",
                                            "data-cy": "drivingLicence",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.carer.smoking
                                            )
                                              ? _vm._i(
                                                  _vm.carer.smoking,
                                                  null
                                                ) > -1
                                              : _vm.carer.smoking,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.carer.smoking,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.carer,
                                                      "smoking",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.carer,
                                                      "smoking",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.carer,
                                                  "smoking",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carer.drivingLicence"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("DrivingLicence")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.carer.drivingLicence,
                                              expression:
                                                "carer.drivingLicence",
                                            },
                                          ],
                                          staticClass: "form-check",
                                          attrs: {
                                            type: "checkbox",
                                            name: "drivingLicence",
                                            disabled: "true",
                                            id: "carer-drivingLicence",
                                            "data-cy": "drivingLicence",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.carer.drivingLicence
                                            )
                                              ? _vm._i(
                                                  _vm.carer.drivingLicence,
                                                  null
                                                ) > -1
                                              : _vm.carer.drivingLicence,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.carer.drivingLicence,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.carer,
                                                      "drivingLicence",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.carer,
                                                      "drivingLicence",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.carer,
                                                  "drivingLicence",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carer.allergy"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Allergy")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.carer.allergy)),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carer.workExperience"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Work Experience")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.carer.workExperience)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carer.character"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Character")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.carer.character)),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carer.languageSkills"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("LanguageSkills")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c(
                                          "div",
                                          {
                                            model: {
                                              value: _vm.carer.contact,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.carer,
                                                  "contact",
                                                  $$v
                                                )
                                              },
                                              expression: "carer.contact",
                                            },
                                          },
                                          _vm._l(
                                            _vm.carer.languageSkills,
                                            function (com, comIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: comIndex,
                                                  staticClass: "row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                com.language,
                                                              expression:
                                                                "com.language",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            id: "languageSkill-language",
                                                            disabled: "",
                                                            name: "language",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                com,
                                                                "language",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.languages,
                                                          function (language) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: language,
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "care4YouApp.Language." +
                                                                      language
                                                                  ),
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    language,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      language
                                                                    ) +
                                                                    "\n                            "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: com.level,
                                                              expression:
                                                                "com.level",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            id: "languageSkill-languageSkill",
                                                            disabled: "",
                                                            name: "level",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                com,
                                                                "level",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.languageSkillEnums,
                                                          function (
                                                            languageSkillEnum
                                                          ) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: languageSkillEnum,
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "care4YouApp.languageSkill.levels." +
                                                                      languageSkillEnum
                                                                  ),
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    languageSkillEnum,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      languageSkillEnum
                                                                    ) +
                                                                    "\n                            "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carer.rating"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Rating")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c(
                                          "div",
                                          {
                                            model: {
                                              value: _vm.carer.ratings,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.carer,
                                                  "ratings",
                                                  $$v
                                                )
                                              },
                                              expression: "carer.ratings",
                                            },
                                          },
                                          _vm._l(
                                            _vm.carer.ratings,
                                            function (com, comIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: comIndex,
                                                  staticClass: "row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "align-middle form-control",
                                                        },
                                                        _vm._l(
                                                          com.stars,
                                                          function (n) {
                                                            return _c(
                                                              "font-awesome-icon",
                                                              {
                                                                key: n,
                                                                attrs: {
                                                                  icon: "star",
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-6",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: com.note,
                                                            expression:
                                                              "com.note",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "rating-note",
                                                          readonly: "",
                                                          name: "rating",
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value: com.note,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              com,
                                                              "note",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carer.archive"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Archive")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.carer.archive,
                                              expression: "carer.archive",
                                            },
                                          ],
                                          staticClass: "form-check",
                                          attrs: {
                                            id: "carer-archive",
                                            disabled: "true",
                                            name: "archive",
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.carer.archive
                                            )
                                              ? _vm._i(
                                                  _vm.carer.archive,
                                                  null
                                                ) > -1
                                              : _vm.carer.archive,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.carer.archive,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.carer,
                                                      "archive",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.carer,
                                                      "archive",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.carer,
                                                  "archive",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.carer.id &&
                                  !_vm.paramsForOverview &&
                                  (_vm.carer.archive == null ||
                                    _vm.carer.archive == false)
                                    ? _c("router-link", {
                                        attrs: {
                                          to: {
                                            name: "CarerUpdate",
                                            params: { carerId: _vm.carer.id },
                                          },
                                          custom: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ navigate }) {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-primary",
                                                      on: { click: navigate },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: "pencil-alt",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "entity.action.edit"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v(" Edit")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2496563092
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t(
                          "care4YouApp.carerDetail.sections.contact"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchTab(1)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "row justify-content-center" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _vm.carer.contact
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass: "jh-entity-heading",
                                      attrs: {
                                        "data-cy": "contactDetailsHeading",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.contact.detail.title"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Contact")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "dl",
                                    { staticClass: "row jh-entity-details" },
                                    [
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.salutation"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Salutation")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.Salutation." +
                                                    _vm.carer.contact.salutation
                                                )
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.carer.contact.salutation
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.title"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Title")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.carer.contact.title)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.firstName"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("First Name")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.carer.contact.firstName)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.lastName"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Last Name")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.carer.contact.lastName)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.callName"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Call Name")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.carer.contact.callName)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.gender"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Gender")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.Gender." +
                                                    _vm.carer.contact.gender
                                                )
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.carer.contact.gender)
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.taxNumberCarer"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Tax Number")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.carer.contact.taxNumber)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.street"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Street")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.carer.contact.street)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.houseNumber"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("House Number")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.carer.contact.houseNumber
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.postcode"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Postcode")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.carer.contact.postcode)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.city"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("City")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.carer.contact.city)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.country"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Country")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.carer.contact.country)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.contact.communications"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("Communications")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c(
                                          "div",
                                          {
                                            model: {
                                              value: _vm.carer.contact,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.carer,
                                                  "contact",
                                                  $$v
                                                )
                                              },
                                              expression: "carer.contact",
                                            },
                                          },
                                          _vm._l(
                                            _vm.carer.contact.communications,
                                            function (com, comIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: comIndex,
                                                  staticClass: "row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-1",
                                                    },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "align-middle form-control",
                                                        },
                                                        [
                                                          com.communicationType ==
                                                          "TELEPHONE"
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "phone",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          com.communicationType ==
                                                          "MOBILEPHONE"
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "mobile",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          com.communicationType ==
                                                          "EMAIL"
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "envelope",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          com.communicationType ==
                                                          "FAX"
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "fax",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          com.communicationType ==
                                                          "MESSANGER"
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "message",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          com.communicationType ==
                                                          "EMERGENCYPHONE"
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "ambulance",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          com.communicationType ==
                                                          "PRIVATE"
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "home",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          com.communicationType ==
                                                          "BUSINESS"
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "industry",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          com.communicationType ==
                                                          "WHATSAPP"
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "fa-brands fa-whatsapp",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          com.communicationType ==
                                                          "FACEBOOK"
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "fa-brands fa-facebook",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-5",
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                com.communicationType,
                                                              expression:
                                                                "com.communicationType",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            id: "communication-communicationtype",
                                                            disabled: "",
                                                            name: "communicationtype",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                com,
                                                                "communicationType",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.communicationTypeValues,
                                                          function (
                                                            communicationType
                                                          ) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: communicationType,
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "care4YouApp.CommunicationType." +
                                                                      communicationType
                                                                  ),
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    communicationType,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      communicationType
                                                                    ) +
                                                                    "\n                            "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-5",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              com.contactValue,
                                                            expression:
                                                              "com.contactValue",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "communication-contact",
                                                          readonly: "",
                                                          name: "contact",
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            com.contactValue,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              com,
                                                              "contactValue",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.carer.contact.id &&
                                  !_vm.paramsForOverview &&
                                  (_vm.carer.archive == null ||
                                    _vm.carer.archive == false)
                                    ? _c("router-link", {
                                        attrs: {
                                          to: {
                                            name: "CarerContactUpdate",
                                            params: {
                                              contactId: _vm.carer.contact.id,
                                            },
                                          },
                                          custom: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ navigate }) {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-primary",
                                                      on: { click: navigate },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: "pencil-alt",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "entity.action.edit"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v(" Edit")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2496563092
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t(
                          "care4YouApp.carerDetail.sections.healthInsurance"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchTab(2)
                        },
                      },
                    },
                    [
                      !_vm.healthInsuranceUserTask()
                        ? _c(
                            "div",
                            { staticClass: "row justify-content-center" },
                            [
                              _c("div", { staticClass: "col-8" }, [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "h2",
                                      {
                                        staticClass: "jh-entity-heading",
                                        attrs: {
                                          "data-cy": "healthInsuranceHeading",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "care4YouApp.carerDetail.sections.healthInsurance"
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("HealthInsurance")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "dl",
                                      { staticClass: "row jh-entity-details" },
                                      [
                                        _c("dt", [
                                          _c(
                                            "span",
                                            {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.carerDetail.healthInsurance"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("HealthInsuranceUntil")]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("dd", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  _vm.carer
                                                    .healthInsuranceValidTo
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "https://www.care-concept.de/login/auslandsversicherung_login.php",
                                            target: "_blank",
                                            rel: "noopener noreferrer",
                                          },
                                        },
                                        [_vm._v("Care Concept")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v(" ")]),
                                    _vm._v(" "),
                                    !_vm.paramsForOverview &&
                                    (_vm.carer.archive == null ||
                                      _vm.carer.archive == false)
                                      ? _c("router-link", {
                                          attrs: {
                                            to: {
                                              name: "CarerHealthInsuranceUpdate",
                                              params: { carerId: _vm.carer.id },
                                            },
                                            custom: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ navigate }) {
                                                  return [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-primary",
                                                        on: { click: navigate },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: "pencil-alt",
                                                            },
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "entity.action.edit"
                                                                  )
                                                                ),
                                                            },
                                                          },
                                                          [_vm._v(" Edit")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2496563092
                                          ),
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.healthInsuranceUserTask()
                        ? _c(
                            "div",
                            { staticClass: "row justify-content-center" },
                            [
                              _c("div", { staticClass: "col-8" }, [
                                _c(
                                  "form",
                                  {
                                    attrs: {
                                      name: "editForm",
                                      role: "form",
                                      novalidate: "",
                                    },
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.save()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "h2",
                                      {
                                        attrs: {
                                          id: "care4YouApp.carerDetail.healthInsuranceEditLabel",
                                          "data-cy":
                                            "HealthInsuranceUpdateHeading",
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "care4YouApp.carerDetail.healthInsuranceEdit"
                                            )
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  edit HealthInsurance\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-control-label",
                                              attrs: {
                                                for: "carer-healthInsuranceValidTo",
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.carerDetail.healthInsurance"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("HealthInsuranceUntil")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-input-group",
                                            { staticClass: "mb-3" },
                                            [
                                              _c(
                                                "b-input-group-prepend",
                                                [
                                                  _c("b-form-datepicker", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      "aria-controls":
                                                        "carer-healthInsuranceValidTo",
                                                      name: "healthInsuranceValidTo",
                                                      locale:
                                                        _vm.currentLanguage,
                                                      "button-only": "",
                                                      "today-button": "",
                                                      "reset-button": "",
                                                      "close-button": "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v.carer
                                                          .healthInsuranceValidTo
                                                          .$model,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.$v.carer
                                                            .healthInsuranceValidTo,
                                                          "$model",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.carer.healthInsuranceValidTo.$model",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("b-form-input", {
                                                staticClass: "form-control",
                                                class: {
                                                  valid:
                                                    !_vm.$v.carer
                                                      .healthInsuranceValidTo
                                                      .$invalid,
                                                  invalid:
                                                    _vm.$v.carer
                                                      .healthInsuranceValidTo
                                                      .$invalid,
                                                },
                                                attrs: {
                                                  id: "carer-healthInsuranceValidTo",
                                                  "data-cy":
                                                    "healthInsuranceValidTo",
                                                  type: "text",
                                                  name: "healthInsuranceValidTo",
                                                  value:
                                                    _vm.convertDateFromServer(
                                                      _vm.carer
                                                        .healthInsuranceValidTo
                                                    ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.updateDateField(
                                                      $event,
                                                      _vm.carer,
                                                      "healthInsuranceValidTo"
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("div", [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "https://www.care-concept.de/login/auslandsversicherung_login.php",
                                                  target: "_blank",
                                                  rel: "noopener noreferrer",
                                                },
                                              },
                                              [_vm._v("Care Concept")]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: {
                                            type: "submit",
                                            id: "save-entity",
                                            "data-cy": "entityCreateSaveButton",
                                            disabled:
                                              _vm.healthInsuranceNotSaveable(),
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "save" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.carerDetail.enhanceHealthInsurance"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("Enhance Health Insurance")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: {
                                            type: "submit",
                                            id: "cancelHI",
                                            "data-cy":
                                              "cancelHealthInsuranceButton",
                                            disabled:
                                              _vm.healthInsuranceNotCancelable(),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.cancelHealthInsurance()
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "ban" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.carerDetail.cancelHealthInsurance"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("Cancel Health Insurance")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t(
                          "care4YouApp.carerDetail.sections.careAssignments"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchTab(3)
                        },
                      },
                    },
                    _vm._l(
                      _vm.careAssignmentYears,
                      function (careAssignmentYear) {
                        return _c(
                          "b-row",
                          {
                            key: careAssignmentYear.year,
                            attrs: { "align-v": "center" },
                            model: {
                              value: _vm.careAssignmentYears,
                              callback: function ($$v) {
                                _vm.careAssignmentYears = $$v
                              },
                              expression: "careAssignmentYears",
                            },
                          },
                          [
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _c(
                                "h2",
                                { staticClass: "mb-0 mx-auto text-center" },
                                [
                                  _vm._v(
                                    _vm._s(careAssignmentYear.year) +
                                      " - " +
                                      _vm._s(careAssignmentYear.days) +
                                      " Arbeitstage"
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.getCareAssignmentsForYear(
                                _vm.carer.careAssignments,
                                careAssignmentYear.year
                              ),
                              function (careAssignment, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: careAssignment.id,
                                    attrs: { cols: "12" },
                                    model: {
                                      value: _vm.carer.careAssignments,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.carer,
                                          "careAssignments",
                                          $$v
                                        )
                                      },
                                      expression: "carer.careAssignments",
                                    },
                                  },
                                  [
                                    _c("b-card", {
                                      staticClass: "mb-2",
                                      attrs: { "body-class": "p-0" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "b-row",
                                                  {
                                                    attrs: {
                                                      "align-v": "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass: "mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  careAssignment
                                                                    .customer
                                                                    .contractPerson
                                                                    .lastName +
                                                                    ", " +
                                                                    careAssignment
                                                                      .customer
                                                                      .contractPerson
                                                                      .firstName
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-col",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass: "mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.getDateFormatedWithDefault(
                                                                    careAssignment.arrivalAt,
                                                                    "offen"
                                                                  ) +
                                                                    " - " +
                                                                    _vm.getDateFormatedWithDefault(
                                                                      careAssignment.departureAt,
                                                                      "offen"
                                                                    )
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-col",
                                                      { attrs: { md: "auto" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "b-toggle",
                                                                rawName:
                                                                  "v-b-toggle",
                                                                value:
                                                                  "accordionAssignment-" +
                                                                  careAssignmentYear.year +
                                                                  "-" +
                                                                  index,
                                                                expression:
                                                                  "'accordionAssignment-' + careAssignmentYear.year + '-' + index",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "btn-sm",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.toggleAccordion(
                                                                  "accordionAssignment-" +
                                                                    careAssignmentYear.year +
                                                                    "-" +
                                                                    index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            !_vm.getToggleAccordion(
                                                              "accordionAssignment-" +
                                                                careAssignmentYear.year +
                                                                "-" +
                                                                index
                                                            )
                                                              ? _c(
                                                                  "font-awesome-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon: "plus",
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.getToggleAccordion(
                                                              "accordionAssignment-" +
                                                                careAssignmentYear.year +
                                                                "-" +
                                                                index
                                                            )
                                                              ? _c(
                                                                  "font-awesome-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon: "minus",
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        !_vm.paramsForOverview &&
                                                        (_vm.carer.archive ==
                                                          null ||
                                                          _vm.carer.archive ==
                                                            false)
                                                          ? _c("router-link", {
                                                              attrs: {
                                                                to: {
                                                                  name: "CarerEditCareAssignment",
                                                                  params: {
                                                                    carerId:
                                                                      _vm.carer
                                                                        .id,
                                                                    careAssignmentId:
                                                                      careAssignment.id,
                                                                  },
                                                                },
                                                                custom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "default",
                                                                      fn: function ({
                                                                        navigate,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "b-button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-sm edit",
                                                                              attrs:
                                                                                {
                                                                                  variant:
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  navigate,
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "font-awesome-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "pencil-alt",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "default",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "b-collapse",
                                                  {
                                                    staticClass: "p-3",
                                                    attrs: {
                                                      id:
                                                        "accordionAssignment-" +
                                                        careAssignmentYear.year +
                                                        "-" +
                                                        index,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group col-6",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-control-label",
                                                                attrs: {
                                                                  for:
                                                                    index +
                                                                    "-care-assignment-arrivalAt",
                                                                },
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "care4YouApp.careAssignment.arrivalAt"
                                                                      )
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Arrival At\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-input-group",
                                                                  {
                                                                    staticClass:
                                                                      "mb-3",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-input-group-prepend",
                                                                      [
                                                                        _c(
                                                                          "b-form-datepicker",
                                                                          {
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs:
                                                                              {
                                                                                locale:
                                                                                  _vm.currentLanguage,
                                                                                name:
                                                                                  index +
                                                                                  "-care-assignment-arrivalAt",
                                                                                "aria-controls":
                                                                                  "care-assignment-arrivalAt",
                                                                                "button-only":
                                                                                  "",
                                                                                "close-button":
                                                                                  "",
                                                                                disabled:
                                                                                  "",
                                                                                "reset-button":
                                                                                  "",
                                                                                "today-button":
                                                                                  "",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  careAssignment.arrivalAt,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      careAssignment,
                                                                                      "arrivalAt",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "careAssignment.arrivalAt",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          id:
                                                                            index +
                                                                            "-care-assignment-arrivalAt",
                                                                          value:
                                                                            _vm.convertDateFromServer(
                                                                              careAssignment.arrivalAt
                                                                            ),
                                                                          name:
                                                                            index +
                                                                            "-arrivalAt",
                                                                          disabled:
                                                                            "",
                                                                          type: "text",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.updateDateField(
                                                                                $event,
                                                                                careAssignment,
                                                                                "arrivalAt"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group col-6",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-control-label",
                                                                attrs: {
                                                                  for:
                                                                    index +
                                                                    "-care-assignment-departureAt",
                                                                },
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "care4YouApp.careAssignment.departureAt"
                                                                      )
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Departure At\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-input-group",
                                                                  {
                                                                    staticClass:
                                                                      "mb-3",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-input-group-prepend",
                                                                      [
                                                                        _c(
                                                                          "b-form-datepicker",
                                                                          {
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs:
                                                                              {
                                                                                locale:
                                                                                  _vm.currentLanguage,
                                                                                name:
                                                                                  index +
                                                                                  "-care-assignment-departureAt",
                                                                                "aria-controls":
                                                                                  "care-assignment-departureAt",
                                                                                "button-only":
                                                                                  "",
                                                                                "close-button":
                                                                                  "",
                                                                                disabled:
                                                                                  "",
                                                                                "reset-button":
                                                                                  "",
                                                                                "today-button":
                                                                                  "",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  careAssignment.departureAt,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      careAssignment,
                                                                                      "departureAt",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "careAssignment.departureAt",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          id:
                                                                            index +
                                                                            "-care-assignment-departureAt",
                                                                          value:
                                                                            _vm.convertDateFromServer(
                                                                              careAssignment.departureAt
                                                                            ),
                                                                          name:
                                                                            index +
                                                                            "-departureAt",
                                                                          disabled:
                                                                            "",
                                                                          type: "text",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.updateDateField(
                                                                                $event,
                                                                                careAssignment,
                                                                                "departureAt"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group col-6",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-control-label",
                                                                attrs: {
                                                                  for:
                                                                    index +
                                                                    "-care-assignment-estimatedArrivalAt",
                                                                },
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "care4YouApp.careAssignment.estimatedArrivalAt"
                                                                      )
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Arrival At\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-input-group",
                                                                  {
                                                                    staticClass:
                                                                      "mb-3",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-input-group-prepend",
                                                                      [
                                                                        _c(
                                                                          "b-form-datepicker",
                                                                          {
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs:
                                                                              {
                                                                                locale:
                                                                                  _vm.currentLanguage,
                                                                                name:
                                                                                  index +
                                                                                  "-care-assignment-estimatedArrivalAt",
                                                                                "aria-controls":
                                                                                  "care-assignment-estimatedArrivalAt",
                                                                                "button-only":
                                                                                  "",
                                                                                "close-button":
                                                                                  "",
                                                                                disabled:
                                                                                  "",
                                                                                "reset-button":
                                                                                  "",
                                                                                "today-button":
                                                                                  "",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  careAssignment.estimatedArrivalAt,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      careAssignment,
                                                                                      "estimatedArrivalAt",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "careAssignment.estimatedArrivalAt",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          id:
                                                                            index +
                                                                            "-care-assignment-estimatedArrivalAt",
                                                                          value:
                                                                            _vm.convertDateFromServer(
                                                                              careAssignment.estimatedArrivalAt
                                                                            ),
                                                                          name:
                                                                            index +
                                                                            "-estimatedArrivalAt",
                                                                          disabled:
                                                                            "",
                                                                          type: "text",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.updateDateField(
                                                                                $event,
                                                                                careAssignment,
                                                                                "estimatedArrivalAt"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group col-6",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-control-label",
                                                                attrs: {
                                                                  for: "care-assignment-workingDays",
                                                                },
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "care4YouApp.careAssignment.workingDays"
                                                                      )
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Working Days\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model.number",
                                                                  value:
                                                                    careAssignment.workingDays,
                                                                  expression:
                                                                    "careAssignment.workingDays",
                                                                  modifiers: {
                                                                    number: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "number",
                                                                name:
                                                                  index +
                                                                  "-workingDays",
                                                                id:
                                                                  index +
                                                                  "-care-assignment-workingDays",
                                                                readonly: "",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  careAssignment.workingDays,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      careAssignment,
                                                                      "workingDays",
                                                                      _vm._n(
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    )
                                                                  },
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$forceUpdate()
                                                                },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group col-6",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-control-label",
                                                                attrs: {
                                                                  for:
                                                                    index +
                                                                    "-arrivalData-assignment-data-protocol",
                                                                },
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "care4YouApp.careAssignment.arrivalProtocol"
                                                                      )
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Protocol\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("textarea", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    careAssignment.arrivalProtocol,
                                                                  expression:
                                                                    "careAssignment.arrivalProtocol",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                id:
                                                                  index +
                                                                  "-arrivalData-assignment-data-protocol",
                                                                name:
                                                                  index +
                                                                  "-arrivalData-protocol",
                                                                readonly: "",
                                                                rows: "5",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  careAssignment.arrivalProtocol,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      careAssignment,
                                                                      "arrivalProtocol",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group col-6",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-control-label",
                                                                attrs: {
                                                                  for:
                                                                    index +
                                                                    "-departureData-assignment-data-protocol",
                                                                },
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "care4YouApp.careAssignment.departureProtocol"
                                                                      )
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Protocol\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("textarea", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    careAssignment.departureProtocol,
                                                                  expression:
                                                                    "careAssignment.departureProtocol",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                id:
                                                                  index +
                                                                  "-departureData-assignment-data-protocol",
                                                                name:
                                                                  index +
                                                                  "-departureData-protocol",
                                                                readonly: "",
                                                                rows: "5",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  careAssignment.departureProtocol,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      careAssignment,
                                                                      "departureProtocol",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      }
                    ),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t(
                          "care4YouApp.carerDetail.sections.suggestion"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchTab(4)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "form-group col-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: {
                                for: "suggestionForPrinting-customer-agency",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("care4YouApp.customer.agency")
                                ),
                              },
                            },
                            [_vm._v("\n                Agency\n              ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.suggestionForPrinting.customer.agency,
                                  expression:
                                    "suggestionForPrinting.customer.agency",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "suggestionForPrinting-customer-agency",
                                name: "suggestionForPrinting-customer-agency",
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.suggestionForPrinting.customer,
                                    "agency",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { domProps: { value: null } }),
                              _vm._v(" "),
                              _vm._l(_vm.agencies, function (agencyOption) {
                                return _c(
                                  "option",
                                  {
                                    key: agencyOption.id,
                                    domProps: {
                                      value:
                                        _vm.suggestionForPrinting.customer
                                          .agency &&
                                        agencyOption.id ===
                                          _vm.suggestionForPrinting.customer
                                            .agency.id
                                          ? _vm.suggestionForPrinting.customer
                                              .agency
                                          : agencyOption,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(agencyOption.name) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "form-group col-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: {
                                for: "suggestionForPrinting-carer-workExperience",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("care4YouApp.carer.workExperience")
                                ),
                              },
                            },
                            [_vm._v("\n                Agency\n              ")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.suggestionForPrinting.carer
                                    .workExperience,
                                expression:
                                  "suggestionForPrinting.carer.workExperience",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "suggestionForPrinting-carer-workExperience",
                              name: "suggestionForPrinting-carer-workExperience",
                              type: "text",
                            },
                            domProps: {
                              value:
                                _vm.suggestionForPrinting.carer.workExperience,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.suggestionForPrinting.carer,
                                  "workExperience",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: {
                                for: "suggestionForPrinting-carer-languageSkill",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "care4YouApp.carerSuggestion.knowledgeOfGerman"
                                  )
                                ),
                              },
                            },
                            [_vm._v("\n                Agency\n              ")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.languageSkillForPrinting,
                                expression: "languageSkillForPrinting",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "suggestionForPrinting-carer-languageSkill",
                              name: "suggestionForPrinting-carer-languageSkill",
                              type: "text",
                            },
                            domProps: { value: _vm.languageSkillForPrinting },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.languageSkillForPrinting =
                                  $event.target.value
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: {
                                for: "suggestionForPrinting-carer-character",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "care4YouApp.carerSuggestion.character"
                                  )
                                ),
                              },
                            },
                            [_vm._v("\n                Agency\n              ")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.characterForPrinting,
                                expression: "characterForPrinting",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "suggestionForPrinting-carer-character",
                              name: "suggestionForPrinting-carer-character",
                              type: "text",
                            },
                            domProps: { value: _vm.characterForPrinting },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.characterForPrinting = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "form-group col-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: {
                                for: "suggestionForPrinting-desiredSalary",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "care4YouApp.carerSuggestion.desiredSalary"
                                  )
                                ),
                              },
                            },
                            [_vm._v("\n                Agency\n              ")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.suggestionForPrinting.desiredSalary,
                                expression:
                                  "suggestionForPrinting.desiredSalary",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "suggestionForPrinting-desiredSalary",
                              name: "suggestionForPrinting-desiredSalary",
                              type: "number",
                            },
                            domProps: {
                              value: _vm.suggestionForPrinting.desiredSalary,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.suggestionForPrinting,
                                  "desiredSalary",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: {
                                for: "suggestionForPrinting-travelCosts",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "care4YouApp.carerSuggestion.travelCosts"
                                  )
                                ),
                              },
                            },
                            [_vm._v("\n                Agency\n              ")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.suggestionForPrinting.travelCosts,
                                expression: "suggestionForPrinting.travelCosts",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "suggestionForPrinting-travelCosts",
                              name: "suggestionForPrinting-travelCosts",
                              type: "number",
                            },
                            domProps: {
                              value: _vm.suggestionForPrinting.travelCosts,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.suggestionForPrinting,
                                  "travelCosts",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: {
                                for: "suggestionForPrinting-totalCosts",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "care4YouApp.carerSuggestion.totalCosts"
                                  )
                                ),
                              },
                            },
                            [_vm._v("\n                Agency\n              ")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.suggestionForPrinting.totalCosts,
                                expression: "suggestionForPrinting.totalCosts",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "suggestionForPrinting-totalCosts",
                              name: "suggestionForPrinting-totalCosts",
                              type: "number",
                            },
                            domProps: {
                              value: _vm.suggestionForPrinting.totalCosts,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.suggestionForPrinting,
                                  "totalCosts",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group col-3" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-control-label",
                                attrs: {
                                  for: "suggestionForPrinting-arrivalFrom",
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "care4YouApp.carerSuggestion.arrivalFrom"
                                    )
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  "\n                Arrival From\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-form-datepicker", {
                                      staticClass: "form-control",
                                      attrs: {
                                        locale: _vm.currentLanguage,
                                        "aria-controls":
                                          "suggestionForPrinting-arrivalFrom",
                                        "button-only": "",
                                        "close-button": "",
                                        name: "suggestionForPrinting-arrivalFrom",
                                        "reset-button": "",
                                        "today-button": "",
                                      },
                                      model: {
                                        value:
                                          _vm.suggestionForPrinting.arrivalFrom,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.suggestionForPrinting,
                                            "arrivalFrom",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "suggestionForPrinting.arrivalFrom",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "suggestionForPrinting-arrivalFrom",
                                    value: _vm.convertDateFromServer(
                                      _vm.suggestionForPrinting.arrivalFrom
                                    ),
                                    name: "suggestionForPrinting-arrivalFrom",
                                    type: "text",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateDateField(
                                        $event,
                                        _vm.suggestionForPrinting,
                                        "arrivalFrom"
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: {
                                for: "suggestionForPrinting-assignmentDuration",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "care4YouApp.carerSuggestion.assignmentDuration"
                                  )
                                ),
                              },
                            },
                            [_vm._v("\n                Agency\n              ")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.suggestionForPrinting.assignmentDuration,
                                expression:
                                  "suggestionForPrinting.assignmentDuration",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "suggestionForPrinting-assignmentDuration",
                              name: "suggestionForPrinting-assignmentDuration",
                              type: "text",
                            },
                            domProps: {
                              value:
                                _vm.suggestionForPrinting.assignmentDuration,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.suggestionForPrinting,
                                  "assignmentDuration",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: { for: "suggestionForPrinting-notes" },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("care4YouApp.carerSuggestion.notes")
                                ),
                              },
                            },
                            [_vm._v("\n                Agency\n              ")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.suggestionForPrinting.notes,
                                expression: "suggestionForPrinting.notes",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "suggestionForPrinting-notes",
                              name: "suggestionForPrinting-notes",
                              type: "text",
                            },
                            domProps: {
                              value: _vm.suggestionForPrinting.notes,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.suggestionForPrinting,
                                  "notes",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group col-3" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-sm btn-info",
                                attrs: { variant: "Success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.generatePdf(
                                      _vm.suggestionForPrinting
                                    )
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "file-pdf" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t(
                          "care4YouApp.carerDetail.sections.documents"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchTab(5)
                        },
                      },
                    },
                    [
                      _vm.documents.length == 0
                        ? _c("h4", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "care4YouApp.carerDetail.notExisting.documents"
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        _vm._l(_vm.documents, function (document, index) {
                          return _c(
                            "b-col",
                            { key: index, attrs: { md: "4" } },
                            [
                              _c("b-card", {
                                staticClass: "mb-2",
                                attrs: { tag: "article" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function () {
                                        return [
                                          _c(
                                            "h4",
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    document.documentName
                                                  ) +
                                                  "\n                    "
                                              ),
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: _vm.getIconForDocType(
                                                    document.documentBinariesContentType
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      document.documentBinariesContentType,
                                                    expression:
                                                      "document.documentBinariesContentType",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  id:
                                                    "document-documentBinariesContentType" +
                                                    index,
                                                  name:
                                                    "documentBinariesContentType" +
                                                    index,
                                                  type: "hidden",
                                                },
                                                domProps: {
                                                  value:
                                                    document.documentBinariesContentType,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      document,
                                                      "documentBinariesContentType",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "form-control-label",
                                                  attrs: {
                                                    for: "document-documentType",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "care4YouApp.document.documentType"
                                                      )
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Document Type\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        document.documentType,
                                                      expression:
                                                        "document.documentType",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    id: "document-documentType",
                                                    disabled:
                                                      _vm.carer.archive == true,
                                                    name: "documentType",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        document,
                                                        "documentType",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.documentTypeValues,
                                                  function (documentType) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: documentType,
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "care4YouApp.DocumentType." +
                                                              documentType
                                                          ),
                                                        },
                                                        domProps: {
                                                          value: documentType,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              documentType
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "form-control-label",
                                                  attrs: {
                                                    for: "document-documentStatus",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "care4YouApp.document.documentStatus"
                                                      )
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Document Status\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        document.documentStatus,
                                                      expression:
                                                        "document.documentStatus",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    id: "document-documentStatus",
                                                    name: "documentStatus",
                                                    disabled:
                                                      _vm.carer.archive == true,
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          document,
                                                          "documentStatus",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.setChangedAt(
                                                          document
                                                        )
                                                      },
                                                    ],
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.documentStatusValues,
                                                  function (documentStatus) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: documentStatus,
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "care4YouApp.DocumentStatus." +
                                                              documentStatus
                                                          ),
                                                        },
                                                        domProps: {
                                                          value: documentStatus,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              documentStatus
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "form-control-label",
                                                  attrs: {
                                                    for:
                                                      "document_file" + index,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "care4YouApp.document.statusChangedAt"
                                                      )
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Status Changed At\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              document.statusChangedAt
                                                ? _c("p", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$d(
                                                          Date.parse(
                                                            document.statusChangedAt
                                                          ),
                                                          "short"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !document.statusChangedAt
                                                ? _c("p", [_vm._v("-")])
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "form-control-label",
                                                  attrs: {
                                                    for:
                                                      "document_file" + index,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "care4YouApp.document.createdAt"
                                                      )
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Created At\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              document.createdAt
                                                ? _c("p", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$d(
                                                          Date.parse(
                                                            document.createdAt
                                                          ),
                                                          "short"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !document.createdAt
                                                ? _c("p", [_vm._v("-")])
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-button",
                                            {
                                              attrs: { variant: "info" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.downloadFile(
                                                    document.documentBinariesContentType,
                                                    document.documentBinaries,
                                                    document.documentName
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "download" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "entity.action.download"
                                                      )
                                                    ),
                                                  },
                                                },
                                                [_vm._v("Download")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-button",
                                            {
                                              attrs: { variant: "info" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openFile(
                                                    document.documentBinariesContentType,
                                                    document.documentBinaries
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "eye" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "entity.action.open"
                                                      )
                                                    ),
                                                  },
                                                },
                                                [_vm._v("Download")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.carer.archive == null ||
                                          _vm.carer.archive == false
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: { variant: "save" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.safeFile(
                                                        document
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: { icon: "save" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "entity.action.save"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [_vm._v("Save")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.carer.archive == null ||
                                          _vm.carer.archive == false
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: { variant: "delete" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteFile(
                                                        document
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: { icon: "trash" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "entity.action.delete"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [_vm._v("Delete")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      !_vm.paramsForOverview &&
                      (_vm.carer.archive == null || _vm.carer.archive == false)
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("b-card", {
                                    staticClass: "mb-2",
                                    attrs: { tag: "article" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function () {
                                            return [
                                              _c(
                                                "h4",
                                                [
                                                  _vm._v(
                                                    "\n                    Neues Dokument\n                    "
                                                  ),
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: _vm.getIconForDocType(
                                                        _vm.newDocument
                                                          .documentBinariesContentType
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "form-control-label",
                                                      attrs: {
                                                        for: "document_file_new",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "global.menu.entities.document"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [_vm._v(" File ")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    ref: "document_file_new",
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "document_file_new",
                                                      accept: _vm.getAccept(
                                                        _vm.newDocument
                                                      ),
                                                      type: "file",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.setFileData(
                                                          $event,
                                                          _vm.newDocument,
                                                          "documentBinaries",
                                                          false
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.newDocument
                                                            .documentBinaries,
                                                        expression:
                                                          "newDocument.documentBinaries",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "document-documentBinaries_new",
                                                      name: "binaries_new",
                                                      type: "hidden",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.newDocument
                                                          .documentBinaries,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.newDocument,
                                                          "documentBinaries",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.newDocument
                                                            .documentBinariesContentType,
                                                        expression:
                                                          "newDocument.documentBinariesContentType",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "document-documentBinariesContentType_new",
                                                      name: "documentBinariesContentType_new",
                                                      type: "hidden",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.newDocument
                                                          .documentBinariesContentType,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.newDocument,
                                                          "documentBinariesContentType",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "form-control-label",
                                                      attrs: {
                                                        for: "document-documentName_new",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "care4YouApp.document.documentName"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Document Name\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.newDocument
                                                            .documentName,
                                                        expression:
                                                          "newDocument.documentName",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "document-documentName_new",
                                                      name: "name_new",
                                                      type: "text",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.newDocument
                                                          .documentName,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.newDocument,
                                                          "documentName",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "form-control-label",
                                                      attrs: {
                                                        for: "document_new-documentType",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "care4YouApp.document.documentType"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Document Type\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.newDocument
                                                              .documentType,
                                                          expression:
                                                            "newDocument.documentType",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "document_new-documentType",
                                                        name: "documentType",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            _vm.newDocument,
                                                            "documentType",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.documentTypeValues,
                                                      function (documentType) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: documentType,
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "care4YouApp.DocumentType." +
                                                                  documentType
                                                              ),
                                                            },
                                                            domProps: {
                                                              value:
                                                                documentType,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  documentType
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "form-control-label",
                                                      attrs: {
                                                        for: "document_new-documentStatus",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "care4YouApp.document.documentStatus"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Document Status\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.newDocument
                                                              .documentStatus,
                                                          expression:
                                                            "newDocument.documentStatus",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "document_new-documentStatus",
                                                        name: "documentStatus",
                                                        disabled:
                                                          _vm.carer.archive ==
                                                          true,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            _vm.newDocument,
                                                            "documentStatus",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.documentStatusValues,
                                                      function (
                                                        documentStatus
                                                      ) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: documentStatus,
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "care4YouApp.DocumentStatus." +
                                                                  documentStatus
                                                              ),
                                                            },
                                                            domProps: {
                                                              value:
                                                                documentStatus,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  documentStatus
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      !_vm.validDataForNewDoc(),
                                                    variant: "info",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addNewFile()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: { icon: "plus" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "entity.action.add"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [_vm._v("Add")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1674684286
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-tab", {
                    attrs: {
                      title: _vm.$t("care4YouApp.carerDetail.sections.mails"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.switchTab(6)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.paramsForOverview
                ? _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: {
                          id: "cancel-save",
                          "data-cy": "entityCreateCancelButton",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.previousState()
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "ban" } }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(_vm.$t("entity.action.back")),
                            },
                          },
                          [_vm._v("Back")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.carer.archive != null && _vm.carer.archive == true
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: {
                              id: "reactivate",
                              "data-cy": "entityCreateCancelButton",
                              type: "button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.reactivateCarer()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", { attrs: { icon: "ban" } }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.action.reactivate")
                                  ),
                                },
                              },
                              [_vm._v("Reactivate")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.carer.archive == null || _vm.carer.archive == false
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: {
                              id: "reactivate",
                              "data-cy": "entityCreateCancelButton",
                              type: "button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.archiveCarer()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "archive" },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.action.archive")
                                  ),
                                },
                              },
                              [_vm._v("Archive")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }