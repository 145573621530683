import { Component, Inject, Provide, Vue } from 'vue-property-decorator';
import AlertService from '@/shared/alert/alert.service';
import { IActivity } from '@/shared/model/activity.model';
import ActivityService from '@/entities/activity/activity.service';
import { ActivityType } from '@/shared/model/enumerations/activity-type.model';
import dayjs from 'dayjs';
import { ZONED_DATE_TIME_SERVER_FORMAT } from '@/shared/date/filters';

/**
 * An utility service.
 */
@Component
export default class ActivityUtils extends Vue {
  public editActivity: IActivity = null;
  public activities: IActivity[] = [];
  public selectedActivity = null;
  public activityItemsPerPage = 20;
  public activityQueryCount: number = null;
  public activityPage = 1;
  public activityPreviousPage = 1;
  public activityPropOrder = 'createdAt';
  public activityReverse = true;
  public activityTotalItems = 0;
  public isLoadingActivities = false;
  public showEditActivity = false;
  public activityTypeOptions: string[] = Object.keys(ActivityType);

  @Inject('alertService') protected alertService: () => AlertService;
  protected editActivityIndex = -1;

  public prepareEditActivity(instance: IActivity, index: number): void {
    this.editActivityIndex = index;
    //create copy to not modify reference in table
    this.editActivity = JSON.parse(JSON.stringify(instance)) as typeof instance;
    this.showEditActivity = true;
  }

  public confirmActivityRemoval(instance: IActivity, index: number, cb: (data: IActivity, index: number) => any): void {
    this.$bvModal
      .msgBoxConfirm(this.$t('care4YouApp.activity.delete.question', { id: instance.id }).toString(), {
        title: this.$t('entity.delete.title').toString(),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('entity.delete.yes').toString(),
        cancelTitle: this.$t('entity.delete.no').toString(),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if (value === true) {
          this.activityService()
            .delete(instance.id)
            .then(
              res => {
                if (cb) {
                  cb(instance, index);
                }
                const message = this.$t('care4YouApp.activity.deleted', { param: instance.id });
                (this.$root as any).$bvToast.toast(message.toString(), {
                  toaster: 'b-toaster-top-center',
                  title: 'Info',
                  variant: 'info',
                  solid: true,
                  autoHideDelay: 5000,
                });
              },
              err => {
                this.alertService().showHttpError(this, err.response);
              }
            );
        }
      })
      .catch(err => {
        // An error occurred
      });
  }

  public showModalForNewActivity(newActivity): void {
    this.editActivityIndex = -1;
    this.editActivity = newActivity;
    this.showEditActivity = true;
  }

  public replaceWhiteSpace(text): string {
    return text.replace('\\n', '\n');
  }

  public updateActivity(instance: IActivity, cb: (data: IActivity) => any): void {
    this.activityService()
      .update(instance)
      .then(
        res => {
          if (cb) {
            cb(res);
          }
          const message = this.$t('care4YouApp.activity.updated', { param: res.id });
          (this.$root as any).$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'info',
            solid: true,
            autoHideDelay: 5000,
          });
        },
        err => {
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public saveEditActivity(): void {
    const currDateTime = dayjs(new Date()).format(ZONED_DATE_TIME_SERVER_FORMAT);
    // @ts-ignore
    //this.editActivity.updatedAt = currDateTime;
    if (this.editActivity.id) {
      //update
      this.activityService()
        .update(this.editActivity)
        .then(
          res => {
            const message = this.$t('care4YouApp.activity.updated', { param: this.editActivity.id });
            (this.$root as any).$bvToast.toast(message.toString(), {
              toaster: 'b-toaster-top-center',
              title: 'Info',
              variant: 'info',
              solid: true,
              autoHideDelay: 5000,
            });
            this.resetEditActivity(res);
            this.closeEditActivityDialog();
          },
          err => {
            this.alertService().showHttpError(this, err.response);
          }
        );
    } else {
      //create
      // @ts-ignore
      //this.editActivity.createdAt = currDateTime;
      this.activityService()
        .create(this.editActivity)
        .then(
          res => {
            this.resetEditActivity(res);
            this.closeEditActivityDialog();
            this.loadActivities();
          },
          err => {
            this.alertService().showHttpError(this, err.response);
          }
        );
    }
  }

  public loadActivities() {}

  public sortActivity(): Array<any> {
    const result = [this.activityPropOrder + ',' + (this.activityReverse ? 'desc' : 'asc')];
    if (this.activityPropOrder !== 'id') {
      result.push('id');
    }
    return result;
  }

  public changeActivityOrder(propOrder): void {
    this.activityPropOrder = propOrder;
    this.activityReverse = !this.activityReverse;
    this.loadActivities();
  }

  public handleSyncList(): void {
    this.activityPage = 1;
    this.loadActivities();
  }

  public loadActivityPage(page: number): void {
    if (page !== this.activityPreviousPage) {
      this.activityPreviousPage = page;
      this.loadActivities();
    }
  }

  public closeEditActivityDialog(): void {
    this.editActivity = null;
    //this.showEditActivity = true;
  }

  public getPropertyOfObject(obj, propName): string {
    if (obj.hasOwnProperty(propName)) {
      return obj[propName];
    }
    return '';
  }

  @Provide('activityService') public activityService = () => new ActivityService();

  private resetEditActivity(updatedActivity) {
    if (this.editActivityIndex >= 0) {
      this.activities[this.editActivityIndex] = updatedActivity;
    } else {
      this.activities.push(updatedActivity);
    }
    this.editActivity = null;
    this.editActivityIndex = -1;
  }

  public getActivityTypeIcon(activityType) {
    switch (activityType) {
      case 'ARRIVAL':
        return 'arrow-right';
      case 'CALL':
        return 'phone';
      case 'DEPARTURE':
        return 'arrow-left';
      case 'MEETING':
        return 'calendar';
      case 'NOTE':
        return 'note-sticky';
      case 'NEW_CUSTOMER_PROCESS_PROTOCOL':
        return 'star';
    }

    return null;
  }
}
