import { Authority } from '@/shared/security/authority';

const CarerOverview = () => import('@/carer-overview/carer-overview.vue');
const CarerDetail = () => import('@/carer-detail/carer-detail.vue');
const CarerUpdate = () => import('@/carer-detail/carer-update.vue');
const CarerContactUpdate = () => import('@/carer-detail/carer-contact-update.vue');
const CarerHealthInsuranceUpdate = () => import('@/carer-detail/carer-healthInsurance-update.vue');
const CarerCareAssignmentUpdateComponent = () => import('@/carer-detail/carer-care-assignment-update.vue');

// prettier-ignore
export default [
  {
    path: '/carer-overview',
    name: 'CarerOverview',
    component: CarerOverview,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/carer-detail/:carerId/:tabIndex',
    name: 'CarerDetail',
    component: CarerDetail,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/carer-detail/',
    name: 'CreateCarer',
    component: CarerUpdate,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/carer-detail/:carerId/edit',
    name: 'CarerUpdate',
    component: CarerUpdate,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/carer-detail/:carerId/editContact',
    name: 'CarerContactUpdate',
    component: CarerContactUpdate,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/carer-detail/:carerId/editHealthInsurance',
    name: 'CarerHealthInsuranceUpdate',
    component: CarerHealthInsuranceUpdate,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/carer-detail/:carerId/editCareAssignment/:careAssignmentId',
    name: 'CarerEditCareAssignment',
    component: CarerCareAssignmentUpdateComponent,
    meta: { authorities: [Authority.USER] }
  }
];
