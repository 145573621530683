import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { ICommunication } from '@/shared/model/communication.model';
import { CommunicationType } from '@/shared/model/enumerations/communication-type.model';
import { FrageAlter } from '@/shared/model/enumerations/frage-alter.model';
import { FrageAusscheidungshaeufigkeit } from '@/shared/model/enumerations/frage-ausscheidungshaeufigkeit.model';
import { FrageAusscheidungshilfsmittel } from '@/shared/model/enumerations/frage-ausscheidungshilfsmittel.model';
import { FrageDiagnostizierteKrankheit } from '@/shared/model/enumerations/frage-diagnostizierte-krankheit.model';
import { FrageEingeschraenkt } from '@/shared/model/enumerations/frage-eingeschraenkt.model';
import { FrageEinnahme } from '@/shared/model/enumerations/frage-einnahme.model';
import { FrageFuehrerschein } from '@/shared/model/enumerations/frage-fuehrerschein.model';
import { FrageGeschlecht } from '@/shared/model/enumerations/frage-geschlecht.model';
import { FrageGetriebe } from '@/shared/model/enumerations/frage-getriebe.model';
import { FrageHaeufigkeitAufwachen } from '@/shared/model/enumerations/frage-haeufigkeit-aufwachen.model';
import { FrageHaeufigkeitNachteinsaetze } from '@/shared/model/enumerations/frage-haeufigkeit-nachteinsaetze.model';
import { FrageKognitiveProbleme } from '@/shared/model/enumerations/frage-kognitive-probleme.model';
import { FrageKognitivesHilfsmittel } from '@/shared/model/enumerations/frage-kognitives-hilfsmittel.model';
import { FrageMobilitaetshilfe } from '@/shared/model/enumerations/frage-mobilitaetshilfe.model';
import { FrageRauchen } from '@/shared/model/enumerations/frage-rauchen.model';
import { FrageSelbstaendigkeit } from '@/shared/model/enumerations/frage-selbstaendigkeit.model';
import { FrageSprachkenntnisse } from '@/shared/model/enumerations/frage-sprachkenntnisse.model';
import { FrageTherapien } from '@/shared/model/enumerations/frage-therapien.model';
import { FrageTrinkmenge } from '@/shared/model/enumerations/frage-trinkmenge.model';
import { FragePflegegrad } from '@/shared/model/enumerations/frage-pflegegrad.model';

/**
 * An utility service.
 */
@Component
export default class CareUtils extends Vue {
  public QUESTION_WUNSCHDATUM1 = 'WUNSCHDATUM1';
  public QUESTION_WUNSCHDATUM2 = 'WUNSCHDATUM2';
  public QUESTION_ANSPRECHPARTNER = 'ANSPRECHPARTNER';
  public QUESTION_ANSPRECHPARTNER_NAME = 'ANSPRECHPARTNER_NAME';
  public QUESTION_ANSPRECHPARTNER_VORNAME = 'ANSPRECHPARTNER_VORNAME';
  public QUESTION_ANSPRECHPARTNER_STRASSE = 'ANSPRECHPARTNER_STRASSE';
  public QUESTION_ANSPRECHPARTNER_HAUSNUMMER = 'ANSPRECHPARTNER_HAUSNUMMER';
  public QUESTION_ANSPRECHPARTNER_PLZ = 'ANSPRECHPARTNER_PLZ';
  public QUESTION_ANSPRECHPARTNER_ORT = 'ANSPRECHPARTNER_ORT';
  public QUESTION_ANSPRECHPARTNER_TELEFON = 'ANSPRECHPARTNER_TELEFON';
  public QUESTION_ANSPRECHPARTNER_MAIL = 'ANSPRECHPARTNER_MAIL';
  public QUESTION_PATIENT_NAME = 'PATIENT_NAME';
  public QUESTION_PATIENT_VORNAME = 'PATIENT_VORNAME';
  public QUESTION_PATIENT_STRASSE = 'PATIENT_STRASSE';
  public QUESTION_PATIENT_HAUSNUMMER = 'PATIENT_HAUSNUMMER';
  public QUESTION_PATIENT_PLZ = 'PATIENT_PLZ';
  public QUESTION_PATIENT_ORT = 'PATIENT_ORT';
  public QUESTION_PATIENT_GEBURTSDATUM = 'PATIENT_GEBURTSDATUM';
  public QUESTION_PATIENT_GROESSE = 'PATIENT_GROESSE';
  public QUESTION_PATIENT_GEWICHT = 'PATIENT_GEWICHT';
  public QUESTION_PATIENT_KRANKENKASSE = 'PATIENT_KRANKENKASSE';
  public QUESTION_PFLEGEGRAD = 'PFLEGEGRAD';
  public QUESTION_DIAGNOSE_ALTERSSCHWAECHE = 'DIAGNOSE_ALTERSSCHWAECHE';
  public QUESTION_DIAGNOSE_DEMENZ = 'DIAGNOSE_DEMENZ';
  public QUESTION_DIAGNOSE_KREBS = 'DIAGNOSE_KREBS';
  public QUESTION_DIAGNOSE_SCHLAGANFALL = 'DIAGNOSE_SCHLAGANFALL';
  public QUESTION_DIAGNOSE_EINSAMKEIT = 'DIAGNOSE_EINSAMKEIT';
  public QUESTION_DIAGNOSE_ANGSTZUSTAENDE = 'DIAGNOSE_ANGSTZUSTAENDE';
  public QUESTION_HILFSMITTEL_ROLLSTUHL = 'HILFSMITTEL_ROLLSTUHL';
  public QUESTION_HILFSMITTEL_ROLLATOR = 'HILFSMITTEL_ROLLATOR';
  public QUESTION_HILFSMITTEL_TREPPENLIFT = 'HILFSMITTEL_TREPPENLIFT';
  public QUESTION_HILFSMITTEL_PATIENTENLIFTER = 'HILFSMITTEL_PATIENTENLIFTER';
  public QUESTION_HILFSMITTEL_PFLEGEBETT = 'HILFSMITTEL_PFLEGEBETT';
  public QUESTION_ZUSTAND_BETTLAEGERIG = 'ZUSTAND_BETTLAEGERIG';
  public QUESTION_ZUSTAND_HILFESTEHEN = 'ZUSTAND_HILFESTEHEN';
  public QUESTION_ZUSTAND_HILFEGEHEN = 'ZUSTAND_HILFEGEHEN';
  public QUESTION_ZUSTAND_EINGECHRAENKTSEHEN = 'ZUSTAND_EINGECHRAENKTSEHEN';
  public QUESTION_ZUSTAND_EINGECHRAENKTHOEREN = 'ZUSTAND_EINGECHRAENKTHOEREN';
  public QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE = 'PFLEGETAETIGKEITEN_KOERPERWAESCHE';
  public QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG = 'PFLEGETAETIGKEITEN_TOILETTENGANG';
  public QUESTION_PFLEGETAETIGKEITEN_KLEIDEN = 'PFLEGETAETIGKEITEN_KLEIDEN';
  public QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE = 'PFLEGETAETIGKEITEN_INTIMPFLEGE';
  public QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG = 'PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG';
  public QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG = 'PFLEGETAETIGKEITEN_HILFENAHRUNG';
  public QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN = 'PFLEGETAETIGKEITEN_SPAZIEREN';
  public QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT = 'PFLEGETAETIGKEITEN_HANDARBEIT';
  public QUESTION_PFLEGETAETIGKEITEN_SPIELEN = 'PFLEGETAETIGKEITEN_SPIELEN';
  public QUESTION_PFLEGETAETIGKEITEN_SONSTIGES = 'PFLEGETAETIGKEITEN_SONSTIGES';
  public QUESTION_HAUSHALT_KOCHEN = 'HAUSHALT_KOCHEN';
  public QUESTION_HAUSHALT_WASCHEN = 'HAUSHALT_WASCHEN';
  public QUESTION_HAUSHALT_EINKAUFEN = 'HAUSHALT_EINKAUFEN';
  public QUESTION_HAUSHALT_REINIGEN = 'HAUSHALT_REINIGEN';
  public QUESTION_HAUSHALT_HAUSTIERE = 'HAUSHALT_HAUSTIERE';
  public QUESTION_HAUSHALT_SONSTIGES = 'HAUSHALT_SONSTIGES';
  public QUESTION_WOHNRAUM_EIGENESZIMMER = 'WOHNRAUM_EIGENESZIMMER';
  public QUESTION_WOHNRAUM_EIGENESBETT = 'WOHNRAUM_EIGENESBETT';
  public QUESTION_WOHNRAUM_EIGENESBAD = 'WOHNRAUM_EIGENESBAD';
  public QUESTION_WOHNRAUM_INTERNET = 'WOHNRAUM_INTERNET';
  public QUESTION_ANFORDERUNGEN_GESCHLECHT = 'ANFORDERUNGEN_GESCHLECHT';
  public QUESTION_ANFORDERUNGEN_30BIS40 = 'ANFORDERUNGEN_30BIS40';
  public QUESTION_ANFORDERUNGEN_40BIS50 = 'ANFORDERUNGEN_40BIS50';
  public QUESTION_ANFORDERUNGEN_AB50 = 'ANFORDERUNGEN_AB50';
  public QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND = 'ANFORDERUNGEN_DEUTSCHAUSREICHEND';
  public QUESTION_ANFORDERUNGEN_DEUTSCHGUT = 'ANFORDERUNGEN_DEUTSCHGUT';
  public QUESTION_ANFORDERUNGEN_RAUCHER = 'ANFORDERUNGEN_RAUCHER';
  public QUESTION_ANFORDERUNGEN_NICHTRAUCHER = 'ANFORDERUNGEN_NICHTRAUCHER';
  public QUESTION_ANFORDERUNGEN_RAUCHEREGAL = 'ANFORDERUNGEN_RAUCHEREGAL';
  public QUESTION_EMPFEHLUNG_PFLEGEDIENST = 'EMPFEHLUNG_PFLEGEDIENST';
  public QUESTION_EMPFEHLUNG_KRANKENHAUS = 'EMPFEHLUNG_KRANKENHAUS';
  public QUESTION_EMPFEHLUNG_SONSTIGE = 'EMPFEHLUNG_SONSTIGE';
  public QUESTION_DATENSCHUTZ_AKZEPTIERT = 'DATENSCHUTZ_AKZEPTIERT';

  /**
   * Method to calculate current age
   * */
  public calculateCurrentAge(dob): number {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }
    return age;
  }

  public isWord(docType: string): boolean {
    return docType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || docType === 'application/msword';
  }

  public isExcel(docType: string): boolean {
    return (
      docType === 'application/vnd.openxmlformats-officedocument.excelprocessingml.document' ||
      docType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  }

  public isPdf(docType: string): boolean {
    return docType === 'application/pdf';
  }

  public isOtherFile(docType: string): boolean {
    return !this.isExcel(docType) && !this.isWord(docType) && !this.isPdf(docType);
  }

  public getIconForDocType(docType: string): string {
    if (this.isWord(docType)) {
      return 'file-word';
    }
    if (this.isExcel(docType)) {
      return 'file-excel';
    }
    if (this.isPdf(docType)) {
      return 'file-pdf';
    }

    return 'file';
  }

  public getIconForCommunicationType(comType: string): string {
    if (comType == 'TELEPHONE') {
      return 'phone';
    }
    if (comType == 'MOBILEPHONE') {
      return 'mobile';
    }
    if (comType == 'EMAIL') {
      return 'envelope';
    }
    if (comType == 'FAX') {
      return 'fax';
    }
    if (comType == 'MESSANGER') {
      return 'message';
    }
    if (comType == 'EMERGENCYPHONE') {
      return 'ambulance';
    }
    if (comType == 'PRIVATE') {
      return 'home';
    }
    if (comType == 'BUSINESS') {
      return 'industry';
    }
    if (comType == 'WHATSAPP') {
      return 'fa-brands fa-whatsapp';
    }
    if (comType == 'FACEBOOK') {
      return 'fa-brands fa-facebook';
    }

    return null;
  }

  public convertDateFromServer(dateValue) {
    if (dateValue == null) {
      return null;
    }
    const convertedDate = dayjs(dateValue, 'YYYY-MM-DD');
    return convertedDate.format('DD.MM.YYYY');
  }

  public updateDateField(dateString, obj, field) {
    if (dateString == null || dateString == '') {
      obj[field] = null;
      return;
    }
    const dateVal = dayjs(dateString, 'DD.MM.YYYY');
    if (dateVal.isValid()) {
      obj[field] = dateVal.format('YYYY-MM-DD');
    }
  }

  public getCommunicationValueByType(communications: ICommunication[], type: CommunicationType) {
    let commsLeft = communications.filter(value => value.communicationType === type);
    if (commsLeft.length > 0) {
      return commsLeft[0].contactValue;
    }

    return '';
  }

  public translate(key: string, params?: any): string {
    return params ? this.$t(key, params).toString() : this.$t(key).toString();
  }

  public formatDateTimeOrDefault(val: string, defaultValue: any) {
    if (val == null || val == '') {
      return defaultValue;
    }

    return this.$options.filters.formatDateTime(val);
  }

  public formatDateOrDefault(val: string, defaultValue: any) {
    if (val == null || val == '') {
      return defaultValue;
    }

    return this.$options.filters.formatDate(val);
  }

  public getEnumElementsByFrageName(enumeration: string): string[] {
    let obj = null;
    switch (enumeration) {
      case 'FrageAlter':
        obj = FrageAlter;
        break;
      case 'FrageAusscheidungshaeufigkeit':
        obj = FrageAusscheidungshaeufigkeit;
        break;
      case 'FrageAusscheidungshilfsmittel':
        obj = FrageAusscheidungshilfsmittel;
        break;
      case 'FrageDiagnostizierteKrankheit':
        obj = FrageDiagnostizierteKrankheit;
        break;
      case 'FrageEingeschraenkt':
        obj = FrageEingeschraenkt;
        break;
      case 'FrageEinnahme':
        obj = FrageEinnahme;
        break;
      case 'FrageFuehrerschein':
        obj = FrageFuehrerschein;
        break;
      case 'FrageGeschlecht':
        obj = FrageGeschlecht;
        break;
      case 'FrageGetriebe':
        obj = FrageGetriebe;
        break;
      case 'FrageHaeufigkeitAufwachen':
        obj = FrageHaeufigkeitAufwachen;
        break;
      case 'FrageHaeufigkeitNachteinsaetze':
        obj = FrageHaeufigkeitNachteinsaetze;
        break;
      case 'FrageKognitiveProbleme':
        obj = FrageKognitiveProbleme;
        break;
      case 'FrageKognitivesHilfsmittel':
        obj = FrageKognitivesHilfsmittel;
        break;
      case 'FrageMobilitaetshilfe':
        obj = FrageMobilitaetshilfe;
        break;
      case 'FrageRauchen':
        obj = FrageRauchen;
        break;
      case 'FrageSelbstaendigkeit':
        obj = FrageSelbstaendigkeit;
        break;
      case 'FrageSprachkenntnisse':
        obj = FrageSprachkenntnisse;
        break;
      case 'FrageTherapien':
        obj = FrageTherapien;
        break;
      case 'FrageTrinkmenge':
        obj = FrageTrinkmenge;
        break;
      case 'FragePflegegrad':
        obj = FragePflegegrad;
      default:
        console.error('Fehler bei getEnumElements(' + enumeration + ')');
        return [];
    }
    return Object.keys(obj);
  }
}
