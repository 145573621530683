export enum FrageAusscheidungshilfsmittel {
  WINDELN = 'windeln',

  VORLAGEN = 'vorlagen',

  URINFALSCHE = 'urinflasche',

  KATHETER = 'katheter',

  SUPRAPUBISCHER_KATHETER = 'suprapubischer_katheter',

  SONSTIGE = 'sonstige',
}
