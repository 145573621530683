import { Module } from 'vuex';
import dayjs from 'dayjs';

export interface SelectionCriteriaStateStorable {
  customerOverviewCriteria: SelectionCriteriaCustomerOverviewStateStorable;
  carerOverviewCriteria: SelectionCriteriaCarerOverviewStateStorable;
  postboxCriteria: SelectionCriteriaPostboxStateStorable;
}

export interface SelectionCriteriaCustomerOverviewStateStorable {
  order: string;
  reverse: boolean;
  page: number;
  search: string;
  incArchived: boolean;
  atRequest: boolean;
}

export interface SelectionCriteriaCarerOverviewStateStorable {
  order: string;
  reverse: boolean;
  page: number;
  search: string;
  incArchived: boolean;
}

export interface SelectionCriteriaPostboxStateStorable {
  viewDate: string;
  incFinished: boolean;
  orderOne: string;
  reverseOne: boolean;
  pageOne: number;
  orderTwo: string;
  reverseTwo: boolean;
  pageTwo: number;
  orderThree: string;
  reverseThree: boolean;
  pageThree: number;
}

export const defaultSelectionCriteriaPostboxState: SelectionCriteriaPostboxStateStorable = {
  viewDate: dayjs(Date.now()).format('YYYY-MM-DD'),
  incFinished: false,
  orderOne: 'dueDate',
  reverseOne: false,
  pageOne: 1,
  orderTwo: 'dueDate',
  reverseTwo: false,
  pageTwo: 1,
  orderThree: 'dueDate',
  reverseThree: false,
  pageThree: 1,
};

export const defaultSelectionCriteriaCustomerOverviewState: SelectionCriteriaCustomerOverviewStateStorable = {
  order: 'contractPerson.lastName,contractPerson.firstName',
  reverse: false,
  page: 1,
  search: '',
  incArchived: false,
  atRequest: false,
};

export const defaultSelectionCriteriaCarerOverviewState: SelectionCriteriaCarerOverviewStateStorable = {
  order: 'contact.lastName,contact.firstName',
  reverse: false,
  page: 1,
  search: '',
  incArchived: false,
};
export const selectionCriteriaStore: Module<SelectionCriteriaStateStorable, any> = {
  state: {
    postboxCriteria: defaultSelectionCriteriaPostboxState,
    customerOverviewCriteria: defaultSelectionCriteriaCustomerOverviewState,
    carerOverviewCriteria: defaultSelectionCriteriaCarerOverviewState,
  },
  getters: {
    postboxCriteria: state => state.postboxCriteria,
    customerOverviewCriteria: state => state.customerOverviewCriteria,
    carerOverviewCriteria: state => state.carerOverviewCriteria,
  },
  mutations: {
    postboxCriteria(state, newState: SelectionCriteriaPostboxStateStorable) {
      state.postboxCriteria = newState;
    },
    customerOverviewCriteria(state, newState: SelectionCriteriaCustomerOverviewStateStorable) {
      state.customerOverviewCriteria = newState;
    },
    carerOverviewCriteria(state, newState: SelectionCriteriaCarerOverviewStateStorable) {
      state.carerOverviewCriteria = newState;
    },
  },
};
