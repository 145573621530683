import Vue from 'vue';
import dayjs from 'dayjs';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';

export const DATE_TIME_LONG_FORMAT = 'DD.MM.YYYYTHH:mm';
export const ZONED_DATE_TIME_SERVER_FORMAT = 'YYYY-MM-DD@HH:mm:ss.SSSZ';

export function initFilters() {
  Vue.filter('formatDate', value => {
    if (value) {
      return dayjs(value).format(DATE_FORMAT);
    }
    return '';
  });
  Vue.filter('formatDateToMonth', value => {
    if (value) {
      return dayjs(value).format('MMMM YYYY');
    }
    return '';
  });
  Vue.filter('formatDateTime', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('formatDateTimeLong', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_LONG_FORMAT);
    }
    return '';
  });
  Vue.filter('formatZonedDateTimeServer', value => {
    if (value) {
      return dayjs(value).format(ZONED_DATE_TIME_SERVER_FORMAT);
    }
    return '';
  });
  Vue.filter('duration', value => {
    if (value) {
      const formatted = dayjs.duration(value).humanize();
      if (formatted) {
        return formatted;
      }
      return value;
    }
    return '';
  });
  Vue.filter('toCurrency', function (value) {
    const parsedValue = Number.parseFloat(value);
    if (Number.isNaN(parsedValue)) {
      return value;
    }
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    });
    return formatter.format(value);
  });
}
