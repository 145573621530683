import { IContact } from '@/shared/model/contact.model';
import { IContactPerson } from '@/shared/model/contact-person.model';
import { ISalary } from '@/shared/model/salary.model';
import { ICareAssignment } from '@/shared/model/care-assignment.model';
import { IPatient } from '@/shared/model/patient.model';
import { ICarerSuggestion } from '@/shared/model/carer-suggestion.model';
import { IAgency } from '@/shared/model/agency.model';
import { IApplicationUser } from '@/shared/model/application-user.model';

import { CustomerState } from '@/shared/model/enumerations/customer-state.model';
export interface ICustomer {
  id?: number;
  state?: CustomerState | null;
  contactsource?: string | null;
  archive?: boolean | null;
  contractPerson?: IContact | null;
  contactPersons?: IContactPerson[] | null;
  salaries?: ISalary[] | null;
  careAssignments?: ICareAssignment[] | null;
  patients?: IPatient[] | null;
  carerSuggestions?: ICarerSuggestion[] | null;
  agency?: IAgency | null;
  applicationUser?: IApplicationUser | null;
}

export class Customer implements ICustomer {
  constructor(
    public id?: number,
    public state?: CustomerState | null,
    public contactsource?: string | null,
    public archive?: boolean | null,
    public contractPerson?: IContact | null,
    public contactPersons?: IContactPerson[] | null,
    public salaries?: ISalary[] | null,
    public careAssignments?: ICareAssignment[] | null,
    public patients?: IPatient[] | null,
    public carerSuggestions?: ICarerSuggestion[] | null,
    public agency?: IAgency | null,
    public applicationUser?: IApplicationUser | null
  ) {
    this.archive = this.archive ?? false;
  }
}
