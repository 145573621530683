import { Component, Prop, Provide, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import JhiDataUtils from '@/shared/data/data-utils.service';
import CareUtils from '@/shared/utils/care-utils.service';
import Vue2Filters from 'vue2-filters';
import PatientService from '@/entities/patient/patient.service';
import { IPatient } from '@/shared/model/patient.model';
import CustomerDetail from '@/customer-detail/customer-detail.vue';
import CarerDetail from '@/carer-detail/carer-detail.vue';
import CarerSuggestionService from '@/entities/carer-suggestion/carer-suggestion.service';
import { ICarer } from '@/shared/model/carer.model';
import CareAssignmentService from '@/entities/care-assignment/care-assignment.service';
import CarerService from '@/entities/carer/carer.service';
import ActivityUtils from '@/shared/utils/activity-utils.service';
import { Activity } from '@/shared/model/activity.model';
import { EntityType } from '@/shared/model/enumerations/entity-type.model';

@Component({
  mixins: [Vue2Filters.mixin],
  components: {
    CustomerDetail,
    CarerDetail,
  },
})
export default class PatientBrief extends mixins(JhiDataUtils, CareUtils, ActivityUtils) {
  @Prop({ required: false }) readonly taskId: number;
  @Prop({ required: false }) readonly taskIdWithoutCarer: number;
  @Prop({ required: false }) readonly useCareAssignment: boolean;
  @Prop({ required: false }) readonly careAssignmentId: number;

  @Watch('careAssignmentId')
  onDataChangedCareAssignmentId(value: number, oldValue: number) {
    if (value != null) {
      this.readByCareAssignmentId(value);
    }
  }

  @Provide('patientService') private patientService = () => new PatientService();

  @Provide('carerSuggestionService') private carerSuggestionService = () => new CarerSuggestionService();

  public readByCareAssignmentId(id: number): void {
    this.isProcessing = true;
    this.careAssignmentService()
      .find(id)
      .then(res => {
        const assignment = res;
        this.carerService()
          .find(assignment.carer.id)
          .then(res2 => {
            this.carer = res2;
          })
          .catch(error => {
            this.isProcessing = false;
            this.alertService().showHttpError(this, error.response);
          });
        this.patientService()
          .findByCustomerId(assignment.customer.id)
          .then(res => {
            this.patient = res[0];
            this.patients = res;
          })
          .catch(error => {
            this.isProcessing = false;
            this.alertService().showHttpError(this, error.response);
          });

        this.isProcessing = false;
      })
      .catch(error => {
        this.isProcessing = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  //@Inject('alertService') private alertService: () => AlertService;

  public patient: IPatient = null;

  public patients = [];

  public carer: ICarer = null;

  public isProcessing = false;
  public activitiesLoaded = false;
  public customer = null;

  @Watch('taskId')
  onDataChanged(value: number, oldValue: number) {
    this.readPatientByTaskId(value);

    this.readSelectedCarersByTaskId(value);
  }

  @Watch('taskIdWithoutCarer')
  onDataChangedWithoutCarer(value: number, oldValue: number) {
    this.readPatientByTaskId(value);
  }

  @Provide('carerService') private carerService = () => new CarerService();

  @Provide('careAssignmentService') private careAssignmentService = () => new CareAssignmentService();

  public readPatientByTaskId(taskId: number): void {
    this.isProcessing = true;
    this.patientService()
      .findByTaskId(taskId)
      .then(res => {
        this.patient = res[0];
        this.patients = res;
        this.isProcessing = false;
      })
      .catch(error => {
        this.isProcessing = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public getPatientLabel(): string {
    let name = '';
    let first = true;

    for (const currPatient of this.patients) {
      if (currPatient != null && currPatient.contact != null) {
        if (!first) {
          name = name + ' und ';
        }
        first = false;

        name =
          name +
          currPatient.contact.firstName +
          ' ' +
          currPatient.contact.lastName +
          ' (' +
          this.calculateCurrentAge(currPatient.birthdate) +
          ')';
      }
    }

    return this.$t('care4YouApp.patientBrief.patient').toString() + ': ' + name;
  }

  public readSelectedCarersByTaskId(taskId: number): void {
    this.isProcessing = true;
    if (!this.useCareAssignment) {
      this.carerSuggestionService()
        .findByTaskId(taskId)
        .then(res => {
          if (res.length > 0) {
            this.carer = res[0].carer;
          }

          this.isProcessing = false;
        })
        .catch(error => {
          this.isProcessing = false;
          this.alertService().showHttpError(this, error.response);
        });
    }
  }

  public getCarerLabel(): string {
    let name = '';

    if (this.carer) {
      name = this.carer.contact.firstName + ' ' + this.carer.contact.lastName;
    }

    return this.$t('care4YouApp.carerBrief.carer').toString() + ': ' + name;
  }

  public handleCustomerLoaded(customer) {
    this.customer = customer;
    this.loadActivities();

    this.$emit('customerloaded', this.customer);
  }

  public getNewActivity(): void {
    const newActivity = new Activity();
    newActivity.parentKey = this.customer.id;
    newActivity.parentType = EntityType.CUSTOMER;
    this.showModalForNewActivity(newActivity);
  }

  public loadActivities(): void {
    this.activityService()
      .retrieveAllForCustomer(this.customer?.id)
      .then(
        res => {
          this.activities = res.data;
          this.activitiesLoaded = true;
        },
        err => {
          if (err?.response?.status != 404) {
            this.alertService().showHttpError(this, err.response);
          }
        }
      );
  }

  public addActivity() {
    //TODO
  }
}
