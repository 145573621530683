var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "row justify-content-center" },
    [
      _c("invoice2Pdf", { ref: "invoice2Pdf" }),
      _vm._v(" "),
      !_vm.isLoadingAll() && _vm.customer
        ? _c(
            "div",
            { staticClass: "col-10" },
            [
              _c(
                "form",
                { attrs: { name: "editForm", novalidate: "", role: "form" } },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "text-left" }, [
                            _c(
                              "h2",
                              { attrs: { id: "page-heading" } },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      id: "care4YouApp.customerDetail.home.createLabel",
                                    },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "care4YouApp.customerDetail.home." +
                                            _vm.title
                                        )
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Customer Detail\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("watcher-identicator", {
                                  attrs: {
                                    parameter: _vm.customer.id + "",
                                    "watcher-key": "CUSTOMER_UPDATES",
                                    "watcher-title": _vm.translate(
                                      "care4YouApp.watcherComponent.types.customerUpdates"
                                    ),
                                    "is-disabled":
                                      _vm.isCreate || _vm.patientForOverview,
                                  },
                                }),
                                _vm._v(" "),
                                _vm.patientForOverview
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "CustomerDetail",
                                            params: {
                                              customerId: _vm.customer.id,
                                              tabIndex: 0,
                                              patientId: 0,
                                              contactPersonId: 0,
                                            },
                                          },
                                          custom: "",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            title: _vm.translate(
                                              "entity.action.openNewTab"
                                            ),
                                            icon: "arrow-up-right-from-square",
                                            size: "sm",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          !_vm.paramsForUserTask &&
                          !_vm.isCreate &&
                          !_vm.patientForOverview
                            ? _c(
                                "b-col",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "success", size: "lg" },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "care4YouApp.customerDetail.detail.addTask"
                                          )
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addTask()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Cancel\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "b-tabs",
                    {
                      attrs: { pills: "", vertical: "" },
                      model: {
                        value: _vm.tabIndex,
                        callback: function ($$v) {
                          _vm.tabIndex = $$v
                        },
                        expression: "tabIndex",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            title: _vm.$t(
                              "care4YouApp.customerDetail.sections.generalInformation"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchTab(0)
                            },
                          },
                        },
                        [
                          _c("div", [
                            _c(
                              "h3",
                              [
                                _c(
                                  "span",
                                  {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "care4YouApp.customerDetail.subSections.managementInformation"
                                        )
                                      ),
                                    },
                                  },
                                  [_vm._v("Management information")]
                                ),
                                _vm._v(" "),
                                !_vm.isCreate &&
                                (_vm.customer.archive == null ||
                                  _vm.customer.archive == false)
                                  ? _c("router-link", {
                                      attrs: {
                                        to: {
                                          name: "CustomerCoreUpdate",
                                          params: {
                                            customerId: _vm.customer.id,
                                          },
                                        },
                                        custom: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ navigate }) {
                                              return [
                                                !_vm.patientForOverview
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-primary btn-sm",
                                                        attrs: {
                                                          id: "update-customer-core",
                                                        },
                                                        on: { click: navigate },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: "pencil-alt",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        754946481
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "form-group col-3" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    attrs: { for: "customer-agency" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("care4YouApp.customer.agency")
                                      ),
                                    },
                                  },
                                  [_vm._v(" Agency ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.customer.agency,
                                        expression: "customer.agency",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "customer-agency",
                                      "data-cy": "agency",
                                      disabled: !_vm.isCreate,
                                      name: "agency",
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.customer,
                                          "agency",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { domProps: { value: null } }),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.agencies,
                                      function (agencyOption) {
                                        return _c(
                                          "option",
                                          {
                                            key: agencyOption.id,
                                            domProps: {
                                              value:
                                                _vm.customer.agency &&
                                                agencyOption.id ===
                                                  _vm.customer.agency.id
                                                  ? _vm.customer.agency
                                                  : agencyOption,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(agencyOption.name) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group col-3" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    attrs: { for: "customer-contactsource" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "care4YouApp.customer.contactsource"
                                        )
                                      ),
                                    },
                                  },
                                  [_vm._v("\n                  Contactsource")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.contactsource,
                                      expression: "customer.contactsource",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "customer-contactsource",
                                    readonly: !_vm.isCreate,
                                    name: "contactsource",
                                    type: "text",
                                  },
                                  domProps: {
                                    value: _vm.customer.contactsource,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.customer,
                                        "contactsource",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group col-3" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    attrs: { for: "customer-state" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("care4YouApp.customer.state")
                                      ),
                                    },
                                  },
                                  [_vm._v(" State")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.customer.state,
                                        expression: "customer.state",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "customer-state",
                                      disabled: "",
                                      name: "state",
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.customer,
                                          "state",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.customerStateValues,
                                    function (state) {
                                      return _c(
                                        "option",
                                        {
                                          key: state,
                                          attrs: {
                                            label: _vm.$t(
                                              "care4YouApp.CustomerState." +
                                                state
                                            ),
                                          },
                                          domProps: { value: state },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(state) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group col-3" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    attrs: { for: "customer-archive" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("care4YouApp.customer.archive")
                                      ),
                                    },
                                  },
                                  [_vm._v(" Archive")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.archive,
                                      expression: "customer.archive",
                                    },
                                  ],
                                  staticClass: "form-check",
                                  attrs: {
                                    id: "customer-archive",
                                    disabled: "",
                                    name: "archive",
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.customer.archive)
                                      ? _vm._i(_vm.customer.archive, null) > -1
                                      : _vm.customer.archive,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.customer.archive,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.customer,
                                              "archive",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.customer,
                                              "archive",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.customer, "archive", $$c)
                                      }
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "h3",
                              [
                                _c(
                                  "span",
                                  {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "care4YouApp.customer.contractPerson"
                                        )
                                      ),
                                    },
                                  },
                                  [_vm._v("Contract person")]
                                ),
                                _vm._v(" "),
                                _vm.customer.contractPerson &&
                                !_vm.isCreate &&
                                (_vm.customer.archive == null ||
                                  _vm.customer.archive == false)
                                  ? _c("router-link", {
                                      attrs: {
                                        to: {
                                          name: "CustomerContractPersonUpdate",
                                          params: {
                                            customerId: _vm.customer.id,
                                          },
                                        },
                                        custom: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ navigate }) {
                                              return [
                                                !_vm.patientForOverview
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-primary btn-sm",
                                                        attrs: {
                                                          id: "update-customer-contract-person",
                                                        },
                                                        on: { click: navigate },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: "pencil-alt",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2611562048
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.customer.contractPerson
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-3" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          attrs: {
                                            for: "contractPerson-salutation",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.contact.salutation"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Salutation")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.customer.contractPerson
                                                  .salutation,
                                              expression:
                                                "customer.contractPerson.salutation",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "contractPerson-salutation",
                                            disabled: !_vm.isCreate,
                                            name: "salutation",
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.customer.contractPerson,
                                                "salutation",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.salutationValues,
                                          function (salutation) {
                                            return _c(
                                              "option",
                                              {
                                                key: salutation,
                                                attrs: {
                                                  label: _vm.$t(
                                                    "care4YouApp.Salutation." +
                                                      salutation
                                                  ),
                                                },
                                                domProps: { value: salutation },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(salutation) +
                                                    "\n                  "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-3" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          attrs: { for: "contratPerson-title" },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.contact.title"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Title")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.customer.contractPerson.title,
                                            expression:
                                              "customer.contractPerson.title",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "contractPerson-title",
                                          readonly: !_vm.isCreate,
                                          name: "title",
                                          type: "text",
                                        },
                                        domProps: {
                                          value:
                                            _vm.customer.contractPerson.title,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.customer.contractPerson,
                                              "title",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.customer.contractPerson
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-4" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          attrs: {
                                            for: "contractPerson-firstname",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.contact.firstName"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Surname")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.customer.contractPerson
                                                .firstName,
                                            expression:
                                              "customer.contractPerson.firstName",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "contractPerson-firstname",
                                          readonly: !_vm.isCreate,
                                          name: "surname",
                                          type: "text",
                                        },
                                        domProps: {
                                          value:
                                            _vm.customer.contractPerson
                                              .firstName,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.customer.contractPerson,
                                              "firstName",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-4" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          attrs: {
                                            for: "contractPerson-lastname",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.contact.lastName"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Lastname")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.customer.contractPerson
                                                .lastName,
                                            expression:
                                              "customer.contractPerson.lastName",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "contractPerson-lastname",
                                          readonly: !_vm.isCreate,
                                          name: "lastname",
                                          type: "text",
                                        },
                                        domProps: {
                                          value:
                                            _vm.customer.contractPerson
                                              .lastName,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.customer.contractPerson,
                                              "lastName",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "form-group col-3" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    attrs: {
                                      for: "customer-contractPerson-communicationChannel",
                                    },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "care4YouApp.contact.communicationChannel"
                                        )
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Communication Channel"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.customer.contractPerson
                                            .communicationChannel,
                                        expression:
                                          "customer.contractPerson.communicationChannel",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "customer-contractPerson-communicationChannel",
                                      disabled: !_vm.isCreate,
                                      name: "communicationChannel",
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.customer.contractPerson,
                                          "communicationChannel",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.communicationChannelValues,
                                    function (communicationChannel) {
                                      return _c(
                                        "option",
                                        {
                                          key: communicationChannel,
                                          attrs: {
                                            label: _vm.$t(
                                              "care4YouApp.CommunicationChannel." +
                                                communicationChannel
                                            ),
                                          },
                                          domProps: {
                                            value: communicationChannel,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(communicationChannel) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.customer.contractPerson
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-4" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          attrs: {
                                            for: "contractPerson-companyNumber",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.contact.companyNumber"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Company Number")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.customer.contractPerson
                                                .companyNumber,
                                            expression:
                                              "customer.contractPerson.companyNumber",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "contractPerson-companyNumber",
                                          readonly: !_vm.isCreate,
                                          name: "companyNumber",
                                          type: "text",
                                        },
                                        domProps: {
                                          value:
                                            _vm.customer.contractPerson
                                              .companyNumber,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.customer.contractPerson,
                                              "companyNumber",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-4" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          attrs: {
                                            for: "contractPerson-taxNumber",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.contact.taxNumberCustomer"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Tax Number")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.customer.contractPerson
                                                .taxNumber,
                                            expression:
                                              "customer.contractPerson.taxNumber",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "contractPerson-taxNumber",
                                          readonly: !_vm.isCreate,
                                          name: "taxNumber",
                                          type: "text",
                                        },
                                        domProps: {
                                          value:
                                            _vm.customer.contractPerson
                                              .taxNumber,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.customer.contractPerson,
                                              "taxNumber",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.customer.contractPerson
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-4" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          attrs: {
                                            for: "contractPerson-street",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.contact.street"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Street")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.customer.contractPerson
                                                .street,
                                            expression:
                                              "customer.contractPerson.street",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "contractPerson-street",
                                          readonly: !_vm.isCreate,
                                          name: "street",
                                          type: "text",
                                        },
                                        domProps: {
                                          value:
                                            _vm.customer.contractPerson.street,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.customer.contractPerson,
                                              "street",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-4" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          attrs: {
                                            for: "contractPerson-houseNumber",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.contact.houseNumber"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Housenumber")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.customer.contractPerson
                                                .houseNumber,
                                            expression:
                                              "customer.contractPerson.houseNumber",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "contractPerson-houseNumber",
                                          readonly: !_vm.isCreate,
                                          name: "houseNumber",
                                          type: "text",
                                        },
                                        domProps: {
                                          value:
                                            _vm.customer.contractPerson
                                              .houseNumber,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.customer.contractPerson,
                                              "houseNumber",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.customer.contractPerson
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-4" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          attrs: {
                                            for: "contractPerson-postcode",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.contact.postcode"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Postcode")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.customer.contractPerson
                                                .postcode,
                                            expression:
                                              "customer.contractPerson.postcode",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "contractPerson-postcode",
                                          readonly: !_vm.isCreate,
                                          name: "postcode",
                                          type: "text",
                                        },
                                        domProps: {
                                          value:
                                            _vm.customer.contractPerson
                                              .postcode,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.customer.contractPerson,
                                              "postcode",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-4" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          attrs: { for: "contractPerson-city" },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("care4YouApp.contact.city")
                                            ),
                                          },
                                        },
                                        [_vm._v("City")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.customer.contractPerson.city,
                                            expression:
                                              "customer.contractPerson.city",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "contractPerson-city",
                                          readonly: !_vm.isCreate,
                                          name: "city",
                                          type: "text",
                                        },
                                        domProps: {
                                          value:
                                            _vm.customer.contractPerson.city,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.customer.contractPerson,
                                              "city",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-4" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          attrs: {
                                            for: "contractPerson-country",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.contact.country"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("Country")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.customer.contractPerson
                                                .country,
                                            expression:
                                              "customer.contractPerson.country",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "contractPerson-country",
                                          readonly: !_vm.isCreate,
                                          name: "country",
                                          type: "text",
                                        },
                                        domProps: {
                                          value:
                                            _vm.customer.contractPerson.country,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.customer.contractPerson,
                                              "country",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            title: _vm.$t(
                              "care4YouApp.customerDetail.sections.patients"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchTab(1)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "accordion",
                              attrs: { role: "tablist" },
                            },
                            [
                              _vm._l(
                                _vm.customer.patients,
                                function (patient, pindex) {
                                  return _c("b-card", {
                                    key: pindex,
                                    staticClass: "mb-2",
                                    attrs: {
                                      "header-bg-variant": "light",
                                      "body-class": "p-0",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function () {
                                            return [
                                              _c(
                                                "b-row",
                                                {
                                                  attrs: {
                                                    "align-v": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { md: "" } },
                                                    [
                                                      _c(
                                                        "h4",
                                                        { staticClass: "mb-0" },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                patient?.contact
                                                                  ?.lastName +
                                                                  ", " +
                                                                  patient
                                                                    ?.contact
                                                                    ?.firstName
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { md: "auto" } },
                                                    [
                                                      !_vm.isCreate &&
                                                      (_vm.customer.archive ==
                                                        null ||
                                                        _vm.customer.archive ==
                                                          false) &&
                                                      !_vm.patientForOverview
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "btn btn-primary btn-sm",
                                                              attrs: {
                                                                id: "update-customer-patient",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.editPatient(
                                                                      patient
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "pencil-alt",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-toggle",
                                                              rawName:
                                                                "v-b-toggle",
                                                              value:
                                                                "accordionPatients-" +
                                                                patient.id,
                                                              expression:
                                                                "'accordionPatients-' + patient.id",
                                                            },
                                                          ],
                                                          staticClass: "btn-sm",
                                                          attrs: {
                                                            variant: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.toggleAccordion(
                                                                "accordionPatients-" +
                                                                  patient.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          !_vm.getToggleAccordion(
                                                            "accordionPatients-" +
                                                              patient.id
                                                          )
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "plus",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.getToggleAccordion(
                                                            "accordionPatients-" +
                                                              patient.id
                                                          )
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "minus",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              patient.contact
                                                ? _c(
                                                    "b-collapse",
                                                    {
                                                      staticClass: "p-3",
                                                      attrs: {
                                                        id:
                                                          "accordionPatients-" +
                                                          patient.id,
                                                        visible:
                                                          _vm.getInitialOpenAccordion(
                                                            "accordionPatients-" +
                                                              patient.id
                                                          ),
                                                        accordion:
                                                          "patientAccordion",
                                                        role: "tabpanel",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-tabs",
                                                        {
                                                          attrs: {
                                                            "content-class":
                                                              "mt-3",
                                                            fill: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-tab",
                                                            {
                                                              key: "personalData",
                                                              attrs: {
                                                                title: _vm.$t(
                                                                  "care4YouApp.customerDetail.tabs.personalData"
                                                                ),
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-3",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-beginType",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.patient.beginType"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Begintype"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "select",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  patient.beginType,
                                                                                expression:
                                                                                  "patient.beginType",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              id: "patient-beginType",
                                                                              "data-cy":
                                                                                "beginType",
                                                                              disabled:
                                                                                !_vm.isCreate,
                                                                              name: "beginType",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                var $$selectedVal =
                                                                                  Array.prototype.filter
                                                                                    .call(
                                                                                      $event
                                                                                        .target
                                                                                        .options,
                                                                                      function (
                                                                                        o
                                                                                      ) {
                                                                                        return o.selected
                                                                                      }
                                                                                    )
                                                                                    .map(
                                                                                      function (
                                                                                        o
                                                                                      ) {
                                                                                        var val =
                                                                                          "_value" in
                                                                                          o
                                                                                            ? o._value
                                                                                            : o.value
                                                                                        return val
                                                                                      }
                                                                                    )
                                                                                _vm.$set(
                                                                                  patient,
                                                                                  "beginType",
                                                                                  $event
                                                                                    .target
                                                                                    .multiple
                                                                                    ? $$selectedVal
                                                                                    : $$selectedVal[0]
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.careBeginTypeValues,
                                                                          function (
                                                                            careBeginType
                                                                          ) {
                                                                            return _c(
                                                                              "option",
                                                                              {
                                                                                key: careBeginType,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _vm.$t(
                                                                                        "care4YouApp.CareBeginType." +
                                                                                          careBeginType
                                                                                      ),
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      careBeginType,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      careBeginType
                                                                                    ) +
                                                                                    "\n                            "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  patient.beginType ==
                                                                  "DATE"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-group col-3",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              staticClass:
                                                                                "form-control-label",
                                                                              attrs:
                                                                                {
                                                                                  for: "patient-careBeginDate",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  textContent:
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "care4YouApp.patient.careBeginDate"
                                                                                      )
                                                                                    ),
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Carebegindate"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "b-input-group",
                                                                            {
                                                                              staticClass:
                                                                                "mb-3",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-input-group-prepend",
                                                                                [
                                                                                  _c(
                                                                                    "b-form-datepicker",
                                                                                    {
                                                                                      staticClass:
                                                                                        "form-control",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            !_vm.isCreate,
                                                                                          locale:
                                                                                            _vm.currentLanguage,
                                                                                          "aria-controls":
                                                                                            "patient-careBeginDate",
                                                                                          "button-only":
                                                                                            "",
                                                                                          "close-button":
                                                                                            "",
                                                                                          name: "careBeginDate",
                                                                                          "reset-button":
                                                                                            "",
                                                                                          "today-button":
                                                                                            "",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            patient.careBeginDate,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                patient,
                                                                                                "careBeginDate",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "patient.careBeginDate",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "b-form-input",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-control",
                                                                                  attrs:
                                                                                    {
                                                                                      id: "patient-careBeginDate",
                                                                                      readonly:
                                                                                        !_vm.isCreate,
                                                                                      value:
                                                                                        _vm.convertDateFromServer(
                                                                                          patient.careBeginDate
                                                                                        ),
                                                                                      name: "careBeginDate",
                                                                                      type: "text",
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.updateDateField(
                                                                                          $event,
                                                                                          patient,
                                                                                          "careBeginDate"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "h3",
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "care4YouApp.customerDetail.subSections.patientInformation"
                                                                              )
                                                                            ),
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Information of patient"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm.isCreate
                                                                    ? _c(
                                                                        "b-button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-primary btn-sm",
                                                                          attrs:
                                                                            {
                                                                              id: "transfer-data-from-contract-person-to-patient",
                                                                              title:
                                                                                _vm.$t(
                                                                                  "care4YouApp.customerDetail.detail.transferDataFromContractPersonToPatient"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.transferDataFromContractPersonToPatient(
                                                                                  patient
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "font-awesome-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  icon: "share",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-3",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-salutation",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.contact.salutation"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Salutation"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "select",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  patient
                                                                                    .contact
                                                                                    .salutation,
                                                                                expression:
                                                                                  "patient.contact.salutation",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              id: "patient-salutation",
                                                                              "data-cy":
                                                                                "salutation",
                                                                              disabled:
                                                                                !_vm.isCreate,
                                                                              name: "salutation",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                var $$selectedVal =
                                                                                  Array.prototype.filter
                                                                                    .call(
                                                                                      $event
                                                                                        .target
                                                                                        .options,
                                                                                      function (
                                                                                        o
                                                                                      ) {
                                                                                        return o.selected
                                                                                      }
                                                                                    )
                                                                                    .map(
                                                                                      function (
                                                                                        o
                                                                                      ) {
                                                                                        var val =
                                                                                          "_value" in
                                                                                          o
                                                                                            ? o._value
                                                                                            : o.value
                                                                                        return val
                                                                                      }
                                                                                    )
                                                                                _vm.$set(
                                                                                  patient.contact,
                                                                                  "salutation",
                                                                                  $event
                                                                                    .target
                                                                                    .multiple
                                                                                    ? $$selectedVal
                                                                                    : $$selectedVal[0]
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.salutationValues,
                                                                          function (
                                                                            salutation
                                                                          ) {
                                                                            return _c(
                                                                              "option",
                                                                              {
                                                                                key: salutation,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _vm.$t(
                                                                                        "care4YouApp.Salutation." +
                                                                                          salutation
                                                                                      ),
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      salutation,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      salutation
                                                                                    ) +
                                                                                    "\n                            "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-3",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-title",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.contact.title"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Title"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  patient
                                                                                    .contact
                                                                                    .title,
                                                                                expression:
                                                                                  "patient.contact.title",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              id: "patient-title",
                                                                              "data-cy":
                                                                                "title",
                                                                              readonly:
                                                                                !_vm.isCreate,
                                                                              name: "title",
                                                                              type: "text",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                patient
                                                                                  .contact
                                                                                  .title,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.$set(
                                                                                  patient.contact,
                                                                                  "title",
                                                                                  $event
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-4",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-firstName",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.contact.firstName"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Firstname"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  patient
                                                                                    .contact
                                                                                    .firstName,
                                                                                expression:
                                                                                  "patient.contact.firstName",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              id: "patient-firstName",
                                                                              "data-cy":
                                                                                "firstName",
                                                                              readonly:
                                                                                !_vm.isCreate,
                                                                              name: "firstName",
                                                                              type: "text",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                patient
                                                                                  .contact
                                                                                  .firstName,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.$set(
                                                                                  patient.contact,
                                                                                  "firstName",
                                                                                  $event
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-4",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-lastName",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.contact.lastName"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Lastname"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  patient
                                                                                    .contact
                                                                                    .lastName,
                                                                                expression:
                                                                                  "patient.contact.lastName",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              id: "patient-lastName",
                                                                              "data-cy":
                                                                                "lastName",
                                                                              readonly:
                                                                                !_vm.isCreate,
                                                                              name: "lastName",
                                                                              type: "text",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                patient
                                                                                  .contact
                                                                                  .lastName,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.$set(
                                                                                  patient.contact,
                                                                                  "lastName",
                                                                                  $event
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-4",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-birthdate",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.patient.birthdate"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Birthdate"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "b-input-group",
                                                                        {
                                                                          staticClass:
                                                                            "mb-3",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-input-group-prepend",
                                                                            [
                                                                              _c(
                                                                                "b-form-datepicker",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-control",
                                                                                  attrs:
                                                                                    {
                                                                                      locale:
                                                                                        _vm.currentLanguage,
                                                                                      "aria-controls":
                                                                                        "patient-birthdate",
                                                                                      "button-only":
                                                                                        "",
                                                                                      "close-button":
                                                                                        "",
                                                                                      disabled:
                                                                                        !_vm.isCreate,
                                                                                      name: "birthdate",
                                                                                      "reset-button":
                                                                                        "",
                                                                                      "today-button":
                                                                                        "",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        patient.birthdate,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            patient,
                                                                                            "birthdate",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "patient.birthdate",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              staticClass:
                                                                                "form-control",
                                                                              attrs:
                                                                                {
                                                                                  id: "patient-birthdate",
                                                                                  value:
                                                                                    _vm.convertDateFromServer(
                                                                                      patient.birthdate
                                                                                    ),
                                                                                  readonly:
                                                                                    !_vm.isCreate,
                                                                                  name: "birthdate",
                                                                                  type: "text",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.updateDateField(
                                                                                      $event,
                                                                                      patient,
                                                                                      "birthdate"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-4",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-gender",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.contact.gender"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Gender"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "select",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  patient
                                                                                    .contact
                                                                                    .gender,
                                                                                expression:
                                                                                  "patient.contact.gender",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              id: "patient-gender",
                                                                              "data-cy":
                                                                                "gender",
                                                                              disabled:
                                                                                !_vm.isCreate,
                                                                              name: "gender",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                var $$selectedVal =
                                                                                  Array.prototype.filter
                                                                                    .call(
                                                                                      $event
                                                                                        .target
                                                                                        .options,
                                                                                      function (
                                                                                        o
                                                                                      ) {
                                                                                        return o.selected
                                                                                      }
                                                                                    )
                                                                                    .map(
                                                                                      function (
                                                                                        o
                                                                                      ) {
                                                                                        var val =
                                                                                          "_value" in
                                                                                          o
                                                                                            ? o._value
                                                                                            : o.value
                                                                                        return val
                                                                                      }
                                                                                    )
                                                                                _vm.$set(
                                                                                  patient.contact,
                                                                                  "gender",
                                                                                  $event
                                                                                    .target
                                                                                    .multiple
                                                                                    ? $$selectedVal
                                                                                    : $$selectedVal[0]
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.genderValues,
                                                                          function (
                                                                            gender
                                                                          ) {
                                                                            return _c(
                                                                              "option",
                                                                              {
                                                                                key: gender,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _vm.$t(
                                                                                        "care4YouApp.Gender." +
                                                                                          gender
                                                                                      ),
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      gender,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      gender
                                                                                    ) +
                                                                                    "\n                            "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-4",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-nationality",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.patient.nationality"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Nationality"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  patient.nationality,
                                                                                expression:
                                                                                  "patient.nationality",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              id: "patient-nationality",
                                                                              "data-cy":
                                                                                "nationality",
                                                                              readonly:
                                                                                !_vm.isCreate,
                                                                              name: "nationality",
                                                                              type: "text",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                patient.nationality,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.$set(
                                                                                  patient,
                                                                                  "nationality",
                                                                                  $event
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-height",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.patient.height"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Height"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model.number",
                                                                                value:
                                                                                  patient.height,
                                                                                expression:
                                                                                  "patient.height",
                                                                                modifiers:
                                                                                  {
                                                                                    number: true,
                                                                                  },
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              id: "patient-height",
                                                                              "data-cy":
                                                                                "height",
                                                                              readonly:
                                                                                !_vm.isCreate,
                                                                              name: "height",
                                                                              type: "number",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                patient.height,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.$set(
                                                                                  patient,
                                                                                  "height",
                                                                                  _vm._n(
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                )
                                                                              },
                                                                            blur: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$forceUpdate()
                                                                            },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-weight",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.patient.weight"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Weight"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model.number",
                                                                                value:
                                                                                  patient.weight,
                                                                                expression:
                                                                                  "patient.weight",
                                                                                modifiers:
                                                                                  {
                                                                                    number: true,
                                                                                  },
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              id: "patient-weight",
                                                                              "data-cy":
                                                                                "weight",
                                                                              readonly:
                                                                                !_vm.isCreate,
                                                                              name: "weight",
                                                                              type: "number",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                patient.weight,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.$set(
                                                                                  patient,
                                                                                  "weight",
                                                                                  _vm._n(
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                )
                                                                              },
                                                                            blur: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$forceUpdate()
                                                                            },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-4",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-personcount",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.patient.personcount"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Personcount"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model.number",
                                                                                value:
                                                                                  patient.personcount,
                                                                                expression:
                                                                                  "patient.personcount",
                                                                                modifiers:
                                                                                  {
                                                                                    number: true,
                                                                                  },
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              id: "patient-personcount",
                                                                              "data-cy":
                                                                                "personcount",
                                                                              readonly:
                                                                                !_vm.isCreate,
                                                                              name: "personcount",
                                                                              type: "number",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                patient.personcount,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.$set(
                                                                                  patient,
                                                                                  "personcount",
                                                                                  _vm._n(
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                )
                                                                              },
                                                                            blur: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$forceUpdate()
                                                                            },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-3",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-control-label",
                                                                          attrs:
                                                                            {
                                                                              for: "patient-healthInsurance",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "care4YouApp.patient.healthInsurance"
                                                                                  )
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Health Insurance"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  patient.healthInsurance,
                                                                                expression:
                                                                                  "patient.healthInsurance",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              id: "patient-healthInsurance",
                                                                              readonly:
                                                                                !_vm.isCreate,
                                                                              name: "healthInsurance",
                                                                              type: "text",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                patient.healthInsurance,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.$set(
                                                                                  patient,
                                                                                  "healthInsurance",
                                                                                  $event
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "b-tab",
                                                            {
                                                              key: "online-fragebogen",
                                                              attrs: {
                                                                title: _vm.$t(
                                                                  "care4YouApp.customerDetail.tabs.onlineQuestionnaire"
                                                                ),
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-12",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "fragebogen",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "agency-id":
                                                                                _vm
                                                                                  .customer
                                                                                  .agency
                                                                                  .id,
                                                                              "patient-care-attributes":
                                                                                patient.careAttributes,
                                                                              "readonly-mode":
                                                                                _vm.isFragebogenReadOnly(),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      !_vm.isLoadingData &&
                                                      _vm.customerId &&
                                                      _vm.customerId != -1 &&
                                                      _vm.hasQuestionnaire[
                                                        patient.id
                                                      ] !== true
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-info",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.generateDynamicFieldAndAdd(
                                                                      patient.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "care4YouApp.customerDetail.edit.createQuestionnaire"
                                                                        )
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Create Questionnaire"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      !_vm.isLoadingData &&
                                                      _vm.customerId &&
                                                      _vm.customerId != -1 &&
                                                      _vm.hasQuestionnaire[
                                                        patient.id
                                                      ] === true
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-info",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.stopQuestionnaire(
                                                                      patient.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "care4YouApp.customerDetail.edit.stopQuestionnaire"
                                                                        )
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Stop Questionnaire"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }
                              ),
                              _vm._v(" "),
                              !_vm.isCreate &&
                              (_vm.customer.archive == null ||
                                _vm.customer.archive == false) &&
                              !_vm.patientForOverview
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: { id: "addNewPatient" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addPatientLater()
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "plus" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "d-none d-md-inline",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("entity.action.add")
                                            ),
                                          },
                                        },
                                        [_vm._v("Add")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isCreate
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: {
                                        id: "addPatient",
                                        type: "button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addPatient()
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "plus" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("entity.action.add")
                                            ),
                                          },
                                        },
                                        [_vm._v("Add")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            title: _vm.$t(
                              "care4YouApp.customerDetail.sections.contactPersons"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchTab(2)
                            },
                          },
                        },
                        [
                          _vm._l(
                            _vm.customer.contactPersons,
                            function (contactPerson, index) {
                              return _c("b-card", {
                                key: index,
                                staticClass: "mb-2",
                                attrs: {
                                  "header-bg-variant": "light",
                                  "body-class": "p-0",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-row",
                                            { attrs: { "align-v": "center" } },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "" } },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass: "mb-0",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "care4YouApp.customerDetail.subSections.contactPerson",
                                                            {
                                                              number: index + 1,
                                                            }
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    #. ContactPerson\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-col",
                                                { attrs: { md: "" } },
                                                [
                                                  _c(
                                                    "h4",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            contactPerson
                                                              .contact
                                                              .lastName +
                                                              ", " +
                                                              contactPerson
                                                                .contact
                                                                .firstName
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-col",
                                                { attrs: { md: "auto" } },
                                                [
                                                  !_vm.isCreate &&
                                                  (_vm.customer.archive ==
                                                    null ||
                                                    _vm.customer.archive ==
                                                      false) &&
                                                  !_vm.patientForOverview
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "btn btn-primary btn-sm",
                                                          attrs: {
                                                            id: "update-customer-contact",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editContactPerson(
                                                                contactPerson
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: "pencil-alt",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-toggle",
                                                          rawName: "v-b-toggle",
                                                          value:
                                                            "accordionContacts-" +
                                                            contactPerson.id,
                                                          expression:
                                                            "'accordionContacts-' + contactPerson.id",
                                                        },
                                                      ],
                                                      staticClass: "btn-sm",
                                                      attrs: {
                                                        variant: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleAccordion(
                                                            "accordionContacts-" +
                                                              contactPerson.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      !_vm.getToggleAccordion(
                                                        "accordionContacts-" +
                                                          contactPerson.id
                                                      )
                                                        ? _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: "plus",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.getToggleAccordion(
                                                        "accordionContacts-" +
                                                          contactPerson.id
                                                      )
                                                        ? _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: "minus",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-collapse",
                                            {
                                              staticClass: "p-3",
                                              attrs: {
                                                id:
                                                  "accordionContacts-" +
                                                  contactPerson.id,
                                                visible:
                                                  _vm.getInitialOpenAccordion(
                                                    "accordionContacts-" +
                                                      contactPerson.id
                                                  ),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-6",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              1 +
                                                              "contact-person-availability",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.contactPerson.availability"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Availability\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              contactPerson.availability,
                                                            expression:
                                                              "contactPerson.availability",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            1 +
                                                            "contact-person-availability",
                                                          name:
                                                            index +
                                                            1 +
                                                            "availability",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            contactPerson.availability,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              contactPerson,
                                                              "availability",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              1 +
                                                              "contact-person-relation",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.contactPerson.relation"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Relation\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              contactPerson.relation,
                                                            expression:
                                                              "contactPerson.relation",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            1 +
                                                            "contact-person-relation",
                                                          name:
                                                            index +
                                                            1 +
                                                            "relation",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            contactPerson.relation,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              contactPerson,
                                                              "relation",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-3",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              1 +
                                                              "contact-salutation",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.contact.salutation"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Salutation")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                contactPerson
                                                                  .contact
                                                                  .salutation,
                                                              expression:
                                                                "contactPerson.contact.salutation",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            id:
                                                              index +
                                                              1 +
                                                              "contact-salutation",
                                                            name:
                                                              index +
                                                              1 +
                                                              "salutation",
                                                            disabled:
                                                              !_vm.isCreate,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                contactPerson.contact,
                                                                "salutation",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.salutationValues,
                                                          function (
                                                            salutation
                                                          ) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: salutation,
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "care4YouApp.Salutation." +
                                                                      salutation
                                                                  ),
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    salutation,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      salutation
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-3",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              1 +
                                                              "contact-title",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.contact.title"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Title")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              contactPerson
                                                                .contact.title,
                                                            expression:
                                                              "contactPerson.contact.title",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            1 +
                                                            "contact-title",
                                                          name:
                                                            index + 1 + "title",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            contactPerson
                                                              .contact.title,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              contactPerson.contact,
                                                              "title",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              1 +
                                                              "contact-firstname",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.contact.firstName"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Surname")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              contactPerson
                                                                .contact
                                                                .firstName,
                                                            expression:
                                                              "contactPerson.contact.firstName",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            1 +
                                                            "contact-firstname",
                                                          name:
                                                            index +
                                                            1 +
                                                            "surname",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            contactPerson
                                                              .contact
                                                              .firstName,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              contactPerson.contact,
                                                              "firstName",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              1 +
                                                              "contact-lastname",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.contact.lastName"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Lastname")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              contactPerson
                                                                .contact
                                                                .lastName,
                                                            expression:
                                                              "contactPerson.contact.lastName",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            1 +
                                                            "contact-lastname",
                                                          name:
                                                            index +
                                                            1 +
                                                            "lastname",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            contactPerson
                                                              .contact.lastName,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              contactPerson.contact,
                                                              "lastName",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              1 +
                                                              "contact-street",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.contact.street"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Street")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              contactPerson
                                                                .contact.street,
                                                            expression:
                                                              "contactPerson.contact.street",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            1 +
                                                            "contact-street",
                                                          name:
                                                            index +
                                                            1 +
                                                            "street",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            contactPerson
                                                              .contact.street,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              contactPerson.contact,
                                                              "street",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              1 +
                                                              "contact-houseNumber",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.contact.houseNumber"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Housenumber")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              contactPerson
                                                                .contact
                                                                .houseNumber,
                                                            expression:
                                                              "contactPerson.contact.houseNumber",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            1 +
                                                            "contact-houseNumber",
                                                          name:
                                                            index +
                                                            1 +
                                                            "houseNumber",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            contactPerson
                                                              .contact
                                                              .houseNumber,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              contactPerson.contact,
                                                              "houseNumber",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              1 +
                                                              "contact-postcode",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.contact.postcode"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Postcode")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              contactPerson
                                                                .contact
                                                                .postcode,
                                                            expression:
                                                              "contactPerson.contact.postcode",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            1 +
                                                            "contact-postcode",
                                                          name:
                                                            index +
                                                            1 +
                                                            "postcode",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            contactPerson
                                                              .contact.postcode,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              contactPerson.contact,
                                                              "postcode",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              1 +
                                                              "contact-city",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.contact.city"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("City")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              contactPerson
                                                                .contact.city,
                                                            expression:
                                                              "contactPerson.contact.city",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            1 +
                                                            "contact-city",
                                                          name:
                                                            index + 1 + "city",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            contactPerson
                                                              .contact.city,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              contactPerson.contact,
                                                              "city",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              1 +
                                                              "contact-country",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.contact.country"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Country")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              contactPerson
                                                                .contact
                                                                .country,
                                                            expression:
                                                              "contactPerson.contact.country",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            1 +
                                                            "contact-country",
                                                          name:
                                                            index +
                                                            1 +
                                                            "country",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            contactPerson
                                                              .contact.country,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              contactPerson.contact,
                                                              "country",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("dt", [
                                                _c(
                                                  "span",
                                                  {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "care4YouApp.contact.communications"
                                                        )
                                                      ),
                                                    },
                                                  },
                                                  [_vm._v("Communications")]
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _vm._l(
                                                contactPerson.contact
                                                  .communications,
                                                function (com, comIndex) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: comIndex,
                                                      staticClass: "row",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-1",
                                                        },
                                                        [
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "align-middle form-control",
                                                            },
                                                            [
                                                              _vm.getIconForCommunicationType(
                                                                com.communicationType
                                                              ) != null
                                                                ? _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: _vm.getIconForCommunicationType(
                                                                          com.communicationType
                                                                        ),
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-3",
                                                        },
                                                        [
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    com.communicationType,
                                                                  expression:
                                                                    "com.communicationType",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                id: "communication-communicationtype",
                                                                disabled:
                                                                  !_vm.isCreate,
                                                                name: "communicationtype",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      com,
                                                                      "communicationType",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.communicationTypeValues,
                                                              function (
                                                                communicationType
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: communicationType,
                                                                    attrs: {
                                                                      label:
                                                                        _vm.$t(
                                                                          "care4YouApp.CommunicationType." +
                                                                            communicationType
                                                                        ),
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        communicationType,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          communicationType
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-3",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  com.contactValue,
                                                                expression:
                                                                  "com.contactValue",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id: "communication-contact",
                                                              readonly:
                                                                !_vm.isCreate,
                                                              name: "contact",
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              value:
                                                                com.contactValue,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  com,
                                                                  "contactValue",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.isCreate
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-1",
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-secondary",
                                                                  attrs: {
                                                                    id: "removeCommunication",
                                                                    type: "button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeCommunication(
                                                                          index,
                                                                          comIndex
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: "trash",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm._v(" "),
                                              _vm.isCreate
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-primary",
                                                      attrs: {
                                                        id: "addCommunication",
                                                        type: "button",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addCommunication(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: { icon: "plus" },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }
                          ),
                          _vm._v(" "),
                          _vm.isCreate
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    id: "addContactPerson",
                                    type: "button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addContactPerson()
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "plus" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("entity.action.add")
                                        ),
                                      },
                                    },
                                    [_vm._v("Add")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            disabled: _vm.isCreate,
                            title: _vm.$t(
                              "care4YouApp.customerDetail.sections.careAssignments"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchTab(3)
                            },
                          },
                        },
                        [
                          _c(
                            "b-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _vm.initialCareAssignmentExistent() &&
                                    !_vm.patientForOverview &&
                                    !_vm.isCreate &&
                                    (_vm.customer.archive == null ||
                                      _vm.customer.archive == false)
                                      ? _c(
                                          "div",
                                          { staticClass: "float-left" },
                                          [
                                            _c("router-link", {
                                              staticClass: "mx-auto",
                                              attrs: {
                                                to: {
                                                  name: "CreateCareAssignments",
                                                  params: {
                                                    customerId: _vm.customer.id,
                                                  },
                                                },
                                                custom: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      navigate,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-success",
                                                            attrs: {
                                                              id: "create-care-assignments",
                                                            },
                                                            on: {
                                                              click: navigate,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  icon: "plus",
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "care4YouApp.customer.home.createCareAssignmentsFromSuggestions"
                                                                      )
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        Create new CareAssignments\n                      "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                4224778856
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.patientForOverview &&
                                    !_vm.isCreate &&
                                    (_vm.customer.archive == null ||
                                      _vm.customer.archive == false)
                                      ? _c(
                                          "div",
                                          { staticClass: "float-left" },
                                          [
                                            _c("router-link", {
                                              staticClass: "mx-auto",
                                              attrs: {
                                                to: {
                                                  name: "CreateCareAssignment",
                                                  params: {
                                                    customerId: _vm.customer.id,
                                                  },
                                                },
                                                custom: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      navigate,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary",
                                                            attrs: {
                                                              id: "create-care-assignments",
                                                            },
                                                            on: {
                                                              click: navigate,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  icon: "plus",
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "care4YouApp.customer.home.createManualCareAssignment"
                                                                      )
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Create manual CareAssignments "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                595287649
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  !_vm.patientForOverview && !_vm.isNewCustomer
                                    ? _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "float-right",
                                              attrs: {
                                                variant: "success",
                                                disabled:
                                                  _vm.isCarerSwitchRunning,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.switchCarer()
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "repeat" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-none d-md-inline",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "care4YouApp.customer.home.switchCarer"
                                                      )
                                                    ),
                                                  },
                                                },
                                                [_vm._v("Switch Carer")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _vm._l(
                            _vm.customer.careAssignments,
                            function (careAssignment, index) {
                              return _c("b-card", {
                                key: careAssignment.id,
                                staticClass: "float-nonemb-2",
                                attrs: { "body-class": "p-0" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-row",
                                            { attrs: { "align-v": "center" } },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    md: "auto",
                                                    cols: "2",
                                                  },
                                                },
                                                [
                                                  _c("b-avatar", {
                                                    attrs: {
                                                      src:
                                                        "data:" +
                                                        careAssignment.carer
                                                          .imageContentType +
                                                        ";base64," +
                                                        careAssignment.carer
                                                          .image,
                                                      size: "6em",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            careAssignment.carer
                                                              .contact
                                                              .lastName +
                                                              ", " +
                                                              careAssignment
                                                                .carer.contact
                                                                .firstName
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm text-muted mb-0",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatDate"
                                                            )(
                                                              careAssignment
                                                                .carer.birthday
                                                            )
                                                          ) +
                                                          "\n                    " +
                                                          _vm._s(
                                                            careAssignment.carer
                                                              .birthday
                                                              ? "(" +
                                                                  _vm.calculateCurrentAge(
                                                                    careAssignment
                                                                      .carer
                                                                      .birthday
                                                                  ) +
                                                                  ")"
                                                              : ""
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.getDateFormatedWithDefault(
                                                              careAssignment.arrivalAt,
                                                              "offen"
                                                            ) +
                                                              " - " +
                                                              _vm.getDateFormatedWithDefault(
                                                                careAssignment.departureAt,
                                                                "offen"
                                                              )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm text-muted mb-0",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          careAssignment.workingDays
                                                            ? careAssignment.workingDays
                                                            : "-"
                                                        ) + " Arbeitstage"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: { cols: "2" },
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-toggle",
                                                          rawName: "v-b-toggle",
                                                          value:
                                                            "accordionAssignment-" +
                                                            index,
                                                          expression:
                                                            "'accordionAssignment-' + index",
                                                        },
                                                      ],
                                                      staticClass: "btn-sm",
                                                      attrs: {
                                                        variant: "primary",
                                                        title:
                                                          _vm.getToggleAccordion(
                                                            "accordionAssignment-" +
                                                              index
                                                          )
                                                            ? _vm.$t(
                                                                "entity.action.close"
                                                              )
                                                            : _vm.$t(
                                                                "entity.action.open"
                                                              ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleAccordion(
                                                            "accordionAssignment-" +
                                                              index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      !_vm.getToggleAccordion(
                                                        "accordionAssignment-" +
                                                          index
                                                      )
                                                        ? _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: "plus",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.getToggleAccordion(
                                                        "accordionAssignment-" +
                                                          index
                                                      )
                                                        ? _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: "minus",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "btn-sm",
                                                      attrs: {
                                                        variant: "danger",
                                                        title: _vm.$t(
                                                          "care4YouApp.careAssignment.terminate.doTermination"
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.terminateCareAssignment(
                                                            careAssignment
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: "xmark",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  !_vm.patientForOverview &&
                                                  (_vm.customer.archive ==
                                                    null ||
                                                    _vm.customer.archive ==
                                                      false)
                                                    ? _c("router-link", {
                                                        attrs: {
                                                          to: {
                                                            name: "EditCareAssignment",
                                                            params: {
                                                              customerId:
                                                                _vm.customer.id,
                                                              careAssignmentId:
                                                                careAssignment.id,
                                                            },
                                                          },
                                                          custom: "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                navigate,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-sm edit",
                                                                      attrs: {
                                                                        variant:
                                                                          "primary",
                                                                        title:
                                                                          _vm.$t(
                                                                            "entity.action.edit"
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          navigate,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              icon: "pencil-alt",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.userHasManagerRole()
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          staticClass: "btn-sm",
                                                          attrs: {
                                                            variant: "danger",
                                                            title: _vm.$t(
                                                              "entity.action.delete"
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteCareAssignment(
                                                                careAssignment
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: "trash",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-collapse",
                                            {
                                              staticClass: "p-3",
                                              attrs: {
                                                id:
                                                  "accordionAssignment-" +
                                                  index,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-6",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              "-care-assignment-arrivalAt",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.careAssignment.arrivalAt"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Arrival At\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "d-flex",
                                                        },
                                                        [
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              staticClass:
                                                                "mb-3",
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-prepend",
                                                                [
                                                                  _c(
                                                                    "b-form-datepicker",
                                                                    {
                                                                      staticClass:
                                                                        "form-control",
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm.isCreate,
                                                                        locale:
                                                                          _vm.currentLanguage,
                                                                        name:
                                                                          index +
                                                                          "-care-assignment-arrivalAt",
                                                                        "aria-controls":
                                                                          "care-assignment-arrivalAt",
                                                                        "button-only":
                                                                          "",
                                                                        "close-button":
                                                                          "",
                                                                        "reset-button":
                                                                          "",
                                                                        "today-button":
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          careAssignment.arrivalAt,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              careAssignment,
                                                                              "arrivalAt",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "careAssignment.arrivalAt",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    id:
                                                                      index +
                                                                      "-care-assignment-arrivalAt",
                                                                    value:
                                                                      _vm.convertDateFromServer(
                                                                        careAssignment.arrivalAt
                                                                      ),
                                                                    disabled:
                                                                      !_vm.isCreate,
                                                                    name:
                                                                      index +
                                                                      "-arrivalAt",
                                                                    type: "text",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.updateDateField(
                                                                          $event,
                                                                          careAssignment,
                                                                          "arrivalAt"
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-6",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              "-care-assignment-departureAt",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.careAssignment.departureAt"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Departure At\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "d-flex",
                                                        },
                                                        [
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              staticClass:
                                                                "mb-3",
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-prepend",
                                                                [
                                                                  _c(
                                                                    "b-form-datepicker",
                                                                    {
                                                                      staticClass:
                                                                        "form-control",
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm.isCreate,
                                                                        locale:
                                                                          _vm.currentLanguage,
                                                                        name:
                                                                          index +
                                                                          "-care-assignment-departureAt",
                                                                        "aria-controls":
                                                                          "care-assignment-departureAt",
                                                                        "button-only":
                                                                          "",
                                                                        "close-button":
                                                                          "",
                                                                        "reset-button":
                                                                          "",
                                                                        "today-button":
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          careAssignment.departureAt,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              careAssignment,
                                                                              "departureAt",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "careAssignment.departureAt",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    id:
                                                                      index +
                                                                      "-care-assignment-departureAt",
                                                                    value:
                                                                      _vm.convertDateFromServer(
                                                                        careAssignment.departureAt
                                                                      ),
                                                                    disabled:
                                                                      !_vm.isCreate,
                                                                    name:
                                                                      index +
                                                                      "-departureAt",
                                                                    type: "text",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.updateDateField(
                                                                          $event,
                                                                          careAssignment,
                                                                          "departureAt"
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-6",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              "-care-assignment-estimatedArrivalAt",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.careAssignment.estimatedArrivalAt"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Arrival At\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "d-flex",
                                                        },
                                                        [
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              staticClass:
                                                                "mb-3",
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-prepend",
                                                                [
                                                                  _c(
                                                                    "b-form-datepicker",
                                                                    {
                                                                      staticClass:
                                                                        "form-control",
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm.isCreate,
                                                                        locale:
                                                                          _vm.currentLanguage,
                                                                        name:
                                                                          index +
                                                                          "-care-assignment-estimatedArrivalAt",
                                                                        "aria-controls":
                                                                          "care-assignment-estimatedArrivalAt",
                                                                        "button-only":
                                                                          "",
                                                                        "close-button":
                                                                          "",
                                                                        "reset-button":
                                                                          "",
                                                                        "today-button":
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          careAssignment.estimatedArrivalAt,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              careAssignment,
                                                                              "estimatedArrivalAt",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "careAssignment.estimatedArrivalAt",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    id:
                                                                      index +
                                                                      "-care-assignment-estimatedArrivalAt",
                                                                    value:
                                                                      _vm.convertDateFromServer(
                                                                        careAssignment.estimatedArrivalAt
                                                                      ),
                                                                    disabled:
                                                                      !_vm.isCreate,
                                                                    name:
                                                                      index +
                                                                      "-estimatedArrivalAt",
                                                                    type: "text",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.updateDateField(
                                                                          $event,
                                                                          careAssignment,
                                                                          "estimatedArrivalAt"
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-6",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for: "care-assignment-workingDays",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.careAssignment.workingDays"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Working Days\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName:
                                                              "v-model.number",
                                                            value:
                                                              careAssignment.workingDays,
                                                            expression:
                                                              "careAssignment.workingDays",
                                                            modifiers: {
                                                              number: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "number",
                                                          name:
                                                            index +
                                                            "-workingDays",
                                                          id:
                                                            index +
                                                            "-care-assignment-workingDays",
                                                          readonly:
                                                            !_vm.isCreate,
                                                        },
                                                        domProps: {
                                                          value:
                                                            careAssignment.workingDays,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              careAssignment,
                                                              "workingDays",
                                                              _vm._n(
                                                                $event.target
                                                                  .value
                                                              )
                                                            )
                                                          },
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.$forceUpdate()
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-6",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              "-arrivalData-assignment-data-protocol",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.careAssignment.arrivalProtocol"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Protocol\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("textarea", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              careAssignment.arrivalProtocol,
                                                            expression:
                                                              "careAssignment.arrivalProtocol",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            "-arrivalData-assignment-data-protocol",
                                                          name:
                                                            index +
                                                            "-arrivalData-protocol",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          rows: "5",
                                                        },
                                                        domProps: {
                                                          value:
                                                            careAssignment.arrivalProtocol,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              careAssignment,
                                                              "arrivalProtocol",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-6",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for:
                                                              index +
                                                              "-departureData-assignment-data-protocol",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.careAssignment.departureProtocol"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Protocol\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("textarea", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              careAssignment.departureProtocol,
                                                            expression:
                                                              "careAssignment.departureProtocol",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            index +
                                                            "-departureData-assignment-data-protocol",
                                                          name:
                                                            index +
                                                            "-departureData-protocol",
                                                          readonly:
                                                            !_vm.isCreate,
                                                          rows: "5",
                                                        },
                                                        domProps: {
                                                          value:
                                                            careAssignment.departureProtocol,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              careAssignment,
                                                              "departureProtocol",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            disabled: _vm.isCreate,
                            title: _vm.$t(
                              "care4YouApp.customerDetail.sections.salary"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchTab(4)
                            },
                          },
                        },
                        [
                          !_vm.patientForOverview &&
                          _vm.isSalaryEditable() &&
                          _vm.customer.archive != true
                            ? _c(
                                "div",
                                { staticClass: "row mb-2" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal",
                                          value: "editSalary",
                                          expression: "'editSalary'",
                                        },
                                      ],
                                      staticClass: "btn mx-auto",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getNewSalary()
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "plus" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.salary.home.createLabel"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v(" Create Salary ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.salaries.length == 0
                            ? _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "care4YouApp.customerDetail.notExisting.salaries"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.salaries && _vm.salaries.length > 0
                            ? _c("div", { staticClass: "table-responsive" }, [
                                _c(
                                  "table",
                                  {
                                    staticClass: "table table-striped",
                                    attrs: { "aria-describedby": "salaries" },
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _c(
                                            "span",
                                            {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.salary.amount"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("Amount")]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _c(
                                            "span",
                                            {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.salary.validFrom"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("Valid From")]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _c(
                                            "span",
                                            {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.salary.text"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("Text")]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("th", { attrs: { scope: "row" } }),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.salaries,
                                        function (salary, index) {
                                          return _c("tr", { key: index }, [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    salary.amount
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    salary.validFrom
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(_vm._s(salary.text)),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "btn-group" },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm",
                                                        attrs: {
                                                          disabled:
                                                            _vm.customer
                                                              .archive == true,
                                                          variant: "danger",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.confirmSalaryRemoval(
                                                              salary,
                                                              index,
                                                              _vm.afterSalaryRemoval
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: "trash",
                                                            },
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "d-none d-md-inline",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "entity.action.delete"
                                                                  )
                                                                ),
                                                            },
                                                          },
                                                          [_vm._v("Delete")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "b-modal",
                                                            rawName:
                                                              "v-b-modal.editSalary",
                                                            modifiers: {
                                                              editSalary: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass:
                                                          "btn btn-sm",
                                                        attrs: {
                                                          disabled:
                                                            _vm.customer
                                                              .archive == true,
                                                          variant: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.prepareEditSalary(
                                                              salary,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: "pencil-alt",
                                                            },
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "d-none d-md-inline",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "entity.action.edit"
                                                                  )
                                                                ),
                                                            },
                                                          },
                                                          [_vm._v("Edit")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            title: _vm.$t(
                              "care4YouApp.customerDetail.sections.documents"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchTab(5)
                            },
                          },
                        },
                        [
                          _vm.documents.length == 0
                            ? _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "care4YouApp.customerDetail.notExisting.documents"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            _vm._l(_vm.documents, function (document, index) {
                              return _c(
                                "b-col",
                                { key: index, attrs: { md: "4" } },
                                [
                                  _c("b-card", {
                                    staticClass: "mb-2",
                                    attrs: { tag: "article" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function () {
                                            return [
                                              _c(
                                                "h4",
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        document.documentName
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: _vm.getIconForDocType(
                                                        document.documentBinariesContentType
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          document.documentBinariesContentType,
                                                        expression:
                                                          "document.documentBinariesContentType",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id:
                                                        "document-documentBinariesContentType" +
                                                        index,
                                                      name:
                                                        "documentBinariesContentType" +
                                                        index,
                                                      type: "hidden",
                                                    },
                                                    domProps: {
                                                      value:
                                                        document.documentBinariesContentType,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          document,
                                                          "documentBinariesContentType",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "form-control-label",
                                                      attrs: {
                                                        for: "document-documentType",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "care4YouApp.document.documentType"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Document Type\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            document.documentType,
                                                          expression:
                                                            "document.documentType",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "document-documentType",
                                                        disabled:
                                                          _vm.patientForOverview ||
                                                          _vm.customer
                                                            .archive == true,
                                                        name: "documentType",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            document,
                                                            "documentType",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.documentTypeValues,
                                                      function (documentType) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: documentType,
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "care4YouApp.DocumentType." +
                                                                  documentType
                                                              ),
                                                            },
                                                            domProps: {
                                                              value:
                                                                documentType,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  documentType
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "form-control-label",
                                                      attrs: {
                                                        for: "document-documentStatus",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "care4YouApp.document.documentStatus"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Document Status\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            document.documentStatus,
                                                          expression:
                                                            "document.documentStatus",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "document-documentStatus",
                                                        disabled:
                                                          _vm.patientForOverview ||
                                                          _vm.customer
                                                            .archive == true,
                                                        name: "documentStatus",
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.$set(
                                                              document,
                                                              "documentStatus",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                          function ($event) {
                                                            return _vm.setChangedAt(
                                                              document
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.documentStatusValues,
                                                      function (
                                                        documentStatus
                                                      ) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: documentStatus,
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "care4YouApp.DocumentStatus." +
                                                                  documentStatus
                                                              ),
                                                            },
                                                            domProps: {
                                                              value:
                                                                documentStatus,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  documentStatus
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "form-control-label",
                                                      attrs: {
                                                        for:
                                                          "document_file" +
                                                          index,
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "care4YouApp.document.statusChangedAt"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Status Changed At\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  document.statusChangedAt
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$d(
                                                              Date.parse(
                                                                document.statusChangedAt
                                                              ),
                                                              "short"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !document.statusChangedAt
                                                    ? _c("p", [_vm._v("-")])
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "form-control-label",
                                                      attrs: {
                                                        for:
                                                          "document_file" +
                                                          index,
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "care4YouApp.document.createdAt"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Created At\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  document.createdAt
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$d(
                                                              Date.parse(
                                                                document.createdAt
                                                              ),
                                                              "short"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !document.createdAt
                                                    ? _c("p", [_vm._v("-")])
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: { variant: "info" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.downloadFile(
                                                        document.documentBinariesContentType,
                                                        document.documentBinaries,
                                                        document.documentName
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: { icon: "download" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "entity.action.download"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [_vm._v("Download")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: { variant: "info" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openFile(
                                                        document.documentBinariesContentType,
                                                        document.documentBinaries
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: { icon: "eye" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "entity.action.open"
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [_vm._v("Download")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              !_vm.patientForOverview &&
                                              !_vm.isCreate &&
                                              (_vm.customer.archive == null ||
                                                _vm.customer.archive == false)
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant: "save",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.safeFile(
                                                            document
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: { icon: "save" },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "entity.action.save"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Save")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.patientForOverview &&
                                              !_vm.isCreate &&
                                              (_vm.customer.archive == null ||
                                                _vm.customer.archive == false)
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant: "delete",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteFile(
                                                            document
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: "trash",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "entity.action.delete"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Delete")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.patientForOverview &&
                                              _vm.isCreate
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant: "delete",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteFileByIndex(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: "trash",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "entity.action.delete"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Delete")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          !_vm.patientForOverview &&
                          (_vm.customer.archive == null ||
                            _vm.customer.archive == false)
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _c("b-card", {
                                        staticClass: "mb-2",
                                        attrs: { tag: "article" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "h4",
                                                    [
                                                      _vm._v(
                                                        "\n                    Neues Dokument\n                    "
                                                      ),
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: _vm.getIconForDocType(
                                                            _vm.newDocument
                                                              .documentBinariesContentType
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for: "document_file_new",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "global.menu.entities.document"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v(" File ")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        ref: "document_file_new",
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "document_file_new",
                                                          accept: "*",
                                                          type: "file",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.setFileData(
                                                              $event,
                                                              _vm.newDocument,
                                                              "documentBinaries",
                                                              false
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.newDocument
                                                                .documentBinaries,
                                                            expression:
                                                              "newDocument.documentBinaries",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "document-documentBinaries_new",
                                                          name: "binaries_new",
                                                          type: "hidden",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.newDocument
                                                              .documentBinaries,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.newDocument,
                                                              "documentBinaries",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.newDocument
                                                                .documentBinariesContentType,
                                                            expression:
                                                              "newDocument.documentBinariesContentType",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "document-documentBinariesContentType_new",
                                                          name: "documentBinariesContentType_new",
                                                          type: "hidden",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.newDocument
                                                              .documentBinariesContentType,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.newDocument,
                                                              "documentBinariesContentType",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for: "document-documentName_new",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.document.documentName"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Document Name\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.newDocument
                                                                .documentName,
                                                            expression:
                                                              "newDocument.documentName",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "document-documentName_new",
                                                          name: "name_new",
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.newDocument
                                                              .documentName,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.newDocument,
                                                              "documentName",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for: "document_new-documentType",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.document.documentType"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Document Type\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.newDocument
                                                                  .documentType,
                                                              expression:
                                                                "newDocument.documentType",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            id: "document_new-documentType",
                                                            name: "documentType",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm.newDocument,
                                                                "documentType",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.documentTypeValues,
                                                          function (
                                                            documentType
                                                          ) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: documentType,
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "care4YouApp.DocumentType." +
                                                                      documentType
                                                                  ),
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    documentType,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      documentType
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-control-label",
                                                          attrs: {
                                                            for: "document_new-documentStatus",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "care4YouApp.document.documentStatus"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Document Status\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.newDocument
                                                                  .documentStatus,
                                                              expression:
                                                                "newDocument.documentStatus",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            id: "document_new-documentStatus",
                                                            name: "documentStatus",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm.newDocument,
                                                                "documentStatus",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.documentStatusValues,
                                                          function (
                                                            documentStatus
                                                          ) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: documentStatus,
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "care4YouApp.DocumentStatus." +
                                                                      documentStatus
                                                                  ),
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    documentStatus,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      documentStatus
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          !_vm.validDataForNewDoc(),
                                                        variant: "info",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addNewFile()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: { icon: "plus" },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "entity.action.add"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Add")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          542904395
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            disabled: _vm.isCreate,
                            title: _vm.$t(
                              "care4YouApp.customerDetail.sections.invoices"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchTab(6)
                            },
                          },
                        },
                        [
                          _vm.isInvoiceEditable() &&
                          (_vm.customer.archive == null ||
                            _vm.customer.archive == false)
                            ? _c(
                                "div",
                                { staticClass: "row mb-2" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal",
                                          value: "editInvoice",
                                          expression: "'editInvoice'",
                                        },
                                      ],
                                      staticClass: "btn mx-auto",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getNewInvoice()
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "plus" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.customer.home.createManualInvoice"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v(" Create manual Invoice ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.invoices.length == 0
                            ? _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "care4YouApp.customerDetail.notExisting.invoices"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.invoices && _vm.invoices.length > 0
                            ? _c("div", { staticClass: "table-responsive" }, [
                                _c(
                                  "table",
                                  {
                                    staticClass: "table table-striped",
                                    attrs: { "aria-describedby": "invoices" },
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeOrder(
                                                  "invoiceDate"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.invoice.invoiceDate_short"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Invoice Date")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.propOrderInvoice,
                                                "field-name": "invoiceDate",
                                                reverse: _vm.reverseInvoice,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeOrder(
                                                  "invoiceNumber"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.invoice.invoiceNumber_short"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("ID")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.propOrderInvoice,
                                                "field-name": "invoiceNumber",
                                                reverse: _vm.reverseInvoice,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeOrder(
                                                  "calculatedFrom"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.invoice.calculatedFrom_short"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Calculated Fromo")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.propOrderInvoice,
                                                "field-name": "calculatedFrom",
                                                reverse: _vm.reverseInvoice,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeOrder(
                                                  "calculatedTo"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.invoice.calculatedTo_short"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Calculated To")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.propOrderInvoice,
                                                "field-name": "calculatedTo",
                                                reverse: _vm.reverseInvoice,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeOrder("amount")
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.invoice.amount"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Amount")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.propOrderInvoice,
                                                "field-name": "amount",
                                                reverse: _vm.reverseInvoice,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeOrder("isSent")
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.invoice.isSent"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Is Sent")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.propOrderInvoice,
                                                "field-name": "isSent",
                                                reverse: _vm.reverseInvoice,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeOrder("isPaid")
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.invoice.isPaid"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Is Paid")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.propOrderInvoice,
                                                "field-name": "isPaid",
                                                reverse: _vm.reverseInvoice,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeOrder(
                                                  "isUnneeded"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.invoice.isUnneeded"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Is Paid")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.propOrderInvoice,
                                                "field-name": "isUnneeded",
                                                reverse: _vm.reverseInvoice,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("th", { attrs: { scope: "row" } }),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.invoices,
                                        function (invoice, index) {
                                          return _c("tr", { key: index }, [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    invoice.invoiceDate
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(invoice.invoiceNumber)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    invoice.calculatedFrom
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    invoice.calculatedTo
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    invoice.amount
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("b-form-checkbox", {
                                                  attrs: {
                                                    id: "isSent" + index,
                                                    disabled:
                                                      _vm.customer.archive ==
                                                      true,
                                                    name: "isSent" + index,
                                                    size: "lg",
                                                    switch: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.updateInvoice(
                                                        invoice,
                                                        _vm.mergeUpdatedInvoice
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: invoice.isSent,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        invoice,
                                                        "isSent",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "invoice.isSent",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("b-form-checkbox", {
                                                  attrs: {
                                                    id: "isPaid" + index,
                                                    disabled:
                                                      _vm.customer.archive ==
                                                      true,
                                                    name: "isPaid" + index,
                                                    size: "lg",
                                                    switch: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.updateInvoice(
                                                        invoice,
                                                        _vm.mergeUpdatedInvoice
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: invoice.isPaid,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        invoice,
                                                        "isPaid",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "invoice.isPaid",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("b-form-checkbox", {
                                                  attrs: {
                                                    id: "isUnneeded" + index,
                                                    disabled:
                                                      _vm.customer.archive ==
                                                      true,
                                                    name: "isUnneeded" + index,
                                                    size: "lg",
                                                    switch: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.updateInvoice(
                                                        invoice,
                                                        _vm.mergeUpdatedInvoice
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: invoice.isUnneeded,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        invoice,
                                                        "isUnneeded",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "invoice.isUnneeded",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "btn-group" },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm",
                                                        attrs: {
                                                          variant: "info",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.generateInvoiceDocument(
                                                              invoice
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: "gear",
                                                            },
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "d-none d-md-inline",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "entity.action.generate"
                                                                  )
                                                                ),
                                                            },
                                                          },
                                                          [_vm._v("Generate")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "b-modal",
                                                            rawName:
                                                              "v-b-modal.editInvoice",
                                                            modifiers: {
                                                              editInvoice: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass:
                                                          "btn btn-sm",
                                                        attrs: {
                                                          disabled:
                                                            _vm.customer
                                                              .archive == true,
                                                          variant: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.prepareEditInvoice(
                                                              invoice,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: "pencil-alt",
                                                            },
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "d-none d-md-inline",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "entity.action.edit"
                                                                  )
                                                                ),
                                                            },
                                                          },
                                                          [_vm._v("Edit")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c("b-tab", {
                        attrs: {
                          disabled: _vm.isCreate,
                          title: _vm.$t(
                            "care4YouApp.customerDetail.sections.mails"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.switchTab(7)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            disabled: _vm.isCreate,
                            title: _vm.$t(
                              "care4YouApp.customerDetail.sections.activities"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchTab(8)
                            },
                          },
                        },
                        [
                          !_vm.patientForOverview &&
                          _vm.isActivityEditable() &&
                          _vm.customer.archive != true
                            ? _c(
                                "div",
                                { staticClass: "row mb-2" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal",
                                          value: "editActivity",
                                          expression: "'editActivity'",
                                        },
                                      ],
                                      staticClass: "btn mx-auto",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getNewActivity()
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "plus" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "care4YouApp.activity.home.createLabel"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v(" Create Activity ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.activities.length == 0
                            ? _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "care4YouApp.customerDetail.notExisting.activities"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.activities && _vm.activities.length > 0
                            ? _c("div", { staticClass: "table-responsive" }, [
                                _c(
                                  "table",
                                  {
                                    staticClass: "table table-striped",
                                    attrs: { "aria-describedby": "activities" },
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "col-1 text-center",
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeActivityOrder(
                                                  "type"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.activity.type"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Type")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.activityPropOrder,
                                                "field-name": "type",
                                                reverse: _vm.activityReverse,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "col-6",
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeActivityOrder(
                                                  "text"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.activity.text"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Text")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.activityPropOrder,
                                                "field-name": "text",
                                                reverse: _vm.activityReverse,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "col-2",
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeActivityOrder(
                                                  "createdAt"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.activity.createdAt"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Created At")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.activityPropOrder,
                                                "field-name": "createdAt",
                                                reverse: _vm.activityReverse,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "col-2",
                                            attrs: { scope: "row" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeActivityOrder(
                                                  "updatedAt"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.activity.updatedAt"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Updated At")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.activityPropOrder,
                                                "field-name": "updatedAt",
                                                reverse: _vm.activityReverse,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("th", {
                                          staticClass: "col-1",
                                          attrs: { scope: "row" },
                                        }),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.activities,
                                        function (activity, index) {
                                          return _c("tr", { key: index }, [
                                            _c("td", [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "align-middle text-center",
                                                },
                                                [
                                                  _vm.getActivityTypeIcon(
                                                    activity.type
                                                  ) != null
                                                    ? _c("font-awesome-icon", {
                                                        attrs: {
                                                          size: "xs",
                                                          title: _vm.$t(
                                                            "care4YouApp.ActivityType." +
                                                              activity.type
                                                          ),
                                                          icon: _vm.getActivityTypeIcon(
                                                            activity.type
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(_vm._s(activity.text)),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDateTime")(
                                                    activity.createdAt
                                                  )
                                                )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                _vm._s(activity.createdBy)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDateTime")(
                                                    activity.updatedAt
                                                  )
                                                )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                _vm._s(activity.updatedBy)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                !_vm.isNewCustomerActivity(
                                                  activity
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "btn-group",
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "b-modal",
                                                                rawName:
                                                                  "v-b-modal.editActivity",
                                                                modifiers: {
                                                                  editActivity: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass:
                                                              "btn btn-sm",
                                                            attrs: {
                                                              disabled:
                                                                _vm.customer
                                                                  .archive ==
                                                                true,
                                                              variant:
                                                                "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.prepareEditActivity(
                                                                  activity,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    _vm.$t(
                                                                      "entity.action.edit"
                                                                    ),
                                                                  icon: "pencil-alt",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "btn btn-sm",
                                                            attrs: {
                                                              disabled:
                                                                _vm.customer
                                                                  .archive ==
                                                                true,
                                                              variant: "danger",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.confirmActivityRemoval(
                                                                  activity,
                                                                  index,
                                                                  _vm.afterActivityRemoval
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  title: _vm.$t(
                                                                    "entity.action.delete"
                                                                  ),
                                                                  icon: "trash",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            disabled: _vm.isCreate,
                            title: _vm.$t(
                              "care4YouApp.customerDetail.sections.user-tasks"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchTab(9)
                            },
                          },
                        },
                        [
                          _vm.userTasks.length == 0
                            ? _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "care4YouApp.customerDetail.notExisting.user-tasks"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.userTasks && _vm.userTasks.length > 0
                            ? _c("div", [
                                _c(
                                  "table",
                                  {
                                    staticClass: "table table-striped",
                                    attrs: { "aria-describedby": "user-tasks" },
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "col-1",
                                            attrs: { scope: "column" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeUserTaskOrder(
                                                  "id"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("global.field.id")
                                                  ),
                                                },
                                              },
                                              [_vm._v("Type")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.customerUserTaskPropOrder,
                                                "field-name": "id",
                                                reverse:
                                                  _vm.customerUserTaskReverse,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "col-5",
                                            attrs: { scope: "column" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeUserTaskOrder(
                                                      "title"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "care4YouApp.bpmUserTask.title"
                                                        )
                                                      ),
                                                    },
                                                  },
                                                  [_vm._v("Type")]
                                                ),
                                                _vm._v(" "),
                                                _c("jhi-sort-indicator", {
                                                  attrs: {
                                                    "current-order":
                                                      _vm.customerUserTaskPropOrder,
                                                    "field-name": "title",
                                                    reverse:
                                                      _vm.customerUserTaskReverse,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeUserTaskOrder(
                                                      "description"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "care4YouApp.bpmUserTask.description"
                                                        )
                                                      ),
                                                    },
                                                  },
                                                  [_vm._v("Type")]
                                                ),
                                                _vm._v(" "),
                                                _c("jhi-sort-indicator", {
                                                  attrs: {
                                                    "current-order":
                                                      _vm.customerUserTaskPropOrder,
                                                    "field-name": "description",
                                                    reverse:
                                                      _vm.customerUserTaskReverse,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "col-2",
                                            attrs: { scope: "column" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeUserTaskOrder(
                                                      "user"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "care4YouApp.bpmUserTask.user"
                                                        )
                                                      ),
                                                    },
                                                  },
                                                  [_vm._v("Type")]
                                                ),
                                                _vm._v(" "),
                                                _c("jhi-sort-indicator", {
                                                  attrs: {
                                                    "current-order":
                                                      _vm.customerUserTaskPropOrder,
                                                    "field-name": "user",
                                                    reverse:
                                                      _vm.customerUserTaskReverse,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeUserTaskOrder(
                                                      "userGroup"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "care4YouApp.bpmUserTask.userGroup"
                                                        )
                                                      ),
                                                    },
                                                  },
                                                  [_vm._v("Type")]
                                                ),
                                                _vm._v(" "),
                                                _c("jhi-sort-indicator", {
                                                  attrs: {
                                                    "current-order":
                                                      _vm.customerUserTaskPropOrder,
                                                    "field-name": "userGroup",
                                                    reverse:
                                                      _vm.customerUserTaskReverse,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "col-2",
                                            attrs: { scope: "column" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeUserTaskOrder(
                                                      "created"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "care4YouApp.bpmUserTask.created"
                                                        )
                                                      ),
                                                    },
                                                  },
                                                  [_vm._v("Type")]
                                                ),
                                                _vm._v(" "),
                                                _c("jhi-sort-indicator", {
                                                  attrs: {
                                                    "current-order":
                                                      _vm.customerUserTaskPropOrder,
                                                    "field-name": "created",
                                                    reverse:
                                                      _vm.customerUserTaskReverse,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeUserTaskOrder(
                                                      "dueDate"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "care4YouApp.bpmUserTask.dueDate"
                                                        )
                                                      ),
                                                    },
                                                  },
                                                  [_vm._v("Type")]
                                                ),
                                                _vm._v(" "),
                                                _c("jhi-sort-indicator", {
                                                  attrs: {
                                                    "current-order":
                                                      _vm.customerUserTaskPropOrder,
                                                    "field-name": "dueDate",
                                                    reverse:
                                                      _vm.customerUserTaskReverse,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeUserTaskOrder(
                                                      "finished"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "care4YouApp.bpmUserTask.finished"
                                                        )
                                                      ),
                                                    },
                                                  },
                                                  [_vm._v("Type")]
                                                ),
                                                _vm._v(" "),
                                                _c("jhi-sort-indicator", {
                                                  attrs: {
                                                    "current-order":
                                                      _vm.customerUserTaskPropOrder,
                                                    "field-name": "finished",
                                                    reverse:
                                                      _vm.customerUserTaskReverse,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "col-1",
                                            attrs: { scope: "column" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeUserTaskOrder(
                                                  "state"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.bpmUserTask.state"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("Type")]
                                            ),
                                            _vm._v(" "),
                                            _c("jhi-sort-indicator", {
                                              attrs: {
                                                "current-order":
                                                  _vm.customerUserTaskPropOrder,
                                                "field-name": "state",
                                                reverse:
                                                  _vm.customerUserTaskReverse,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("th", {
                                          staticClass: "col-1",
                                          attrs: { scope: "column" },
                                        }),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.userTasks,
                                        function (task, index) {
                                          return _c("tr", { key: index }, [
                                            _c("td", [
                                              _vm._v(_vm._s(task.userTask.id)),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "font-italic",
                                                  staticStyle: {
                                                    "text-decoration":
                                                      "underline",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        task.userTask.title + ""
                                                      )
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(task.userTask.title)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.getTaskDescriptionPart1(
                                                            task.userTask
                                                              .description,
                                                            _vm.currentLanguage
                                                          )
                                                        ),
                                                      },
                                                    },
                                                    [_vm._v("description")]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.getTaskDescriptionPart2(
                                                    task.userTask.description,
                                                    _vm.currentLanguage
                                                  ) != null
                                                    ? _c("font-awesome-icon", {
                                                        attrs: {
                                                          title:
                                                            _vm.getTaskDescriptionPart2(
                                                              task.userTask
                                                                .description,
                                                              _vm.currentLanguage
                                                            ),
                                                          icon: "circle-info",
                                                          size: "lg",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    task.userTask.user
                                                      ? task.userTask.user
                                                      : "-"
                                                  ) +
                                                  "\n                    "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    task.userTask.userGroup
                                                      ? _vm.$t(
                                                          "care4YouApp.userGroup.values." +
                                                            task.userTask
                                                              .userGroup
                                                        )
                                                      : "-"
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDateTimeOrDefault(
                                                      task.userTask.created,
                                                      "-"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDateOrDefault(
                                                      task.userTask.dueDate,
                                                      "-"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDateTimeOrDefault(
                                                      task.userTask.finished,
                                                      "-"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "care4YouApp.BpmTaskState." +
                                                        task.userTask.state
                                                    )
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(task.userTask.state)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "btn-group" },
                                                  [
                                                    _vm.isTaskEditable(task) &&
                                                    !_vm.userTaskId
                                                      ? _c("router-link", {
                                                          attrs: {
                                                            to: {
                                                              name: task
                                                                .userTask
                                                                .taskType,
                                                              params: {
                                                                taskId:
                                                                  task.userTask
                                                                    .id,
                                                              },
                                                            },
                                                            custom: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  navigate,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-info btn-sm details",
                                                                        on: {
                                                                          click:
                                                                            navigate,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "pencil-alt",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "d-none d-md-inline",
                                                                            domProps:
                                                                              {
                                                                                textContent:
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "care4YouApp.postbox.handleTask"
                                                                                    )
                                                                                  ),
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Handle"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.isTaskDataEditable(
                                                      task.userTask
                                                    ) && !_vm.userTaskId
                                                      ? _c("router-link", {
                                                          attrs: {
                                                            to: {
                                                              name: "EditTaskData",
                                                              params: {
                                                                taskId:
                                                                  task.userTask
                                                                    .id,
                                                              },
                                                            },
                                                            custom: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  navigate,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-sm",
                                                                        attrs: {
                                                                          variant:
                                                                            "light",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            navigate,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                alt: _vm.$tc(
                                                                                  "care4YouApp.postbox.editTaskData"
                                                                                ),
                                                                                title:
                                                                                  _vm.$tc(
                                                                                    "care4YouApp.postbox.editTaskData"
                                                                                  ),
                                                                                icon: "pencil-alt",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.userTasks && _vm.userTasks.length > 0
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "row justify-content-center" },
                                  [
                                    _c("jhi-item-count", {
                                      attrs: {
                                        itemsPerPage: _vm.userTasksItemsPerPage,
                                        page: _vm.customerUserTaskPage,
                                        total: _vm.customerUserTaskTotalItems,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "row justify-content-center" },
                                  [
                                    _c("b-pagination", {
                                      attrs: {
                                        change:
                                          _vm.loadUserTasksForCustomerPage(),
                                        "per-page": _vm.userTasksItemsPerPage,
                                        "total-rows":
                                          _vm.customerUserTaskTotalItems,
                                        size: "md",
                                      },
                                      model: {
                                        value: _vm.customerUserTaskPage,
                                        callback: function ($$v) {
                                          _vm.customerUserTaskPage = $$v
                                        },
                                        expression: "customerUserTaskPage",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", [
                    (!_vm.paramsForUserTask && !_vm.patientForOverview) ||
                    _vm.isCreate
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: {
                              id: "cancel-save",
                              "data-cy": "entityCreateCancelButton",
                              type: "button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.previousState()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", { attrs: { icon: "ban" } }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.action.back")
                                  ),
                                },
                              },
                              [_vm._v("Back")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.paramsForUserTask &&
                    !_vm.patientForOverview &&
                    _vm.isCreate &&
                    _vm.customer.archive != true
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              id: "save-entity",
                              disabled: _vm.isSaving,
                              type: "button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.save()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "save" },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.action.save")
                                  ),
                                },
                              },
                              [_vm._v("Save")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.paramsForUserTask &&
                    !_vm.patientForOverview &&
                    !_vm.isCreate &&
                    _vm.customer.archive != null &&
                    _vm.customer.archive == true
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              id: "reactivate",
                              disabled: _vm.isSaving,
                              type: "button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.reactivateCustomer()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "rotate-left" },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.action.reactivate")
                                  ),
                                },
                              },
                              [_vm._v("Reactivate")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.paramsForUserTask &&
                    !_vm.patientForOverview &&
                    !_vm.isCreate &&
                    (_vm.customer.archive == null ||
                      _vm.customer.archive == false)
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              id: "archive",
                              disabled: _vm.isSaving,
                              type: "button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.archiveCustomer()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "archive" },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.action.archive")
                                  ),
                                },
                              },
                              [_vm._v("Archive")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.patientForOverview && _vm.taskId
                ? _c(
                    "div",
                    [
                      _c(
                        "b-container",
                        { staticClass: "fixed-bottom", attrs: { fluid: "" } },
                        [
                          _c(
                            "b-row",
                            {
                              class: _vm.isActionMenuOpen
                                ? "bg-dark py-2"
                                : "py-2",
                              attrs: { "align-h": "end" },
                            },
                            [
                              _vm.isActionMenuOpen
                                ? _c(
                                    "b-col",
                                    [
                                      _vm.acceptButtonVisible()
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass: "float-right",
                                              attrs: {
                                                disabled: _vm.isLoadingAll(),
                                                variant: "success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.complete()
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "check" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-none d-md-inline",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "care4YouApp.usertask.complete"
                                                      )
                                                    ),
                                                  },
                                                },
                                                [_vm._v("Complete")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isActionMenuOpen
                                ? _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "float-right",
                                          attrs: {
                                            disabled: _vm.isLoadingAll(),
                                            variant: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveTask()
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "save" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "d-none d-md-inline",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.usertask.save"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("Save")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isActionMenuOpen
                                ? _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "float-right",
                                          attrs: {
                                            disabled: _vm.isLoadingAll(),
                                            variant: "warning",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.previousState()
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "xmark" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "d-none d-md-inline",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.usertask.abort"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("Abort")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isActionMenuOpen
                                ? _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "float-right",
                                          attrs: {
                                            disabled: _vm.isLoadingAll(),
                                            variant: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.completeWithNoInterest()
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "times" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "d-none d-md-inline",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "care4YouApp.usertask.completeNoInterest"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("No Interest")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "float-right",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleActionMenu()
                                        },
                                      },
                                    },
                                    [
                                      !_vm.isActionMenuOpen
                                        ? _c("font-awesome-icon", {
                                            attrs: { icon: "bars" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isActionMenuOpen
                                        ? _c("font-awesome-icon", {
                                            attrs: { icon: "xmark" },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isNoInterestQuestionPopUpOpen
                ? _c(
                    "b-modal",
                    {
                      ref: "noInterestQuestionPopUp",
                      attrs: { id: "noInterestQuestionPopUp", size: "xl" },
                      model: {
                        value: _vm.isNoInterestQuestionPopUpOpen,
                        callback: function ($$v) {
                          _vm.isNoInterestQuestionPopUpOpen = $$v
                        },
                        expression: "isNoInterestQuestionPopUpOpen",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "modal-title" }, slot: "modal-title" },
                        [
                          _c(
                            "span",
                            {
                              attrs: {
                                id: "care4YouApp.customer.noInterestPopUp.title",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "care4YouApp.customer.noInterestPopUp.title"
                                  )
                                ),
                              },
                            },
                            [_vm._v("Answer for no interest")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-body" }, [
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "no-interest-answer" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "care4YouApp.customer.noInterestPopUp.answer"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Answer")]
                              ),
                              _vm._v(" "),
                              _c("b-form-textarea", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "no-interest-answer",
                                  "max-rows": "10",
                                  name: "text",
                                  rows: "6",
                                  type: "text",
                                  placeholder: _vm.$t(
                                    "care4YouApp.customer.noInterestPopUp.answer"
                                  ),
                                },
                                model: {
                                  value: _vm.notInterestPopUpAnswer,
                                  callback: function ($$v) {
                                    _vm.notInterestPopUpAnswer = $$v
                                  },
                                  expression: "notInterestPopUpAnswer",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          attrs: { slot: "modal-footer" },
                          slot: "modal-footer",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.cancel")
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeNoInterestPopUp()
                                },
                              },
                            },
                            [_vm._v("\n          Cancel\n        ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                id: "confirm-no-interest-popup",
                                type: "button",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "care4YouApp.customer.noInterestPopUp.confirm"
                                  )
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmNoInterestPopUp()
                                },
                              },
                            },
                            [_vm._v("\n          Confirm\n        ")]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editInvoice
                ? _c(
                    "b-modal",
                    {
                      ref: "editInvoice",
                      attrs: { id: "editInvoice", size: "xl" },
                      model: {
                        value: _vm.showEditInvoice,
                        callback: function ($$v) {
                          _vm.showEditInvoice = $$v
                        },
                        expression: "showEditInvoice",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "modal-title" }, slot: "modal-title" },
                        [
                          _c(
                            "span",
                            {
                              attrs: {
                                id: "care4YouApp.invoice.edit.question",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.edit.title")
                                ),
                              },
                            },
                            [_vm._v("Edit invoice details")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-body" }, [
                        _c("div", [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "form-group col-6" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "invoice-customerID" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.entities.customer")
                                    ),
                                  },
                                },
                                [_vm._v("Customer ID")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "invoice-customerID",
                                  disabled: !_vm.isCreate,
                                  name: "customerID",
                                  type: "text",
                                },
                                domProps: {
                                  value: _vm.getCustomerText(_vm.editInvoice),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group col-6" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "invoice-amount" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("care4YouApp.invoice.amount")
                                    ),
                                  },
                                },
                                [_vm._v("Amount")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.editInvoice.amount,
                                    expression: "editInvoice.amount",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "invoice-amount",
                                  name: "amount",
                                  type: "number",
                                },
                                domProps: { value: _vm.editInvoice.amount },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.editInvoice,
                                      "amount",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-4" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    attrs: { for: "invoice-invoiceDate" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "care4YouApp.invoice.invoiceDate"
                                        )
                                      ),
                                    },
                                  },
                                  [_vm._v("Invoice Date")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-input-group",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "b-input-group-prepend",
                                      [
                                        _c("b-form-datepicker", {
                                          staticClass: "form-control",
                                          attrs: {
                                            locale: _vm.currentLanguage,
                                            "aria-controls":
                                              "invoice-invoiceDate",
                                            "button-only": "",
                                            "close-button": "",
                                            name: "invoiceDate",
                                            "reset-button": "",
                                            "today-button": "",
                                          },
                                          model: {
                                            value: _vm.editInvoice.invoiceDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editInvoice,
                                                "invoiceDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editInvoice.invoiceDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "invoice-invoiceDate",
                                        value: _vm.convertDateFromServer(
                                          _vm.editInvoice.invoiceDate
                                        ),
                                        name: "invoiceDate",
                                        type: "text",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateDateField(
                                            $event,
                                            _vm.editInvoice,
                                            "invoiceDate"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-4" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    attrs: { for: "invoice-calculatedFrom" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "care4YouApp.invoice.calculatedFrom"
                                        )
                                      ),
                                    },
                                  },
                                  [_vm._v("Calculated From")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-input-group",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "b-input-group-prepend",
                                      [
                                        _c("b-form-datepicker", {
                                          staticClass: "form-control",
                                          attrs: {
                                            locale: _vm.currentLanguage,
                                            "aria-controls":
                                              "invoice-calculatedFrom",
                                            "button-only": "",
                                            "close-button": "",
                                            name: "calculatedFrom",
                                            "reset-button": "",
                                            "today-button": "",
                                          },
                                          model: {
                                            value:
                                              _vm.editInvoice.calculatedFrom,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editInvoice,
                                                "calculatedFrom",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editInvoice.calculatedFrom",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "invoice-calculatedFrom",
                                        value: _vm.convertDateFromServer(
                                          _vm.editInvoice.calculatedFrom
                                        ),
                                        name: "calculatedFrom",
                                        type: "text",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateDateField(
                                            $event,
                                            _vm.editInvoice,
                                            "calculatedFrom"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-4" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    attrs: { for: "invoice-calculatedTo" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "care4YouApp.invoice.calculatedTo"
                                        )
                                      ),
                                    },
                                  },
                                  [_vm._v("Calculated To")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-input-group",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "b-input-group-prepend",
                                      [
                                        _c("b-form-datepicker", {
                                          staticClass: "form-control",
                                          attrs: {
                                            locale: _vm.currentLanguage,
                                            "aria-controls":
                                              "invoice-calculatedTo",
                                            "button-only": "",
                                            "close-button": "",
                                            name: "calculatedTo",
                                            "reset-button": "",
                                            "today-button": "",
                                          },
                                          model: {
                                            value: _vm.editInvoice.calculatedTo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editInvoice,
                                                "calculatedTo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editInvoice.calculatedTo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "invoice-calculatedTo",
                                        value: _vm.convertDateFromServer(
                                          _vm.editInvoice.calculatedTo
                                        ),
                                        name: "calculatedTo",
                                        type: "text",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateDateField(
                                            $event,
                                            _vm.editInvoice,
                                            "calculatedTo"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-control-label",
                                attrs: { for: "invoice-performancePeriod" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "care4YouApp.invoice.performancePeriod"
                                    )
                                  ),
                                },
                              },
                              [_vm._v("Performance Period")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.editInvoice.performancePeriod,
                                  expression: "editInvoice.performancePeriod",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "invoice-performancePeriod",
                                name: "performancePeriod",
                                type: "text",
                              },
                              domProps: {
                                value: _vm.editInvoice.performancePeriod,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.editInvoice,
                                    "performancePeriod",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "invoice-introduction" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("care4YouApp.invoice.introduction")
                                    ),
                                  },
                                },
                                [_vm._v("Introduction")]
                              ),
                              _vm._v(" "),
                              _c("b-form-textarea", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "invoice-introduction",
                                  name: "introduction",
                                  type: "text",
                                  rows: "6",
                                  "max-rows": "10",
                                  placeholder: _vm.$t(
                                    "care4YouApp.invoice.introduction"
                                  ),
                                },
                                model: {
                                  value: _vm.editInvoice.introduction,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editInvoice,
                                      "introduction",
                                      $$v
                                    )
                                  },
                                  expression: "editInvoice.introduction",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "invoice-footer" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("care4YouApp.invoice.footer")
                                    ),
                                  },
                                },
                                [_vm._v("Footer")]
                              ),
                              _vm._v(" "),
                              _c("b-form-textarea", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "invoice-footer",
                                  name: "footer",
                                  type: "text",
                                  rows: "6",
                                  "max-rows": "10",
                                  placeholder: _vm.$t(
                                    "care4YouApp.invoice.footer"
                                  ),
                                },
                                model: {
                                  value: _vm.editInvoice.footer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editInvoice, "footer", $$v)
                                  },
                                  expression: "editInvoice.footer",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "form-group col-4" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "invoice-isSent" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("care4YouApp.invoice.isSent")
                                    ),
                                  },
                                },
                                [_vm._v("Is Sent")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editInvoice.isSent,
                                    expression: "editInvoice.isSent",
                                  },
                                ],
                                staticClass: "form-check",
                                attrs: {
                                  id: "invoice-isSent",
                                  name: "isSent",
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.editInvoice.isSent)
                                    ? _vm._i(_vm.editInvoice.isSent, null) > -1
                                    : _vm.editInvoice.isSent,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.editInvoice.isSent,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.editInvoice,
                                            "isSent",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.editInvoice,
                                            "isSent",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.editInvoice, "isSent", $$c)
                                    }
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group col-4" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "invoice-isPaid" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("care4YouApp.invoice.isPaid")
                                    ),
                                  },
                                },
                                [_vm._v("Is Paid")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editInvoice.isPaid,
                                    expression: "editInvoice.isPaid",
                                  },
                                ],
                                staticClass: "form-check",
                                attrs: {
                                  id: "invoice-isPaid",
                                  name: "isPaid",
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.editInvoice.isPaid)
                                    ? _vm._i(_vm.editInvoice.isPaid, null) > -1
                                    : _vm.editInvoice.isPaid,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.editInvoice.isPaid,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.editInvoice,
                                            "isPaid",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.editInvoice,
                                            "isPaid",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.editInvoice, "isPaid", $$c)
                                    }
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group col-4" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "invoice-isUnneeded" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("care4YouApp.invoice.isUnneeded")
                                    ),
                                  },
                                },
                                [_vm._v("is Unneeded")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editInvoice.isUnneeded,
                                    expression: "editInvoice.isUnneeded",
                                  },
                                ],
                                staticClass: "form-check",
                                attrs: {
                                  id: "invoice-isUnneeded",
                                  name: "isUnneeded",
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.editInvoice.isUnneeded
                                  )
                                    ? _vm._i(_vm.editInvoice.isUnneeded, null) >
                                      -1
                                    : _vm.editInvoice.isUnneeded,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.editInvoice.isUnneeded,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.editInvoice,
                                            "isUnneeded",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.editInvoice,
                                            "isUnneeded",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.editInvoice,
                                        "isUnneeded",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          attrs: { slot: "modal-footer" },
                          slot: "modal-footer",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.cancel")
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeEditDialog()
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                id: "jhi-confirm-delete-invoice",
                                type: "button",
                                disabled:
                                  !_vm.editInvoice.calculatedFrom ||
                                  !_vm.editInvoice.calculatedTo,
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.save")
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveEditInvoice(false)
                                },
                              },
                            },
                            [_vm._v("\n          Save\n        ")]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editActivity
                ? _c(
                    "b-modal",
                    {
                      ref: "editActivity",
                      attrs: { id: "editActivity", size: "xl" },
                      model: {
                        value: _vm.showEditActivity,
                        callback: function ($$v) {
                          _vm.showEditActivity = $$v
                        },
                        expression: "showEditActivity",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "modal-title" }, slot: "modal-title" },
                        [
                          _c(
                            "span",
                            {
                              attrs: {
                                id: "care4YouApp.activity.edit.question",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.edit.title")
                                ),
                              },
                            },
                            [_vm._v("Edit activity details")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-body" }, [
                        _c("div", [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "form-group col-1" }, [
                              _c(
                                "h3",
                                { staticClass: "align-middle form-control" },
                                [
                                  _vm.getActivityTypeIcon(
                                    _vm.editActivity?.type
                                  ) != null
                                    ? _c("font-awesome-icon", {
                                        attrs: {
                                          icon: _vm.getActivityTypeIcon(
                                            _vm.editActivity?.type
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group col-4" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "activity-type" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("care4YouApp.activity.type")
                                    ),
                                  },
                                },
                                [_vm._v("Type")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.editActivity.type,
                                      expression: "editActivity.type",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { id: "activity-type", name: "type" },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.editActivity,
                                        "type",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                _vm._l(
                                  _vm.activityTypeOptions,
                                  function (activityTypeOption) {
                                    return _c(
                                      "option",
                                      {
                                        key: activityTypeOption,
                                        attrs: {
                                          label: _vm.$t(
                                            "care4YouApp.ActivityType." +
                                              activityTypeOption
                                          ),
                                        },
                                        domProps: { value: activityTypeOption },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(activityTypeOption) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "activity-text" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("care4YouApp.activity.text")
                                    ),
                                  },
                                },
                                [_vm._v("Text")]
                              ),
                              _vm._v(" "),
                              _c("b-form-textarea", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "activity-text",
                                  "max-rows": "10",
                                  name: "text",
                                  rows: "6",
                                  type: "text",
                                  placeholder: _vm.$t(
                                    "care4YouApp.activity.text"
                                  ),
                                },
                                model: {
                                  value: _vm.editActivity.text,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editActivity, "text", $$v)
                                  },
                                  expression: "editActivity.text",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          attrs: { slot: "modal-footer" },
                          slot: "modal-footer",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.cancel")
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeEditActivityDialog()
                                },
                              },
                            },
                            [_vm._v("\n          Cancel\n        ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                id: "jhi-confirm-delete-activity",
                                type: "button",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.save")
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveEditActivity()
                                },
                              },
                            },
                            [_vm._v("\n          Save\n        ")]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editSalary
                ? _c(
                    "b-modal",
                    {
                      ref: "editSalary",
                      attrs: { id: "editSalary", size: "xl" },
                      model: {
                        value: _vm.showEditSalary,
                        callback: function ($$v) {
                          _vm.showEditSalary = $$v
                        },
                        expression: "showEditSalary",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "modal-title" }, slot: "modal-title" },
                        [
                          _c(
                            "span",
                            {
                              attrs: { id: "care4YouApp.salary.edit.question" },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.edit.title")
                                ),
                              },
                            },
                            [_vm._v("Edit salary details")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-body" }, [
                        _c("div", [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "form-group col-4" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "salary-amount" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("care4YouApp.salary.amount")
                                    ),
                                  },
                                },
                                [_vm._v("Amount")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editSalary.amount,
                                    expression: "editSalary.amount",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "salary-amount",
                                  name: "salary-amount",
                                  type: "text",
                                },
                                domProps: { value: _vm.editSalary.amount },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.editSalary,
                                      "amount",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-3" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    attrs: { for: "salary-amount" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("care4YouApp.salary.validFrom")
                                      ),
                                    },
                                  },
                                  [_vm._v(" Valid From ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-input-group",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "b-input-group-prepend",
                                      [
                                        _c("b-form-datepicker", {
                                          staticClass: "form-control",
                                          attrs: {
                                            locale: _vm.currentLanguage,
                                            "aria-controls": "salary-validFrom",
                                            "button-only": "",
                                            "close-button": "",
                                            name: "validFrom",
                                            "reset-button": "",
                                            "today-button": "",
                                          },
                                          model: {
                                            value: _vm.editSalary.validFrom,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editSalary,
                                                "validFrom",
                                                $$v
                                              )
                                            },
                                            expression: "editSalary.validFrom",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "salary-validFrom",
                                        value: _vm.convertDateFromServer(
                                          _vm.editSalary.validFrom
                                        ),
                                        name: "validFrom",
                                        type: "text",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateDateField(
                                            $event,
                                            _vm.editSalary,
                                            "validFrom"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  attrs: { for: "salary-text" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("care4YouApp.salary.text")
                                    ),
                                  },
                                },
                                [_vm._v("Text")]
                              ),
                              _vm._v(" "),
                              _c("b-form-textarea", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "salary-text",
                                  "max-rows": "10",
                                  name: "text",
                                  rows: "6",
                                  type: "text",
                                  placeholder: _vm.$t(
                                    "care4YouApp.salary.text"
                                  ),
                                },
                                model: {
                                  value: _vm.editSalary.text,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editSalary, "text", $$v)
                                  },
                                  expression: "editSalary.text",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          attrs: { slot: "modal-footer" },
                          slot: "modal-footer",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.cancel")
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeEditSalaryDialog()
                                },
                              },
                            },
                            [_vm._v("\n          Cancel\n        ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                id: "jhi-confirm-delete-salary",
                                type: "button",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.save")
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveEditSalary()
                                },
                              },
                            },
                            [_vm._v("\n          Save\n        ")]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("b-modal", { attrs: { id: "switchCarer" } }, [
                _c(
                  "span",
                  { attrs: { slot: "modal-title" }, slot: "modal-title" },
                  [
                    _c(
                      "span",
                      {
                        attrs: { id: "care4YouApp.salary.edit.question" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("care4YouApp.customer.home.switchCarer")
                          ),
                        },
                      },
                      [_vm._v("Switch carer")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "label",
                        {
                          staticClass: "form-control-label",
                          attrs: { for: "salary-amount" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("care4YouApp.customer.switch.date")
                            ),
                          },
                        },
                        [_vm._v("\n            Switch carer date\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-form-datepicker", {
                                staticClass: "form-control",
                                attrs: {
                                  locale: _vm.currentLanguage,
                                  "aria-controls": "switchCarer-endDate",
                                  "button-only": "",
                                  "close-button": "",
                                  name: "validFrom",
                                  "reset-button": "",
                                  "today-button": "",
                                },
                                model: {
                                  value: _vm.carerSwitch.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.carerSwitch, "endDate", $$v)
                                  },
                                  expression: "carerSwitch.endDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              id: "switchCarer-endDate",
                              value: _vm.convertDateFromServer(
                                _vm.carerSwitch.endDate
                              ),
                              name: "validFrom",
                              type: "text",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.updateDateField(
                                  $event,
                                  _vm.carerSwitch,
                                  "endDate"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: { for: "carerSwitch-note" },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("care4YouApp.customer.switch.note")
                                ),
                              },
                            },
                            [_vm._v("Switch carer note")]
                          ),
                          _vm._v(" "),
                          _c("b-form-textarea", {
                            staticClass: "form-control",
                            attrs: {
                              id: "carerSwitch-note",
                              "max-rows": "10",
                              name: "text",
                              rows: "6",
                              type: "text",
                              placeholder: _vm.$t(
                                "care4YouApp.customer.switch.note"
                              ),
                            },
                            model: {
                              value: _vm.carerSwitch.note,
                              callback: function ($$v) {
                                _vm.$set(_vm.carerSwitch, "note", $$v)
                              },
                              expression: "carerSwitch.note",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        domProps: {
                          textContent: _vm._s(_vm.$t("entity.action.cancel")),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.closeSwitchCarerDialog()
                          },
                        },
                      },
                      [_vm._v("\n          Cancel\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          id: "jhi-confirm-switch-carer",
                          type: "button",
                        },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("care4YouApp.customer.switch.doSwitch")
                          ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.doSwitchCarer()
                          },
                        },
                      },
                      [_vm._v("\n          Save\n        ")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("b-modal", { attrs: { id: "terminateCareAssignment" } }, [
                _c(
                  "span",
                  { attrs: { slot: "modal-title" }, slot: "modal-title" },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          id: "care4YouApp.customer.home.terminateCareAssignment",
                        },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "care4YouApp.customer.home.terminateCareAssignment"
                            )
                          ),
                        },
                      },
                      [_vm._v("Terminate Care Assignment")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-6" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: {
                                for: "careAssignmentTermination-terminationDate",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "care4YouApp.careAssignment.terminate.date"
                                  )
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n                Care Assignment End Date\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-input-group",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-form-datepicker", {
                                    staticClass: "form-control",
                                    attrs: {
                                      locale: _vm.currentLanguage,
                                      "aria-controls":
                                        "careAssignmentTermination-terminationDate",
                                      "button-only": "",
                                      "close-button": "",
                                      name: "terminationDate",
                                      "reset-button": "",
                                      "today-button": "",
                                    },
                                    model: {
                                      value:
                                        _vm.careAssignmentTermination
                                          .terminationDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.careAssignmentTermination,
                                          "terminationDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "careAssignmentTermination.terminationDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("b-form-input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "careAssignmentTermination-terminationDate",
                                  value: _vm.convertDateFromServer(
                                    _vm.careAssignmentTermination
                                      .terminationDate
                                  ),
                                  name: "terminationDate",
                                  type: "text",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateDateField(
                                      $event,
                                      _vm.careAssignmentTermination,
                                      "terminationDate"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-control-label",
                            attrs: {
                              for: "careAssignmentTermination-terminationReason",
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "care4YouApp.careAssignment.terminate.reason"
                                )
                              ),
                            },
                          },
                          [_vm._v("Type")]
                        ),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.careAssignmentTermination
                                    .terminationReason,
                                expression:
                                  "careAssignmentTermination.terminationReason",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "careAssignmentTermination-terminationReason",
                              name: "terminationReason",
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.careAssignmentTermination,
                                  "terminationReason",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(
                            _vm.careTerminationReasonOptions,
                            function (terminationReasonOption) {
                              return _c(
                                "option",
                                {
                                  key: terminationReasonOption,
                                  attrs: {
                                    label: _vm.$t(
                                      "care4YouApp.careTerminationReason." +
                                        terminationReasonOption
                                    ),
                                  },
                                  domProps: { value: terminationReasonOption },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(terminationReasonOption) +
                                      "\n                "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-12" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: { for: "careAssignmentTermination-note" },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "care4YouApp.careAssignment.terminate.note"
                                  )
                                ),
                              },
                            },
                            [_vm._v("Care Assignment Termination Note")]
                          ),
                          _vm._v(" "),
                          _c("b-form-textarea", {
                            staticClass: "form-control",
                            attrs: {
                              id: "careAssignmentTermination-note",
                              "max-rows": "10",
                              name: "note",
                              rows: "6",
                              type: "text",
                              placeholder: _vm.$t(
                                "care4YouApp.careAssignment.terminate.note"
                              ),
                            },
                            model: {
                              value: _vm.careAssignmentTermination.note,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.careAssignmentTermination,
                                  "note",
                                  $$v
                                )
                              },
                              expression: "careAssignmentTermination.note",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        domProps: {
                          textContent: _vm._s(_vm.$t("entity.action.cancel")),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.closeTerminateCareAssignmentDialog()
                          },
                        },
                      },
                      [_vm._v("\n          Cancel\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          id: "jhi-confirm-care-assignment-termination",
                          type: "button",
                          disabled:
                            !_vm.careAssignmentTermination.terminationDate,
                        },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "care4YouApp.careAssignment.terminate.doTermination"
                            )
                          ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.doTerminateCareAssignment()
                          },
                        },
                      },
                      [_vm._v("\n          doTermination\n        ")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("b-modal", { attrs: { id: "deleteCareAssignment" } }, [
                _c(
                  "span",
                  { attrs: { slot: "modal-title" }, slot: "modal-title" },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          id: "care4YouApp.customer.home.deleteCareAssignment",
                        },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "care4YouApp.customer.home.deleteCareAssignment"
                            )
                          ),
                        },
                      },
                      [_vm._v("Delete Care Assignment")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", [
                    _c("div", { staticClass: "row" }, [
                      _vm.careAssignmentForDeletion
                        ? _c(
                            "p",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "care4YouApp.careAssignment.delete.question",
                                    { id: _vm.careAssignmentForDeletion.id }
                                  )
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n              Care Assignment End Date\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        domProps: {
                          textContent: _vm._s(_vm.$t("entity.action.cancel")),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.closeDeleteCareAssignmentDialog()
                          },
                        },
                      },
                      [_vm._v("\n          Cancel\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          id: "jhi-confirm-care-assignment-deletion",
                          type: "button",
                        },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "care4YouApp.careAssignment.delete.doDeletion"
                            )
                          ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.doDeleteCareAssignment()
                          },
                        },
                      },
                      [_vm._v("\n          doDeletion\n        ")]
                    ),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }