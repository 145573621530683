import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IBpmProcessVariable } from '@/shared/model/bpm-process-variable.model';

const baseApiUrl = 'api/bpm-process-variables';
const baseSearchApiUrl = 'api/_search/bpm-process-variables?query=';

export default class BpmProcessVariableService {
  public search(query, paginationQuery): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseSearchApiUrl}${query}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<IBpmProcessVariable> {
    return new Promise<IBpmProcessVariable>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IBpmProcessVariable): Promise<IBpmProcessVariable> {
    return new Promise<IBpmProcessVariable>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateAll(id: number, entities: IBpmProcessVariable[]): Promise<IBpmProcessVariable> {
    return new Promise<IBpmProcessVariable>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}s/${id}`, entities)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IBpmProcessVariable): Promise<IBpmProcessVariable> {
    return new Promise<IBpmProcessVariable>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IBpmProcessVariable): Promise<IBpmProcessVariable> {
    return new Promise<IBpmProcessVariable>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
