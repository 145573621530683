import { ActivityType } from '@/shared/model/enumerations/activity-type.model';
import { EntityType } from '@/shared/model/enumerations/entity-type.model';

export interface IActivity {
  id?: number;
  createdAt?: Date | null;
  createdBy?: string | null;
  updatedAt?: Date | null;
  updatedBy?: string | null;
  type?: ActivityType | null;
  text?: string | null;
  parentType?: EntityType | null;
  parentKey?: number | null;
}

export class Activity implements IActivity {
  constructor(
    public id?: number,
    public createdAt?: Date | null,
    public createdBy?: string | null,
    public updatedAt?: Date | null,
    public updatedBy?: string | null,
    public type?: ActivityType | null,
    public text?: string | null,
    public parentType?: EntityType | null,
    public parentKey?: number | null
  ) {}
}
