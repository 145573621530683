import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';

import '@/shared/config/dayjs';
import { Route } from 'vue-router';

@Component({
  components: {
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar,

    'jhi-footer': JhiFooter,
  },
})
export default class App extends Vue {
  private renderHeader = true;
  private renderFooter = true;
  private withinCard = true;

  created() {
    //this.getLayoutFlags(this.$router.currentRoute);
    //onUpdated(() => this.getLayoutFlags(this.$router.currentRoute));
    this.$router.beforeResolve(async (to, from, next) => {
      this.getLayoutFlags(to);
      next();
    });
  }

  private getLayoutFlags(route: Route) {
    if (route.meta?.renderHeader != undefined) {
      this.renderHeader = route.meta?.renderHeader;
    }
    if (route.meta?.withinCard != undefined) {
      this.withinCard = route.meta?.withinCard;
    }
    if (route.meta?.renderFooter != undefined) {
      this.renderFooter = route.meta?.renderFooter;
    }
  }
}
