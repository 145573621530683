import { DocumentType } from '@/shared/model/enumerations/document-type.model';
import { DocumentStatus } from '@/shared/model/enumerations/document-status.model';
import { EntityType } from '@/shared/model/enumerations/entity-type.model';
export interface IDocument {
  id?: number;
  documentBinariesContentType?: string | null;
  documentBinaries?: string | null;
  documentName?: string | null;
  documentType?: DocumentType | null;
  documentStatus?: DocumentStatus | null;
  createdAt?: Date | null;
  statusChangedAt?: Date | null;
  parentType?: EntityType | null;
  parentKey?: number | null;
}

export class Document implements IDocument {
  constructor(
    public id?: number,
    public documentBinariesContentType?: string | null,
    public documentBinaries?: string | null,
    public documentName?: string | null,
    public documentType?: DocumentType | null,
    public documentStatus?: DocumentStatus | null,
    public createdAt?: Date | null,
    public statusChangedAt?: Date | null,
    public parentType?: EntityType | null,
    public parentKey?: number | null
  ) {}
}
