import { ICustomer } from '@/shared/model/customer.model';
import { ICarer } from '@/shared/model/carer.model';

import { SuggestionState } from '@/shared/model/enumerations/suggestion-state.model';
export interface ICarerSuggestion {
  id?: number;
  desiredSalary?: number | null;
  totalCosts?: number | null;
  travelCosts?: number | null;
  arrivalFrom?: string | null;
  assignmentDuration?: string | null;
  notes?: string | null;
  state?: SuggestionState | null;
  stateNotes?: string | null;
  customer?: ICustomer | null;
  carer?: ICarer | null;
}

export class CarerSuggestion implements ICarerSuggestion {
  constructor(
    public id?: number,
    public desiredSalary?: number | null,
    public totalCosts?: number | null,
    public travelCosts?: number | null,
    public arrivalFrom?: string | null,
    public assignmentDuration?: string | null,
    public notes?: string | null,
    public state?: SuggestionState | null,
    public stateNotes?: string | null,
    public customer?: ICustomer | null,
    public carer?: ICarer | null
  ) {}
}
