import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');
// prettier-ignore
const Activity = () => import('@/entities/activity/activity.vue');
// prettier-ignore
const ActivityDetails = () => import('@/entities/activity/activity-details.vue');
// prettier-ignore
const ActivityUpdate = () => import('@/entities/activity/activity-update.vue');
// prettier-ignore
const Agency = () => import('@/entities/agency/agency.vue');
// prettier-ignore
const AgencyDetails = () => import('@/entities/agency/agency-details.vue');
// prettier-ignore
const AgencyUpdate = () => import('@/entities/agency/agency-update.vue');
// prettier-ignore
const ApplicationUser = () => import('@/entities/application-user/application-user.vue');
// prettier-ignore
const ApplicationUserDetails = () => import('@/entities/application-user/application-user-details.vue');
// prettier-ignore
const ApplicationUserUpdate = () => import('@/entities/application-user/application-user-update.vue');
// prettier-ignore
const Attribute = () => import('@/entities/attribute/attribute.vue');
// prettier-ignore
const AttributeDetails = () => import('@/entities/attribute/attribute-details.vue');
// prettier-ignore
const AttributeUpdate = () => import('@/entities/attribute/attribute-update.vue');
// prettier-ignore
const BankAccount = () => import("@/entities/bank-account/bank-account.vue");
// prettier-ignore
const BankAccountDetails = () => import("@/entities/bank-account/bank-account-details.vue");
// prettier-ignore
const BankAccountUpdate = () => import('@/entities/bank-account/bank-account-update.vue');
// prettier-ignore
const BpmProcess = () => import('@/entities/bpm-process/bpm-process.vue');
// prettier-ignore
const BpmProcessDetails = () => import('@/entities/bpm-process/bpm-process-details.vue');
// prettier-ignore
const BpmProcessUpdate = () => import('@/entities/bpm-process/bpm-process-update.vue');
// prettier-ignore
const BpmProcessVariable = () => import('@/entities/bpm-process-variable/bpm-process-variable.vue');
// prettier-ignore
const BpmProcessVariableDetails = () => import('@/entities/bpm-process-variable/bpm-process-variable-details.vue');
// prettier-ignore
const BpmProcessVariableUpdate = () => import('@/entities/bpm-process-variable/bpm-process-variable-update.vue');
// prettier-ignore
const BpmUserTask = () => import('@/entities/bpm-user-task/bpm-user-task.vue');
// prettier-ignore
const BpmUserTaskDetails = () => import('@/entities/bpm-user-task/bpm-user-task-details.vue');
// prettier-ignore
const BpmUserTaskUpdate = () => import('@/entities/bpm-user-task/bpm-user-task-update.vue');
// prettier-ignore
const CareAssignment = () => import("@/entities/care-assignment/care-assignment.vue");
// prettier-ignore
const CareAssignmentDetails = () => import("@/entities/care-assignment/care-assignment-details.vue");
// prettier-ignore
const CareAssignmentUpdate = () => import('@/entities/care-assignment/care-assignment-update.vue');
// prettier-ignore
const Carer = () => import('@/entities/carer/carer.vue');
// prettier-ignore
const CarerDetails = () => import('@/entities/carer/carer-details.vue');
// prettier-ignore
const CarerSuggestion = () => import('@/entities/carer-suggestion/carer-suggestion.vue');
// prettier-ignore
const CarerSuggestionDetails = () => import('@/entities/carer-suggestion/carer-suggestion-details.vue');
// prettier-ignore
const CarerSuggestionUpdate = () => import('@/entities/carer-suggestion/carer-suggestion-update.vue');
// prettier-ignore
const CarerUpdate = () => import('@/entities/carer/carer-update.vue');
// prettier-ignore
const Communication = () => import('@/entities/communication/communication.vue');
// prettier-ignore
const CommunicationDetails = () => import('@/entities/communication/communication-details.vue');
// prettier-ignore
const CommunicationUpdate = () => import('@/entities/communication/communication-update.vue');
// prettier-ignore
const Contact = () => import('@/entities/contact/contact.vue');
// prettier-ignore
const ContactDetails = () => import('@/entities/contact/contact-details.vue');
// prettier-ignore
const ContactPerson = () => import('@/entities/contact-person/contact-person.vue');
// prettier-ignore
const ContactPersonDetails = () => import('@/entities/contact-person/contact-person-details.vue');
// prettier-ignore
const ContactPersonUpdate = () => import('@/entities/contact-person/contact-person-update.vue');
// prettier-ignore
const ContactUpdate = () => import('@/entities/contact/contact-update.vue');
// prettier-ignore
const Customer = () => import('@/entities/customer/customer.vue');
// prettier-ignore
const CustomerDetails = () => import('@/entities/customer/customer-details.vue');
// prettier-ignore
const CustomerUpdate = () => import('@/entities/customer/customer-update.vue');
// prettier-ignore
const Document = () => import('@/entities/document/document.vue');
// prettier-ignore
const DocumentDetails = () => import('@/entities/document/document-details.vue');
// prettier-ignore
const DocumentTemplate = () => import('@/entities/document-template/document-template.vue');
// prettier-ignore
const DocumentTemplateDetails = () => import('@/entities/document-template/document-template-details.vue');
// prettier-ignore
const DocumentTemplateUpdate = () => import('@/entities/document-template/document-template-update.vue');
// prettier-ignore
const DocumentUpdate = () => import('@/entities/document/document-update.vue');
// prettier-ignore
const EmailTemplate = () => import('@/entities/email-template/email-template.vue');
// prettier-ignore
const EmailTemplateDetails = () => import('@/entities/email-template/email-template-details.vue');
// prettier-ignore
const EmailTemplateUpdate = () => import('@/entities/email-template/email-template-update.vue');
// prettier-ignore
const Event = () => import('@/entities/event/event.vue');
// prettier-ignore
const EventDetails = () => import('@/entities/event/event-details.vue');
// prettier-ignore
const EventUpdate = () => import('@/entities/event/event-update.vue');
// prettier-ignore
const LanguageSkill = () => import('@/entities/language-skill/language-skill.vue');
// prettier-ignore
const LanguageSkillDetails = () => import('@/entities/language-skill/language-skill-details.vue');
// prettier-ignore
const LanguageSkillUpdate = () => import('@/entities/language-skill/language-skill-update.vue');
// prettier-ignore
const Patient = () => import('@/entities/patient/patient.vue');
// prettier-ignore
const PatientCareAttribute = () => import('@/entities/patient-care-attribute/patient-care-attribute.vue');
// prettier-ignore
const PatientCareAttributeDetails = () => import('@/entities/patient-care-attribute/patient-care-attribute-details.vue');
// prettier-ignore
const PatientCareAttributeUpdate = () => import('@/entities/patient-care-attribute/patient-care-attribute-update.vue');
// prettier-ignore
const PatientDetails = () => import('@/entities/patient/patient-details.vue');
// prettier-ignore
const PatientUpdate = () => import('@/entities/patient/patient-update.vue');
// prettier-ignore
const PersonalSkill = () => import('@/entities/personal-skill/personal-skill.vue');
// prettier-ignore
const PersonalSkillDetails = () => import('@/entities/personal-skill/personal-skill-details.vue');
// prettier-ignore
const PersonalSkillUpdate = () => import('@/entities/personal-skill/personal-skill-update.vue');
// prettier-ignore
const Rating = () => import('@/entities/rating/rating.vue');
// prettier-ignore
const RatingDetails = () => import('@/entities/rating/rating-details.vue');
// prettier-ignore
const RatingUpdate = () => import('@/entities/rating/rating-update.vue');
// prettier-ignore
const UserGroup = () => import('@/entities/user-group/user-group.vue');
// prettier-ignore
const UserGroupDetails = () => import('@/entities/user-group/user-group-details.vue');
// prettier-ignore
const UserGroupUpdate = () => import('@/entities/user-group/user-group-update.vue');
// prettier-ignore
const Invoice = () => import('@/entities/invoice/invoice.vue');
// prettier-ignore
const InvoiceUpdate = () => import('@/entities/invoice/invoice-update.vue');
// prettier-ignore
const InvoiceDetails = () => import('@/entities/invoice/invoice-details.vue');
const Setting = () => import('@/entities/setting/setting.vue');
// prettier-ignore
const SettingUpdate = () => import('@/entities/setting/setting-update.vue');
// prettier-ignore
const SettingDetails = () => import('@/entities/setting/setting-details.vue');
// prettier-ignore
const Watcher = () => import('@/entities/watcher/watcher.vue');
// prettier-ignore
const WatcherUpdate = () => import('@/entities/watcher/watcher-update.vue');
// prettier-ignore
const WatcherDetails = () => import('@/entities/watcher/watcher-details.vue');
// prettier-ignore
const Notification = () => import('@/entities/notification/notification.vue');
// prettier-ignore
const NotificationUpdate = () => import('@/entities/notification/notification-update.vue');
// prettier-ignore
const NotificationDetails = () => import('@/entities/notification/notification-details.vue');
// prettier-ignore
const Salary = () => import('@/entities/salary/salary.vue');
// prettier-ignore
const SalaryUpdate = () => import('@/entities/salary/salary-update.vue');
// prettier-ignore
const SalaryDetails = () => import('@/entities/salary/salary-details.vue');
// prettier-ignore
const DynamicField = () => import('@/entities/dynamic-field/dynamic-field.vue');
// prettier-ignore
const DynamicFieldUpdate = () => import('@/entities/dynamic-field/dynamic-field-update.vue');
// prettier-ignore
const DynamicFieldDetails = () => import('@/entities/dynamic-field/dynamic-field-details.vue');
// prettier-ignore
const Email = () => import('@/entities/email/email.vue');
// prettier-ignore
const EmailUpdate = () => import('@/entities/email/email-update.vue');
// prettier-ignore
const EmailDetails = () => import('@/entities/email/email-details.vue');
// prettier-ignore
const Attachment = () => import('@/entities/attachment/attachment.vue');
// prettier-ignore
const AttachmentUpdate = () => import('@/entities/attachment/attachment-update.vue');
// prettier-ignore
const AttachmentDetails = () => import('@/entities/attachment/attachment-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'bpm-process',
      name: 'BpmProcess',
      component: BpmProcess,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bpm-process/new',
      name: 'BpmProcessCreate',
      component: BpmProcessUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bpm-process/:bpmProcessId/edit',
      name: 'BpmProcessEdit',
      component: BpmProcessUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bpm-process/:bpmProcessId/view',
      name: 'BpmProcessView',
      component: BpmProcessDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bpm-process-variable',
      name: 'BpmProcessVariable',
      component: BpmProcessVariable,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bpm-process-variable/new',
      name: 'BpmProcessVariableCreate',
      component: BpmProcessVariableUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bpm-process-variable/:bpmProcessVariableId/edit',
      name: 'BpmProcessVariableEdit',
      component: BpmProcessVariableUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bpm-process-variable/:bpmProcessVariableId/view',
      name: 'BpmProcessVariableView',
      component: BpmProcessVariableDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'customer',
      name: 'Customer',
      component: Customer,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'customer/new',
      name: 'CustomerCreate',
      component: CustomerUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'customer/:customerId/edit',
      name: 'CustomerEdit',
      component: CustomerUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'customer/:customerId/view',
      name: 'CustomerView',
      component: CustomerDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'carer',
      name: 'Carer',
      component: Carer,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'carer/new',
      name: 'CarerCreate',
      component: CarerUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'carer/:carerId/edit',
      name: 'CarerEdit',
      component: CarerUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'carer/:carerId/view',
      name: 'CarerView',
      component: CarerDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'agency',
      name: 'Agency',
      component: Agency,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'agency/new',
      name: 'AgencyCreate',
      component: AgencyUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'agency/:agencyId/edit',
      name: 'AgencyEdit',
      component: AgencyUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'agency/:agencyId/view',
      name: 'AgencyView',
      component: AgencyDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'application-user',
      name: 'ApplicationUser',
      component: ApplicationUser,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'application-user/new',
      name: 'ApplicationUserCreate',
      component: ApplicationUserUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'application-user/:applicationUserId/edit',
      name: 'ApplicationUserEdit',
      component: ApplicationUserUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'application-user/:applicationUserId/view',
      name: 'ApplicationUserView',
      component: ApplicationUserDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'user-group',
      name: 'UserGroup',
      component: UserGroup,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'user-group/new',
      name: 'UserGroupCreate',
      component: UserGroupUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'user-group/:userGroupId/edit',
      name: 'UserGroupEdit',
      component: UserGroupUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'user-group/:userGroupId/view',
      name: 'UserGroupView',
      component: UserGroupDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bpm-user-task',
      name: 'BpmUserTask',
      component: BpmUserTask,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bpm-user-task/new',
      name: 'BpmUserTaskCreate',
      component: BpmUserTaskUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bpm-user-task/:bpmUserTaskId/edit',
      name: 'BpmUserTaskEdit',
      component: BpmUserTaskUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bpm-user-task/:bpmUserTaskId/view',
      name: 'BpmUserTaskView',
      component: BpmUserTaskDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'contact',
      name: 'Contact',
      component: Contact,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'contact/new',
      name: 'ContactCreate',
      component: ContactUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'contact/:contactId/edit',
      name: 'ContactEdit',
      component: ContactUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'contact/:contactId/view',
      name: 'ContactView',
      component: ContactDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'patient',
      name: 'Patient',
      component: Patient,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'patient/new',
      name: 'PatientCreate',
      component: PatientUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'patient/:patientId/edit',
      name: 'PatientEdit',
      component: PatientUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'patient/:patientId/view',
      name: 'PatientView',
      component: PatientDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'contact-person',
      name: 'ContactPerson',
      component: ContactPerson,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'contact-person/new',
      name: 'ContactPersonCreate',
      component: ContactPersonUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'contact-person/:contactPersonId/edit',
      name: 'ContactPersonEdit',
      component: ContactPersonUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'contact-person/:contactPersonId/view',
      name: 'ContactPersonView',
      component: ContactPersonDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'communication',
      name: 'Communication',
      component: Communication,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'communication/new',
      name: 'CommunicationCreate',
      component: CommunicationUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'communication/:communicationId/edit',
      name: 'CommunicationEdit',
      component: CommunicationUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'communication/:communicationId/view',
      name: 'CommunicationView',
      component: CommunicationDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'rating',
      name: 'Rating',
      component: Rating,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'rating/new',
      name: 'RatingCreate',
      component: RatingUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'rating/:ratingId/edit',
      name: 'RatingEdit',
      component: RatingUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'rating/:ratingId/view',
      name: 'RatingView',
      component: RatingDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bank-account',
      name: 'BankAccount',
      component: BankAccount,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bank-account/new',
      name: 'BankAccountCreate',
      component: BankAccountUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bank-account/:bankAccountId/edit',
      name: 'BankAccountEdit',
      component: BankAccountUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'bank-account/:bankAccountId/view',
      name: 'BankAccountView',
      component: BankAccountDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'language-skill',
      name: 'LanguageSkill',
      component: LanguageSkill,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'language-skill/new',
      name: 'LanguageSkillCreate',
      component: LanguageSkillUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'language-skill/:languageSkillId/edit',
      name: 'LanguageSkillEdit',
      component: LanguageSkillUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'language-skill/:languageSkillId/view',
      name: 'LanguageSkillView',
      component: LanguageSkillDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'personal-skill',
      name: 'PersonalSkill',
      component: PersonalSkill,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'personal-skill/new',
      name: 'PersonalSkillCreate',
      component: PersonalSkillUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'personal-skill/:personalSkillId/edit',
      name: 'PersonalSkillEdit',
      component: PersonalSkillUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'personal-skill/:personalSkillId/view',
      name: 'PersonalSkillView',
      component: PersonalSkillDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'patient-care-attribute',
      name: 'PatientCareAttribute',
      component: PatientCareAttribute,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'patient-care-attribute/new',
      name: 'PatientCareAttributeCreate',
      component: PatientCareAttributeUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'patient-care-attribute/:patientCareAttributeId/edit',
      name: 'PatientCareAttributeEdit',
      component: PatientCareAttributeUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'patient-care-attribute/:patientCareAttributeId/view',
      name: 'PatientCareAttributeView',
      component: PatientCareAttributeDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'attribute',
      name: 'Attribute',
      component: Attribute,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'attribute/new',
      name: 'AttributeCreate',
      component: AttributeUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'attribute/:attributeId/edit',
      name: 'AttributeEdit',
      component: AttributeUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'attribute/:attributeId/view',
      name: 'AttributeView',
      component: AttributeDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'activity',
      name: 'Activity',
      component: Activity,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'activity/new',
      name: 'ActivityCreate',
      component: ActivityUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'activity/:activityId/edit',
      name: 'ActivityEdit',
      component: ActivityUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'activity/:activityId/view',
      name: 'ActivityView',
      component: ActivityDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'care-assignment',
      name: 'CareAssignment',
      component: CareAssignment,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'care-assignment/new',
      name: 'CareAssignmentCreate',
      component: CareAssignmentUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'care-assignment/:careAssignmentId/edit',
      name: 'CareAssignmentEdit',
      component: CareAssignmentUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'care-assignment/:careAssignmentId/view',
      name: 'CareAssignmentView',
      component: CareAssignmentDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'document',
      name: 'Document',
      component: Document,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'document/new',
      name: 'DocumentCreate',
      component: DocumentUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'document/:documentId/edit',
      name: 'DocumentEdit',
      component: DocumentUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'document/:documentId/view',
      name: 'DocumentView',
      component: DocumentDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'event',
      name: 'Event',
      component: Event,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'event/new',
      name: 'EventCreate',
      component: EventUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'event/:eventId/edit',
      name: 'EventEdit',
      component: EventUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'event/:eventId/view',
      name: 'EventView',
      component: EventDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'carer-suggestion',
      name: 'CarerSuggestion',
      component: CarerSuggestion,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'carer-suggestion/new',
      name: 'CarerSuggestionCreate',
      component: CarerSuggestionUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'carer-suggestion/:carerSuggestionId/edit',
      name: 'CarerSuggestionEdit',
      component: CarerSuggestionUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'carer-suggestion/:carerSuggestionId/view',
      name: 'CarerSuggestionView',
      component: CarerSuggestionDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'document-template',
      name: 'DocumentTemplate',
      component: DocumentTemplate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'document-template/new',
      name: 'DocumentTemplateCreate',
      component: DocumentTemplateUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'document-template/:documentTemplateId/edit',
      name: 'DocumentTemplateEdit',
      component: DocumentTemplateUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'document-template/:documentTemplateId/view',
      name: 'DocumentTemplateView',
      component: DocumentTemplateDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'email-template',
      name: 'EmailTemplate',
      component: EmailTemplate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'email-template/new',
      name: 'EmailTemplateCreate',
      component: EmailTemplateUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'email-template/:emailTemplateId/edit',
      name: 'EmailTemplateEdit',
      component: EmailTemplateUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'email-template/:emailTemplateId/view',
      name: 'EmailTemplateView',
      component: EmailTemplateDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'invoice',
      name: 'Invoice',
      component: Invoice,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'invoice/new',
      name: 'InvoiceCreate',
      component: InvoiceUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'invoice/:invoiceId/edit',
      name: 'InvoiceEdit',
      component: InvoiceUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'invoice/:invoiceId/view',
      name: 'InvoiceView',
      component: InvoiceDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'setting',
      name: 'Setting',
      component: Setting,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'setting/new',
      name: 'SettingCreate',
      component: SettingUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'setting/:settingId/edit',
      name: 'SettingEdit',
      component: SettingUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'setting/:settingId/view',
      name: 'SettingView',
      component: SettingDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'watcher',
      name: 'Watcher',
      component: Watcher,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'watcher/new',
      name: 'WatcherCreate',
      component: WatcherUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'watcher/:watcherId/edit',
      name: 'WatcherEdit',
      component: WatcherUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'watcher/:watcherId/view',
      name: 'WatcherView',
      component: WatcherDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'notification',
      name: 'Notification',
      component: Notification,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'notification/new',
      name: 'NotificationCreate',
      component: NotificationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'notification/:notificationId/edit',
      name: 'NotificationEdit',
      component: NotificationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'notification/:notificationId/view',
      name: 'NotificationView',
      component: NotificationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'salary',
      name: 'Salary',
      component: Salary,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'salary/new',
      name: 'SalaryCreate',
      component: SalaryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'salary/:salaryId/edit',
      name: 'SalaryEdit',
      component: SalaryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'salary/:salaryId/view',
      name: 'SalaryView',
      component: SalaryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'dynamic-field',
      name: 'DynamicField',
      component: DynamicField,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'dynamic-field/new',
      name: 'DynamicFieldCreate',
      component: DynamicFieldUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'dynamic-field/:dynamicFieldId/edit',
      name: 'DynamicFieldEdit',
      component: DynamicFieldUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'dynamic-field/:dynamicFieldId/view',
      name: 'DynamicFieldView',
      component: DynamicFieldDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'email',
      name: 'Email',
      component: Email,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'email/new',
      name: 'EmailCreate',
      component: EmailUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'email/:emailId/edit',
      name: 'EmailEdit',
      component: EmailUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'email/:emailId/view',
      name: 'EmailView',
      component: EmailDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'attachment',
      name: 'Attachment',
      component: Attachment,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'attachment/new',
      name: 'AttachmentCreate',
      component: AttachmentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'attachment/:attachmentId/edit',
      name: 'AttachmentEdit',
      component: AttachmentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'attachment/:attachmentId/view',
      name: 'AttachmentView',
      component: AttachmentDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
