import { Component, Inject, Provide, Vue } from 'vue-property-decorator';
import { IInvoice } from '@/shared/model/invoice.model';
import InvoiceService from '@/entities/invoice/invoice.service';
import AlertService from '@/shared/alert/alert.service';

/**
 * An utility service.
 */
@Component
export default class InvoiceUtils extends Vue {
  @Inject('alertService') protected alertService: () => AlertService;
  public editInvoice: IInvoice = null;
  protected editIndex = -1;
  public invoices: IInvoice[] = [];
  public itemsPerPageInvoice = 20;
  public queryCountInvoice: number = null;
  public pageInvoice = 1;
  public previousPageInvoice = 1;
  public propOrderInvoice = 'invoiceDate';
  public reverseInvoice = false;
  public totalItemsInvoice = 0;
  public showEditInvoice = false;

  public prepareEditInvoice(instance: IInvoice, index?: number): void {
    if (index != null) {
      this.editIndex = index;
    }
    //create copy to not modify reference in table
    this.editInvoice = JSON.parse(JSON.stringify(instance)) as typeof instance;
    this.showEditInvoice = true;
  }

  public showModalForNewInvoice(newInvoice): void {
    this.editIndex = -1;
    this.editInvoice = newInvoice;
    this.showEditInvoice = true;
  }

  public generateInvoiceDocument(invoice: IInvoice): void {
    // @ts-ignore
    this.$refs.invoice2Pdf?.generatePdf(invoice);
  }

  public updateInvoice(instance: IInvoice, cb?: (data: IInvoice) => any): void {
    this.invoiceService()
      .update(instance)
      .then(
        res => {
          if (cb) {
            cb(res.data);
          }
          this.handleUpdateHeaders(res.headers);
          //do nothing, only in case of an error
        },
        err => {
          this.alertService().showHttpError(this, err.response, err.response.data.detail);
        }
      );
  }

  public handleUpdateHeaders(headers) {
    if (headers) {
      for (const header in headers) {
        if ('x-care4youapp-info' == header) {
          const message = this.$t(headers[header]);
          (this.$root as any).$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'info',
            solid: true,
            autoHideDelay: 5000,
          });
        }
      }
    }
  }

  public saveEditInvoice(extended: boolean, cb?: (data: IInvoice) => any): void {
    if (this.editInvoice.id) {
      //update
      this.invoiceService()
        .update(this.editInvoice)
        .then(
          res => {
            if (extended == true) {
              this.invoiceService()
                .findExtended(this.editInvoice.id)
                .then(
                  res2 => {
                    if (cb) {
                      cb(res2);
                    }
                    this.handleUpdateHeaders(res.headers);
                    this.resetEditInvoice(res2);
                    this.closeEditDialog();
                  },
                  err2 => {
                    this.alertService().showHttpError(this, err2.response, err2.response.data.detail);
                  }
                );
            } else {
              if (cb) {
                cb(res.data);
              }
              this.handleUpdateHeaders(res.headers);
              this.resetEditInvoice(res.data);
              this.closeEditDialog();
            }
          },
          err => {
            this.alertService().showHttpError(this, err.response, err.response.data.detail);
          }
        );
    } else {
      //create
      this.invoiceService()
        .createWithDerivation(this.editInvoice)
        .then(
          res => {
            if (cb) {
              cb(res);
            }
            this.resetEditInvoice(res);
            this.closeEditDialog();
            this.loadInvoices();
          },
          err => {
            this.alertService().showHttpError(this, err.response, err.response.data.detail);
          }
        );
    }
  }

  public loadInvoices() {}

  public sortInvoice(): Array<any> {
    const result = [this.propOrderInvoice + ',' + (this.reverseInvoice ? 'desc' : 'asc')];
    if (this.propOrderInvoice !== 'id') {
      result.push('id');
    }
    return result;
  }

  public changeOrder(propOrder): void {
    this.propOrderInvoice = propOrder;
    this.reverseInvoice = !this.reverseInvoice;
    this.loadInvoices();
  }

  public handleSyncList(): void {
    this.pageInvoice = 1;
    this.loadInvoices();
  }

  public loadPage(page: number): void {
    if (page !== this.previousPageInvoice) {
      this.previousPageInvoice = page;
      this.loadInvoices();
    }
  }

  public closeEditDialog(): void {
    this.editInvoice = null;
    this.showEditInvoice = true;
  }

  private resetEditInvoice(updatedInvoice) {
    if (this.editIndex >= 0) {
      this.invoices[this.editIndex] = updatedInvoice;
    } else {
      this.invoices.push(updatedInvoice);
    }
    this.editInvoice = null;
    this.editIndex = -1;
  }

  public getPropertyOfObject(obj, propName): string {
    if (obj.hasOwnProperty(propName)) {
      return obj[propName];
    }
    return '';
  }

  public getCustomerCommunicationTypeValue(invoice): string {
    const channel = this.getPropertyOfObject(invoice, 'communicationChannel');
    if (channel == null || channel == '') {
      return '-';
    }

    return (
      this.$t('care4YouApp.CommunicationChannel.' + this.getPropertyOfObject(invoice, 'communicationChannel')) +
      ' (' +
      this.getPropertyOfObject(invoice, 'communicationValue') +
      ')'
    );
  }

  public getCustomerText(invoice): string {
    return (
      invoice.customerID +
      ' (' +
      this.getPropertyOfObject(invoice, 'customerLastName') +
      ', ' +
      this.getPropertyOfObject(invoice, 'customerFirstName') +
      ')'
    );
  }

  @Provide('invoiceService') protected invoiceService = () => new InvoiceService();
}
