var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("ribbon"),
      _vm._v(" "),
      _vm.renderHeader
        ? _c("div", { attrs: { id: "app-header" } }, [_c("jhi-navbar")], 1)
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "div",
            { class: _vm.withinCard == true ? "card jh-card" : "" },
            [_c("router-view")],
            1
          ),
          _vm._v(" "),
          _vm.renderFooter ? _c("jhi-footer") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }