import { IContact } from '@/shared/model/contact.model';
import { ICareAssignment } from '@/shared/model/care-assignment.model';
import { IRating } from '@/shared/model/rating.model';
import { ILanguageSkill } from '@/shared/model/language-skill.model';
import { IPersonalSkill } from '@/shared/model/personal-skill.model';
import { ICarerSuggestion } from '@/shared/model/carer-suggestion.model';
import { IAgency } from '@/shared/model/agency.model';

export interface ICarer {
  id?: number;
  birthday?: Date | null;
  healthInsuranceValidTo?: Date | null;
  imageContentType?: string | null;
  image?: string | null;
  smoking?: boolean | null;
  drivingLicence?: boolean | null;
  allergy?: string | null;
  workExperience?: string | null;
  character?: string | null;
  archive?: boolean | null;
  taxNumberRequestedAt?: Date | null;
  taxNumberRequestedFrom?: string | null;
  contact?: IContact | null;
  careAssignments?: ICareAssignment[] | null;
  ratings?: IRating[] | null;
  languageSkills?: ILanguageSkill[] | null;
  personalSkills?: IPersonalSkill[] | null;
  carerSuggestions?: ICarerSuggestion[] | null;
  agency?: IAgency | null;
  workingDays?: number | null;
  latestDepartureAt?: Date | null;
}

export class Carer implements ICarer {
  constructor(
    public id?: number,
    public birthday?: Date | null,
    public healthInsuranceValidTo?: Date | null,
    public imageContentType?: string | null,
    public image?: string | null,
    public smoking?: boolean | null,
    public drivingLicence?: boolean | null,
    public allergy?: string | null,
    public workExperience?: string | null,
    public character?: string | null,
    public archive?: boolean | null,
    public taxNumberRequestedAt?: Date | null,
    public taxNumberRequestedFrom?: string | null,
    public contact?: IContact | null,
    public careAssignments?: ICareAssignment[] | null,
    public ratings?: IRating[] | null,
    public languageSkills?: ILanguageSkill[] | null,
    public personalSkills?: IPersonalSkill[] | null,
    public carerSuggestions?: ICarerSuggestion[] | null,
    public agency?: IAgency | null
  ) {
    this.smoking = this.smoking ?? false;
    this.drivingLicence = this.drivingLicence ?? false;
    this.archive = this.archive ?? false;
  }
}
