import { Component, Inject, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import JhiDataUtils from '@/shared/data/data-utils.service';
import CareUtils from '@/shared/utils/care-utils.service';
import Vue2Filters from 'vue2-filters';
import AgencyService from '@/entities/agency/agency.service';
import { IInvoice } from '@/shared/model/invoice.model';
import InvoiceService from '@/entities/invoice/invoice.service';
import AlertService from '@/shared/alert/alert.service';
import { Salutation } from '@/shared/model/enumerations/salutation.model';
import dayjs from 'dayjs';

@Component({
  mixins: [Vue2Filters.mixin],
  components: {},
})
export default class Invoice2PdfComponent extends mixins(JhiDataUtils, CareUtils) {
  public selectedInvoice = null;
  public isGeneratingInvoice = false;
  @Inject('alertService') protected alertService: () => AlertService;

  public generatePdf(invoice: IInvoice) {
    if (invoice != null) {
      this.generateInvoiceDocument(invoice);
    }
  }

  public replaceWhiteSpace(text): string {
    if (!text) {
      return '';
    }
    return text.replace('\\n', '\n');
  }

  public replaceVariables(text): string {
    if (!text) {
      return '';
    }
    const sal = this.selectedInvoice['customerSalutation'];
    let greeting = 'Sehr geehrte';
    if (sal == Salutation.SIR) {
      greeting += 'r';
    }
    greeting += ' ';
    greeting += this.$t('care4YouApp.Salutation.' + sal);
    if (this.selectedInvoice['customerTitle'] != '' && this.selectedInvoice['customerTitle'] != undefined) {
      greeting += ' ' + this.selectedInvoice['customerTitle'];
    }
    let newText = text.replace('{{SALUTATION}}', greeting);
    newText = newText.replace('{{LASTNAME}}', this.selectedInvoice['customerLastName']);
    newText = newText.replace('{{FIRSTNAME}}', this.selectedInvoice['customerFirstName']);
    newText = newText.replace('{{MONTH}}', dayjs(this.selectedInvoice['invoiceCalculatedTo']).format('MMMM'));
    newText = newText.replace('{{INVOICE_DATE}}', dayjs(this.selectedInvoice['invoiceDate']).format('DD.MM.YYYY'));
    newText = newText.replace('{{CALCULATED_TO}}', dayjs(this.selectedInvoice['invoiceCalculatedTo']).format('DD.MM.YYYY'));

    return newText;
  }

  @Provide('invoiceService') protected invoiceService = () => new InvoiceService();

  private generateInvoiceDocument(invoice: IInvoice): void {
    this.isGeneratingInvoice = true;
    if (invoice.id == undefined) {
      this.alertService().showError(this, 'Invoice has to be saved before generating a PDF');
      return;
    }
    this.selectedInvoice = invoice;
    this.invoiceService()
      .retrieveInvoiceForPrinting(invoice.id)
      .then(
        res => {
          this.selectedInvoice = res;
          this.isGeneratingInvoice = false;
          // @ts-ignore
          this.$refs.invoice2Pdf.generatePdf();
        },
        err => {
          this.isGeneratingInvoice = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  @Provide('agencyService') private agencyService = () => new AgencyService();
}
