export enum FieldType {
  STRING = 'STRING',

  BOOLEAN = 'BOOLEAN',

  DATE = 'DATE',

  DATETIME = 'DATETIME',

  TIME = 'TIME',

  JSON = 'JSON',

  FLOAT = 'FLOAT',

  LONG = 'LONG',
}
