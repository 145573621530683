import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IDocument } from '@/shared/model/document.model';
import { DocumentType } from '@/shared/model/enumerations/document-type.model';

const baseApiUrl = 'api/documents';
const baseSearchApiUrl = 'api/_search/documents?query=';

export default class DocumentService {
  public getDocumentsByCustomerId(customerId: number): Promise<IDocument[]> {
    return new Promise<IDocument[]>((resolve, reject) => {
      axios
        .get(`api/documents/customer/${customerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getDocumentsByCarerId(carerId: number): Promise<IDocument[]> {
    return new Promise<IDocument[]>((resolve, reject) => {
      axios
        .get(`api/documents/carer/${carerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public search(query, paginationQuery): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseSearchApiUrl}${query}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<IDocument> {
    return new Promise<IDocument>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByEntityTypeAndId(entityType: string, entityId: number): Promise<IDocument[]> {
    return new Promise<IDocument[]>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}-for-entity/${entityType}/${entityId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IDocument): Promise<IDocument> {
    return new Promise<IDocument>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IDocument): Promise<IDocument> {
    return new Promise<IDocument>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IDocument): Promise<IDocument> {
    return new Promise<IDocument>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAllDocumentsByDocumentType(documentType: DocumentType): Promise<IDocument[]> {
    return new Promise<IDocument[]>((resolve, reject) => {
      axios
        .get(`api/documents-by-type/${documentType}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAllDocumentsByTaskIdAndDocumentType(taskId: number, documentType: DocumentType): Promise<IDocument[]> {
    return new Promise<IDocument[]>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${taskId}/${documentType}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
