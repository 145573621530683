import axios from 'axios';

import { ICustomer } from '@/shared/model/customer.model';
import { IPatientCareAttribute } from '@/shared/model/patient-care-attribute.model';

const baseApiUrl = 'api/questionnaires';

export default class QuestionnaireService {
  public findByTaskId(taskId: string): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${taskId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByFragebogenNummer(fragebogenNummer: string): Promise<Object> {
    return new Promise<Object[]>((resolve, reject) => {
      axios
        .get(`api/online-fragebogen/${fragebogenNummer}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAgencyDataForQuestionnaire(agencyId: number): Promise<Object> {
    return new Promise<Object[]>((resolve, reject) => {
      axios
        .get(`api/online-fragebogen/agency-data/${agencyId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateByFragebogenNummer(fragebogenNummer: string, attributes: IPatientCareAttribute[]): Promise<IPatientCareAttribute[]> {
    return new Promise<IPatientCareAttribute[]>((resolve, reject) => {
      axios
        .put(`api/online-fragebogen/${fragebogenNummer}`, attributes)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getNewQuestionnaire(): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .get(`api/new-questionnaire`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICustomer, lite: string): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}` + lite, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createFull(data: any): Promise<any> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/full`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
