import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { CustomerState } from '@/shared/model/enumerations/customer-state.model';

const baseApiUrl = 'api/customers-overview';
const baseSearchApiUrl = 'api/_search/customers?query=';

export default class CustomerOverviewService {
  public search(state: CustomerState, query, incArchivedCustomers: boolean, paginationQuery): Promise<any> {
    const urlAddition = incArchivedCustomers === true ? '/with-archived-customers' : '';
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`api/_search/customers/${state}${urlAddition}?query=${query}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(state: CustomerState, incArchivedCustomers: boolean, paginationQuery?: any): Promise<any> {
    const urlAddition = incArchivedCustomers === true ? '/with-archived-customers' : '';
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/${state}${urlAddition}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public countOnCare(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/count/oncare`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public countOnRequest(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/count/onrequest`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
