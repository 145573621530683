import { IApplicationUser } from '@/shared/model/application-user.model';

export interface IWatcher {
  id?: number;
  watcherKey?: string | null;
  parameter?: string | null;
  notification?: boolean | null;
  mail?: boolean | null;
  applicationUser?: IApplicationUser | null;
}

export class Watcher implements IWatcher {
  constructor(
    public id?: number,
    public watcherKey?: string | null,
    public parameter?: string | null,
    public notification?: boolean | null,
    public mail?: boolean | null,
    public applicationUser?: IApplicationUser | null
  ) {}
}
