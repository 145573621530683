var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.collapse-patient",
              modifiers: { "collapse-patient": true },
            },
          ],
          attrs: { variant: "primary" },
        },
        [_vm._v(_vm._s(_vm.getPatientLabel()))]
      ),
      _vm._v(" "),
      _vm.carer
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-carer",
                  modifiers: { "collapse-carer": true },
                },
              ],
              attrs: { variant: "primary" },
            },
            [_vm._v(_vm._s(_vm.getCarerLabel()))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.activitiesLoaded
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-protocol",
                  modifiers: { "collapse-protocol": true },
                },
              ],
              attrs: { variant: "primary" },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("care4YouApp.activity.newCustomerProcessProtocol")
                  ) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-collapse",
        { staticClass: "mt-2", attrs: { id: "collapse-patient" } },
        [
          _c("b-card", [
            _c(
              "div",
              [
                _c("CustomerDetail", {
                  attrs: { customerIdToDisplay: _vm.patient?.customer?.id },
                  on: { customerloaded: _vm.handleCustomerLoaded },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { staticClass: "mt-2", attrs: { id: "collapse-carer" } },
        [
          _c("b-card", [
            _c(
              "div",
              [
                _c("carer-detail", {
                  attrs: {
                    paramsForOverview: { carerId: _vm.carer?.id, tabIndex: 0 },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.activitiesLoaded
        ? _c(
            "b-collapse",
            {
              staticClass: "mt-2",
              attrs: { id: "collapse-protocol", visible: "" },
            },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    { attrs: { "align-h": "between" } },
                    [
                      _c("b-col", [
                        _c(
                          "table",
                          {
                            staticClass: "table table-striped",
                            attrs: { "aria-describedby": "activities" },
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass: "col-1 text-center",
                                    attrs: { scope: "row" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("care4YouApp.activity.type")
                                          ),
                                        },
                                      },
                                      [_vm._v("Type")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass: "col-7",
                                    attrs: { scope: "row" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("care4YouApp.activity.text")
                                          ),
                                        },
                                      },
                                      [_vm._v("Text")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass: "col-2",
                                    attrs: { scope: "row" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "care4YouApp.activity.createdAt"
                                            )
                                          ),
                                        },
                                      },
                                      [_vm._v("Created At")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass: "col-2",
                                    attrs: { scope: "row" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "care4YouApp.activity.updatedAt"
                                            )
                                          ),
                                        },
                                      },
                                      [_vm._v("Updated At")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.activities,
                                function (activity, index) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "align-middle text-center",
                                        },
                                        [
                                          _vm.getActivityTypeIcon(
                                            activity.type
                                          ) != null
                                            ? _c("font-awesome-icon", {
                                                attrs: {
                                                  title: _vm.$t(
                                                    "care4YouApp.ActivityType." +
                                                      activity.type
                                                  ),
                                                  icon: _vm.getActivityTypeIcon(
                                                    activity.type
                                                  ),
                                                  size: "xs",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(activity.text))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDateTime")(
                                            activity.createdAt
                                          )
                                        )
                                      ),
                                      _c("br"),
                                      _vm._v(_vm._s(activity.createdBy)),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDateTime")(
                                            activity.updatedAt
                                          )
                                        )
                                      ),
                                      _c("br"),
                                      _vm._v(_vm._s(activity.updatedBy)),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "2" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value: "editActivity",
                                  expression: "'editActivity'",
                                },
                              ],
                              staticClass: "btn mx-auto",
                              attrs: { variant: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.getNewActivity()
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "plus" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "care4YouApp.activity.home.createLabel"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v(" Create Activity ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.editActivity
        ? _c(
            "b-modal",
            {
              ref: "editActivity",
              attrs: { id: "editActivity", size: "xl" },
              model: {
                value: _vm.showEditActivity,
                callback: function ($$v) {
                  _vm.showEditActivity = $$v
                },
                expression: "showEditActivity",
              },
            },
            [
              _c(
                "span",
                { attrs: { slot: "modal-title" }, slot: "modal-title" },
                [
                  _c(
                    "span",
                    {
                      attrs: { id: "care4YouApp.activity.edit.question" },
                      domProps: {
                        textContent: _vm._s(_vm.$t("entity.edit.title")),
                      },
                    },
                    [_vm._v("Edit activity details")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "form-group col-1" }, [
                      _c(
                        "h3",
                        { staticClass: "align-middle form-control" },
                        [
                          _vm.getActivityTypeIcon(_vm.editActivity?.type) !=
                          null
                            ? _c("font-awesome-icon", {
                                attrs: {
                                  icon: _vm.getActivityTypeIcon(
                                    _vm.editActivity?.type
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group col-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-control-label",
                          attrs: { for: "activity-type" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("care4YouApp.activity.type")
                            ),
                          },
                        },
                        [_vm._v("Type")]
                      ),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editActivity.type,
                              expression: "editActivity.type",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "activity-type", name: "type" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.editActivity,
                                "type",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(
                          _vm.activityTypeOptions,
                          function (activityTypeOption) {
                            return _c(
                              "option",
                              {
                                key: activityTypeOption,
                                attrs: {
                                  label: _vm.$t(
                                    "care4YouApp.ActivityType." +
                                      activityTypeOption
                                  ),
                                },
                                domProps: { value: activityTypeOption },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(activityTypeOption) +
                                    "\n              "
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "form-control-label",
                          attrs: { for: "activity-text" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("care4YouApp.activity.text")
                            ),
                          },
                        },
                        [_vm._v("Text")]
                      ),
                      _vm._v(" "),
                      _c("b-form-textarea", {
                        staticClass: "form-control",
                        attrs: {
                          id: "activity-text",
                          "max-rows": "10",
                          name: "text",
                          rows: "6",
                          type: "text",
                          placeholder: _vm.$t("care4YouApp.activity.text"),
                        },
                        model: {
                          value: _vm.editActivity.text,
                          callback: function ($$v) {
                            _vm.$set(_vm.editActivity, "text", $$v)
                          },
                          expression: "editActivity.text",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      domProps: {
                        textContent: _vm._s(_vm.$t("entity.action.cancel")),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.closeEditActivityDialog()
                        },
                      },
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        id: "jhi-confirm-delete-activity",
                        type: "button",
                      },
                      domProps: {
                        textContent: _vm._s(_vm.$t("entity.action.save")),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveEditActivity()
                        },
                      },
                    },
                    [_vm._v("\n        Save\n      ")]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [_c("p")])
  },
]
render._withStripped = true

export { render, staticRenderFns }