import { Component, Inject, Prop, PropSync, Provide, Watch } from 'vue-property-decorator';
import CustomerDetailService from './customer-detail.service';
import { Customer, ICustomer } from '@/shared/model/customer.model';
import { Salutation } from '@/shared/model/enumerations/salutation.model';
import { Gender } from '@/shared/model/enumerations/gender.model';
import { IAgency } from '@/shared/model/agency.model';
import { CustomerState } from '@/shared/model/enumerations/customer-state.model';
import { CareBeginType } from '@/shared/model/enumerations/care-begin-type.model';
import { CareDuration } from '@/shared/model/enumerations/care-duration.model';
import { CommunicationType } from '@/shared/model/enumerations/communication-type.model';
import { ApplicationUser } from '@/shared/model/application-user.model';
import { mixins } from 'vue-class-component';
import JhiDataUtils from '@/shared/data/data-utils.service';
import TaskDataUtils, { EntityData, ProcessVariable, TaskData } from '@/shared/data/task-data-utils.service';
import ApplicationUserService from '@/entities/application-user/application-user.service';
import DocumentService from '@/entities/document/document.service';
import { EntityType } from '@/shared/model/enumerations/entity-type.model';
import { PatientCareAttribute } from '@/shared/model/patient-care-attribute.model';
import { IPatient, Patient } from '@/shared/model/patient.model';
import CareUtils from '@/shared/utils/care-utils.service';
import InvoiceUtils from '@/shared/utils/invoice-utils.service';
import AgencyService from '@/entities/agency/agency.service';
import dayjs from 'dayjs';
import deepCopy from '@/shared/functions/deep-copy';
import { ZONED_DATE_TIME_SERVER_FORMAT } from '@/shared/date/filters';
import { CommunicationChannel } from '@/shared/model/enumerations/communication-channel.model';
import { Document, IDocument } from '@/shared/model/document.model';
import { DocumentType } from '@/shared/model/enumerations/document-type.model';
import { DocumentStatus } from '@/shared/model/enumerations/document-status.model';
import QuestionnaireService from '@/questionnaire/questionnaire.service';
import { ValueType } from '@/shared/model/enumerations/value-type.model';
import { Communication } from '@/shared/model/communication.model';
import RouterUtils from '@/shared/utils/router-utils.service';
import ActivityUtils from '@/shared/utils/activity-utils.service';
import { Activity, IActivity } from '@/shared/model/activity.model';
import CareAssignmentService from '@/entities/care-assignment/care-assignment.service';
import { ICareAssignment } from '@/shared/model/care-assignment.model';
import SalaryUtils from '@/shared/utils/salary-utils.service';
import { ISalary, Salary } from '@/shared/model/salary.model';
import PostboxUtils from '@/shared/utils/postbox-utils.service';
import PostboxService from '@/postbox/postbox.service';
import { CarerSwitch } from '@/shared/model/carer-switch.model';
import { CareTerminationReason } from '@/shared/model/enumerations/care-termination-reason';
import { CareAssignmentTermination } from '@/shared/model/care-assignment-termination.model';
import AccountService from '@/account/account.service';
import { IDynamicField } from '@/shared/model/dynamic-field.model';
import { v4 as uuidv4 } from 'uuid';
import PatientService from '@/entities/patient/patient.service';
import SettingService from '@/entities/setting/setting.service';
import { FieldType } from '@/shared/model/enumerations/field-type.model';
import { IBpmUserTaskExtended } from '@/shared/model/bpm-user-task-extended.model';

const validations: any = {};

@Component({
  validations,
})
export default class CustomerDetailComponent extends mixins(
  JhiDataUtils,
  TaskDataUtils,
  CareUtils,
  InvoiceUtils,
  ActivityUtils,
  SalaryUtils,
  RouterUtils,
  PostboxUtils
) {
  @PropSync('customerIdToDisplay') patientForOverview: number;
  @PropSync('tabIndexToEdit') tabIndexForEdit: number;
  @Prop() paramsForUserTask;

  public customerId = -1;
  public customer?: ICustomer = null;
  public documents: IDocument[] = [];
  public newDocument: IDocument = new Document();
  public userTasks: IBpmUserTaskExtended[] = [];
  public documentTypeValues: string[] = Object.keys(DocumentType);
  public documentStatusValues: string[] = Object.keys(DocumentStatus);
  public salutationValues: string[] = Object.keys(Salutation);
  public genderValues: string[] = Object.keys(Gender);
  public customerStateValues: string[] = Object.keys(CustomerState);
  public careBeginTypeValues: string[] = Object.keys(CareBeginType);
  public careDurationValues: string[] = Object.keys(CareDuration);
  public communicationTypeValues: string[] = Object.keys(CommunicationType);
  public communicationChannelValues: string[] = Object.keys(CommunicationChannel);
  public careTerminationReasonOptions: string[] = Object.keys(CareTerminationReason);
  public applicationUsers: ApplicationUser[] = [];
  public agencies: IAgency[] = [];
  public contactPersonTemplate = null;
  public patientTemplate = null;
  public tabIndex = 0;
  public isCarerSwitchRunning = false;
  public isSaving = false;
  public isLoadingData = true;
  public isLoadingDocuments = true;
  public isLoadingInvoices = true;
  public isLoadingCustomerUserTasks = true;
  public customerUserTaskPage = 1;
  public previousCustomerUserTaskPage = 1;
  public customerUserTaskPropOrder = 'dueDate,created';
  public customerUserTaskReverse = true;
  public customerUserTaskTotalItems = 0;
  public customerUserTaskQueryCount = 0;
  public userTasksItemsPerPage = 10;
  public currentLanguage = '';
  public title = 'title';
  public withProcess = true;
  protected isCreate = false;
  public contactPersonId = 0;
  public patientId = 0;
  public pushNav = null;
  public routeName = 'CustomerDetail';
  public userTaskId = null;
  public carerSwitch = new CarerSwitch();
  public showTerminateCareAssignment = false;
  public careAssignmentTermination = new CareAssignmentTermination();
  public careAssignmentForDeletion: ICareAssignment = null;
  private hasAnyAuthorityValues = {};
  private onlineQuestionnaire = false;
  private dynamicFields = null;
  private hasQuestionnaire = [];
  public isNewCustomer = false;

  @Inject('customerDetailService')
  protected customerDetailService: () => CustomerDetailService;
  @Inject('postboxService') protected postboxService: () => PostboxService;
  @Inject('accountService') private accountService: () => AccountService;

  @Provide('patientService')
  private patientService = () => new PatientService();
  @Provide('settingService')
  private settingService = () => new SettingService();

  private callParams = null;

  @Provide('questionnaireService') protected questionnaireService = () => new QuestionnaireService();
  @Provide('careAssignmentService') private careAssignmentService = () => new CareAssignmentService();
  public initialOpenAccordions: string[] = [];
  protected openAccordions: string[] = [];

  private customerCreatedWithoutProcess = false;
  public careAssignments: ICareAssignment[] = [];

  public toggleAccordion(index: string): void {
    const ind = this.openAccordions.indexOf(index);
    if (ind >= 0) {
      this.openAccordions.splice(ind, 1);
    } else {
      this.openAccordions.push(index);
    }
  }

  public getToggleAccordion(index: string): boolean {
    return this.openAccordions.indexOf(index) >= 0;
  }

  public getInitialOpenAccordion(index: string): boolean {
    return this.initialOpenAccordions.indexOf(index) >= 0;
  }

  beforeUpdate() {
    if (this.customerCreatedWithoutProcess) {
      this.isCreate = false;
      this.doInitialization(this, this.callParams, '');
      this.customerCreatedWithoutProcess = false;
    }
  }
  @Watch('pushNav')
  navigationTargetChanged(value, oldValue) {
    if (this.pushNav) {
      this.push(this.pushNav.url, this.pushNav.params);
      this.pushNav = null;
    }
  }

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.doInitialization(vm, to.params, to.name);
    });
  }

  doInitialization(vm, params, routeName) {
    this.onlineQuestionnaire = false;
    if (routeName === 'CreateOnlineQuestionnaire') {
      this.onlineQuestionnaire = true;
    }

    this.pushNav = null;
    this.customerId = -1;
    this.customer = null;
    this.documents = [];
    this.newDocument = new Document();
    this.userTasks = [];
    this.documentTypeValues = Object.keys(DocumentType);
    this.documentStatusValues = Object.keys(DocumentStatus);
    this.salutationValues = Object.keys(Salutation);
    this.genderValues = Object.keys(Gender);
    this.customerStateValues = Object.keys(CustomerState);
    this.careBeginTypeValues = Object.keys(CareBeginType);
    this.careDurationValues = Object.keys(CareDuration);
    this.communicationTypeValues = Object.keys(CommunicationType);
    this.communicationChannelValues = Object.keys(CommunicationChannel);
    this.applicationUsers = [];
    this.agencies = [];
    this.contactPersonTemplate = null;
    this.patientTemplate = null;
    this.tabIndex = 0;
    this.isCarerSwitchRunning = false;
    this.isSaving = false;
    this.isLoadingData = true;
    this.isLoadingDocuments = true;
    this.isLoadingInvoices = true;
    this.currentLanguage = '';
    this.title = 'title';
    this.withProcess = true;
    this.isCreate = false;

    if (params) {
      this.callParams = params;
    }

    vm.initRelationships();
    if (params && params.taskId) {
      vm.readTaskData(params.taskId, vm.readTaskDataAdditional);
    } else if (params.customerId) {
      this.readAllCustomerData(params.customerId);
    } else {
      vm.getInitialCustomer();
    }

    if (params.withProcess || this.onlineQuestionnaire) {
      vm.withProcess = true;
    } else {
      vm.withProcess = false;
    }

    if (params.contactPersonId) {
      vm.setOpenAccordionContactPerson(params.contactPersonId);
    } else if (params.patientId) {
      vm.setOpenAccordionPatient(params.patientId);
    }

    if (params.tabIndex) {
      if (params.tabIndex < 0) {
        vm.setTabIndex(0);
      } else {
        vm.setTabIndex(params.tabIndex);
      }
    } else {
      vm.setTabIndex(0);
    }
  }

  private readTaskDataAdditional(taskData: TaskData) {
    this.readAllCustomerData(this.getProcessVariableFromTaskData(taskData, 'CUSTOMER_ID'));
  }

  private readAllCustomerData(customerId) {
    this.customerId = customerId;

    this.retrieveCustomerData(customerId);
    this.retrieveCustomerDocuments(customerId);
    this.prepareNewDocument(customerId);
    this.loadInvoicesForCustomer(customerId);
    this.loadActivitiesForCustomer(customerId);
    this.loadUserTasksForCustomer(customerId);
    this.getInitialCareAssignmentsForCustomer(customerId);
    this.loadCustomerFlags(customerId);
  }

  private replaceNav() {
    if (this.userTaskId) {
      this.replace(this.routeName, {
        customerId: this.customerId,
        tabIndex: this.tabIndex,
        contactPersonId: this.contactPersonId,
        patientId: this.patientId,
        taskId: this.userTaskId,
      });
    } else {
      this.replace(this.routeName, {
        customerId: this.customerId,
        tabIndex: this.tabIndex,
        contactPersonId: this.contactPersonId,
        patientId: this.patientId,
      });
    }
  }
  public switchTab(tabIndex) {
    if (!this.patientForOverview && !this.isCreate) {
      this.tabIndex = tabIndex;

      if (this.$route.params['tabIndex'] !== String(this.tabIndex)) {
        this.replaceNav();
      }
    }
  }

  public editPatient(patient) {
    if (!this.patientForOverview && !this.isCreate) {
      this.patientId = patient.id;

      if (this.$route.params['patientId'] !== String(this.patientId)) {
        this.replaceNav();
      }

      this.pushNav = {
        url: 'CustomerPatientUpdate',
        params: { customerId: this.customerId, patientId: this.patientId },
      };
    }
  }

  public addPatientLater() {
    if (!this.patientForOverview && !this.isCreate) {
      this.pushNav = {
        url: 'CustomerPatientAdd',
        params: { customerId: this.customerId },
      };
    }
  }

  public editContactPerson(contactPerson) {
    if (!this.patientForOverview && !this.isCreate) {
      this.contactPersonId = contactPerson.id;

      if (this.$route.params['contactPersonId'] !== String(this.contactPersonId)) {
        this.replaceNav();
      }

      this.pushNav = {
        url: 'CustomerContactPersonUpdate',
        params: { customerId: this.customerId, contactPersonId: this.contactPersonId },
      };
    }
  }

  public initialCareAssignmentExistent(): boolean {
    return this.careAssignments && this.careAssignments.length > 0;
  }

  public getInitialCareAssignmentsForCustomer(customerId: number): void {
    this.isLoadingData = true;
    this.careAssignmentService()
      .getInitialCareAssignmentsForCustomer(customerId)
      .then(res => {
        this.careAssignments = res;
        this.isLoadingData = false;
      })
      .catch(error => {
        this.isLoadingData = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public archiveCustomer() {
    this.isSaving = true;
    this.customerDetailService()
      .archiveCustomer(this.customer.id)
      .then(param => {
        this.customer.archive = true;
        const message = this.$t('care4YouApp.customer.archived', { param: param.id });
        (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
        this.isSaving = false;
      })
      .catch(error => {
        this.isSaving = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public reactivateCustomer() {
    this.isSaving = true;
    this.customerDetailService()
      .reactivateCustomer(this.customer.id)
      .then(param => {
        this.customer.archive = false;
        const message = this.$t('care4YouApp.customer.reactivated', { param: param.id });
        (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
        this.isSaving = false;
      })
      .catch(error => {
        this.isSaving = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public readCustomerByTaskId(taskId: string): void {
    this.isLoadingData = true;
    this.isCreate = false;
    this.title = 'updateLabel';
    this.taskId = taskId;
    this.questionnaireService()
      .findByTaskId(taskId)
      .then(res => {
        try {
          this.customer = this.transformPatientCareAttributes(res);
          this.contactPersonTemplate =
            this.customer.contactPersons != null && this.customer.contactPersons.length > 0
              ? deepCopy(this.customer.contactPersons[0])
              : null;
          this.customerId = this.customer.id;
          this.patientTemplate =
            this.customer.patients != null && this.customer.patients.length > 0 ? deepCopy(this.customer.patients[0]) : null;
        } catch (e) {
          this.alertService().showError(this, e.message);
        }
        this.isLoadingData = false;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
        this.isLoadingData = false;
      });
  }

  private isFragebogenReadOnly() {
    return !this.isCreate || this.customer.archive === true || this.patientForOverview != null;
  }

  public getInitialCustomer(): void {
    this.isLoadingData = true;
    this.isCreate = true;
    this.title = 'createLabel';
    this.questionnaireService()
      .getNewQuestionnaire()
      .then(res => {
        this.customer = this.transformPatientCareAttributes(res);
        this.contactPersonTemplate = this.customer.contactPersons.length > 0 ? deepCopy(this.customer.contactPersons[0]) : null;
        this.patientTemplate = this.customer.patients.length > 0 ? deepCopy(this.customer.patients[0]) : null;
        this.deriveOpenAccordions();
        this.isLoadingData = false;
        this.isLoadingDocuments = false;
        this.isLoadingInvoices = false;
        this.isLoadingActivities = false;
      })
      .catch(error => {
        this.isLoadingData = false;
        this.isLoadingDocuments = false;
        this.isLoadingInvoices = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  @Watch('patientForOverview')
  onDataChanged(patient, patientOld) {
    if (patient) {
      this.initRelationships();
      this.retrieveCustomerData(patient);
      this.retrieveCustomerDocuments(patient);
      this.prepareNewDocument(patient);
      this.loadInvoicesForCustomer(patient);
      this.loadCustomerFlags(patient);
      this.loadActivitiesForCustomer(patient);
      this.loadUserTasksForCustomer(patient);
      this.setTabIndex(0);
    }
  }

  @Watch('tabIndexForEdit')
  onIndexChanged(iNew, iOld) {
    if (iNew) {
      this.setTabIndex(iNew);
    }
  }

  @Watch('paramsForUserTask')
  onParamsForUserTaskDataChanged(paramsForUserTaskNew, paramsForUserTaskOld) {
    if (!paramsForUserTaskOld.customerId) {
      if (paramsForUserTaskNew.customerId) {
        this.initRelationships();
        this.retrieveCustomerData(paramsForUserTaskNew.customerId);
        this.retrieveCustomerDocuments(paramsForUserTaskNew.customerId);
        this.prepareNewDocument(paramsForUserTaskNew.customerId);
        this.loadInvoicesForCustomer(paramsForUserTaskNew.customerId);
        this.loadCustomerFlags(paramsForUserTaskNew.customerId);
        this.loadActivitiesForCustomer(paramsForUserTaskNew.customerId);
        this.loadUserTasksForCustomer(paramsForUserTaskNew.customerId);
      }

      if (paramsForUserTaskNew.tabIndex) {
        this.setTabIndex(paramsForUserTaskNew.tabIndex);
      }

      if (paramsForUserTaskNew.contactPersonId) {
        this.setOpenAccordionContactPerson(paramsForUserTaskNew.contactPersonId);
      }

      if (paramsForUserTaskNew.patientId) {
        this.setOpenAccordionPatient(paramsForUserTaskNew.patientId);
      }

      if (paramsForUserTaskNew.routeName) {
        this.routeName = paramsForUserTaskNew.routeName;
      }

      if (paramsForUserTaskNew.userTaskId) {
        this.userTaskId = paramsForUserTaskNew.userTaskId;
      }
    }
  }

  public prepareNewDocument(customerId: number): void {
    const newDoc = new Document();
    newDoc.documentName = 'Neues Dokument';
    newDoc.parentKey = customerId;
    newDoc.parentType = EntityType.CUSTOMER;
    this.newDocument = newDoc;
  }

  public setTabIndex(index: number): void {
    this.tabIndex = index;
  }

  created(): void {
    this.currentLanguage = this.$store.getters.currentLanguage;
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
      }
    );
  }

  public previousState(): void {
    this.$router.go(-1);
  }

  public initRelationships(): void {
    this.agencyService()
      .retrieve()
      .then(res => {
        this.agencies = res.data;
      });
    this.applicationUserService()
      .retrieve()
      .then(res => {
        this.applicationUsers = res.data;
      });
  }

  public acceptButtonVisible(): boolean {
    return true;
  }

  public complete(): void {
    const data = this.buildDataObject(true, [], [], []);

    this.callService(data, this.$t('care4YouApp.postbox.taskFinished', { param: this.$t(this.taskId) }));
  }

  public saveTask(): void {
    const data = this.buildDataObject(false, [], [], []);

    this.callService(data, this.$t('care4YouApp.carerSuggestion.saved'));
  }

  public getDateFormatedWithDefault(val, def) {
    if (val) {
      return this.$options.filters.formatDate(val);
    }

    return def;
  }

  public convertDateTimeFromServer(date: Date): string {
    if (date && dayjs(date).isValid()) {
      return dayjs(date).format('YYYY-MM-DDTHH:mm');
    }
    return null;
  }

  public updateZonedDateTimeField(index, field, event) {
    if (event.target.value) {
      const dat = dayjs(event.target.value, 'YYYY-MM-DDTHH:mm');
      const formatedDat = dat.format(ZONED_DATE_TIME_SERVER_FORMAT);
      this.customer.careAssignments[index][field] = formatedDat;
    } else {
      this.customer.careAssignments[index][field] = null;
    }
  }

  public updateTimeRangeField(attribute, event, part: number) {
    const parts = attribute.value == null ? [] : attribute.value.split('-');
    parts[part] = event;
    attribute.value = parts.join('-');
  }

  public convertTimeRangeFromServer(timerange: string, part: number) {
    if (timerange == null) {
      return '';
    }

    const parts = timerange.split('-');
    if (parts.length > part) {
      return parts[part];
    }

    return '';
  }

  @Provide('agencyService') protected agencyService = () => new AgencyService();
  @Provide('applicationUserService') protected applicationUserService = () => new ApplicationUserService();

  @Provide('documentService') protected documentService = () => new DocumentService();

  public isInvoiceEditable() {
    return this.tabIndexForEdit == 6 || this.tabIndexForEdit == null || (!this.patientForOverview && !this.isCreate);
  }

  public isActivityEditable() {
    return this.tabIndexForEdit == 7 || this.tabIndexForEdit == null || (!this.patientForOverview && !this.isCreate);
  }

  public isSalaryEditable() {
    return this.tabIndexForEdit == 4 || this.tabIndexForEdit == null || (!this.patientForOverview && !this.isCreate);
  }

  protected retrieveCustomerData(customerId): void {
    this.isLoadingData = true;
    this.customerDetailService()
      .retrieveData(customerId)
      .then(param => {
        this.customer = this.transformPatientCareAttributes(param);
        this.salaries = this.customer.salaries;
        this.customerId = customerId;
        this.deriveOpenAccordions();

        this.isNewCustomer = this.customer.careAssignments.length == 0;

        this.$emit('customerloaded', this.customer);

        this.loadDynamicFields(this.customerId);
      })
      .catch(error => {
        this.isLoadingData = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public doSwitchCarer(): void {
    const parameters = {
      USER_TASK_DUE_DATE: JSON.stringify(dayjs(new Date()).format('YYYY-MM-DD')),
      CUSTOMER_ID: this.customerId,
      ASSIGNMENT_END_DATE: JSON.stringify(this.carerSwitch.endDate),
      NOTE: JSON.stringify(this.carerSwitch.note),
      SWITCH_CARER: true,
    };

    this.bpmProcessManagementService()
      .startProcess('switch-carer', parameters)
      .then(res => {
        this.closeSwitchCarerDialog();
        this.loadCustomerFlags(this.customerId);

        (this.$root as any).$bvToast.toast(this.$t('care4YouApp.postbox.process.started', { processid: res }), {
          toaster: 'b-toaster-bottom-right',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public getNewInvoice(): void {
    //this.isLoadingInvoices = true;
    this.invoiceService()
      .createForCustomer(this.customerId)
      .then(
        res => {
          this.showModalForNewInvoice(res);
          this.isLoadingInvoices = false;
        },
        err => {
          this.isLoadingInvoices = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public getNewActivity(): void {
    const newActivity = new Activity();
    newActivity.parentKey = this.customer.id;
    newActivity.parentType = EntityType.CUSTOMER;
    this.showModalForNewActivity(newActivity);
  }

  public getNewSalary(): void {
    const newSalary = new Salary();
    newSalary.amount = 0;
    this.showModalForNewSalary(this.customer.id, newSalary);
  }

  public loadInvoicesForCustomer(customerId: number): void {
    this.isLoadingInvoices = true;
    const paginationQuery = {
      page: this.pageInvoice - 1,
      size: this.itemsPerPageInvoice,
      sort: this.sortInvoice(),
    };
    this.invoiceService()
      .retrieveForCustomer(customerId, paginationQuery)
      .then(
        res => {
          this.invoices = res.data;
          this.totalItemsInvoice = Number(res.headers['x-total-count']);
          this.queryCountInvoice = this.totalItemsInvoice;
          this.isLoadingInvoices = false;
        },
        err => {
          this.isLoadingInvoices = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public loadUserTasksForCustomerPage() {
    if (this.customerUserTaskPage !== this.previousCustomerUserTaskPage) {
      this.previousCustomerUserTaskPage = this.customerUserTaskPage;
      this.loadUserTasksForCustomer(this.customerId);
    }
  }

  public loadUserTasksForCustomer(customerId: number): void {
    this.isLoadingCustomerUserTasks = true;
    const paginationQuery = {
      page: this.customerUserTaskPage - 1,
      size: this.userTasksItemsPerPage,
      sort: this.sortCustomerUserTasks(),
    };
    const promise = this.postboxService().getTasksForCustomer(customerId, paginationQuery);
    promise.then(
      res => {
        this.userTasks = res.data;
        this.customerUserTaskTotalItems = res.headers['x-total-count'];
        this.customerUserTaskQueryCount = this.customerUserTaskTotalItems;
        this.isLoadingCustomerUserTasks = false;
      },
      err => {
        this.isLoadingCustomerUserTasks = false;
        this.alertService().showHttpError(this, err.response);
      }
    );
  }

  public changeUserTaskOrder(order) {
    this.customerUserTaskPropOrder = order;
    this.customerUserTaskReverse = !this.customerUserTaskReverse;
    this.loadUserTasksForCustomer(this.customerId);
  }

  public loadActivitiesForCustomer(customerId: number): void {
    this.isLoadingActivities = true;
    const paginationQuery = {
      page: this.activityPage - 1,
      size: this.activityItemsPerPage,
      sort: this.sortActivity(),
    };
    this.activityService()
      .retrieveForCustomer(customerId, paginationQuery)
      .then(
        res => {
          this.activities = res.data;
          this.activityTotalItems = Number(res.headers['x-total-count']);
          this.activityQueryCount = this.activityTotalItems;
          this.isLoadingActivities = false;
        },
        err => {
          this.isLoadingActivities = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  protected setOpenAccordionPatient(id: number): void {
    this.initialOpenAccordions.push('accordionPatients-' + id);
    this.openAccordions.push('accordionPatients-' + id);
  }

  protected setOpenAccordionContactPerson(id: number): void {
    this.initialOpenAccordions.push('accordionContacts-' + id);
    this.openAccordions.push('accordionContacts-' + id);
  }

  protected deriveOpenAccordions(): void {
    const contactExists =
      this.initialOpenAccordions.filter(value => {
        return value.startsWith('accordionContacts');
      }).length > 0;
    const patientExists =
      this.initialOpenAccordions.filter(value => {
        return value.startsWith('accordionPatients');
      }).length > 0;
    if (!contactExists && this.customer.contactPersons.length > 0) {
      this.initialOpenAccordions.push('accordionContacts-' + this.customer.contactPersons[0].id);
      this.openAccordions.push('accordionContacts-' + this.customer.contactPersons[0].id);
    }
    if (!patientExists && this.customer.patients.length > 0) {
      this.initialOpenAccordions.push('accordionPatients-' + this.customer.patients[0].id);
      this.openAccordions.push('accordionPatients-' + this.customer.patients[0].id);
    }
  }

  protected retrieveCustomerDocuments(customerId): void {
    this.isLoadingDocuments = true;
    this.documentService()
      .findByEntityTypeAndId(EntityType.CUSTOMER, customerId)
      .then(param => {
        this.documents = param;
        this.isLoadingDocuments = false;
      })
      .catch(error => {
        this.isLoadingDocuments = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  protected transformPatientCareAttributes(cust: Customer): Customer {
    cust.patients.forEach(function (pat: Patient) {
      pat.careAttributes.forEach(function (pca: PatientCareAttribute) {
        pca.value = JSON.parse(pca.value);
      });
    });

    return cust;
  }

  public setChangedAt(document: IDocument): void {
    // @ts-ignore
    document.statusChangedAt = dayjs(new Date()).format(ZONED_DATE_TIME_SERVER_FORMAT);
  }

  public isLoadingAll(): boolean {
    return this.isLoadingDocuments || this.isLoadingTaskData || this.isLoadingData || this.isLoadingInvoices || this.isLoadingActivities;
  }

  public isSavingAll(): boolean {
    return this.isSaving || this.isSavingTaskData;
  }

  public addNewFile(): void {
    // @ts-ignore
    this.newDocument.createdAt = dayjs(new Date()).format(ZONED_DATE_TIME_SERVER_FORMAT);

    if (!this.isCreate) {
      this.isLoadingDocuments = true;
      this.documentService()
        .create(this.newDocument)
        .then(res => {
          this.newDocument = res;
          this.addDocumentToData(this.newDocument);

          this.isLoadingDocuments = false;
        })
        .catch(error => {
          this.isLoadingDocuments = false;
          this.alertService().showHttpError(this, error.response);
        });
    } else {
      this.addDocumentToData(this.newDocument);
    }
  }

  public mandatoryFieldsSet(): boolean {
    return this.customer?.contractPerson?.lastName != null && this.customer?.contractPerson?.firstName != null;
  }

  public save(): void {
    this.isSaving = true;
    if (!this.mandatoryFieldsSet()) {
      const fields = 'Vertragspartner - Name + Vorname';
      const message = this.$t('care4YouApp.customerDetail.messages.mandatoryFields', { fields: fields });
      (this.$root as any).$bvToast.toast(message.toString(), {
        toaster: 'b-toaster-top-center',
        title: 'Fehler',
        variant: 'danger',
        solid: true,
        autoHideDelay: 5000,
      });
      this.isSaving = false;
      return;
    }
    this.questionnaireService()
      .createFull(this.prepareData())
      .then(param => {
        this.isSaving = false;
        let message = null;
        if (this.withProcess === true) {
          this.replace('Postbox', null);
          message = this.$t('care4YouApp.questionnaire.created', { param: param.id });
        } else {
          this.customerCreatedWithoutProcess = true;
          this.callParams = { customerId: param.id, tabIndex: 0 };

          this.replace('CustomerDetail', { customerId: param.id, tabIndex: 0 });
          message = this.$t('care4YouApp.customer.created', { param: param.id });
        }
        (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-bottom-right',
          title: 'Success',
          variant: 'success',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.isSaving = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public validDataForNewDoc(): boolean {
    return (
      this.newDocument.documentName != null &&
      this.newDocument.documentName !== '' &&
      this.newDocument.documentBinaries != null &&
      this.newDocument.documentBinaries !== '' &&
      this.newDocument.documentBinariesContentType != null &&
      this.newDocument.documentBinariesContentType !== ''
    );
  }

  public mergeUpdatedInvoice(updatedInvoice) {
    let indexToReplace = -1;
    this.invoices.forEach((value, index) => {
      if (value.id == updatedInvoice.id) {
        indexToReplace = index;
      }
    });
    if (indexToReplace >= 0) {
      this.invoices.splice(indexToReplace, 1, updatedInvoice);
    }
  }

  public afterActivityRemoval(activity: IActivity, index: number) {
    this.activities.splice(index, 1);
  }

  public afterSalaryRemoval(salary: ISalary, index: number) {
    this.salaries.splice(index, 1);
  }

  public safeFile(document: Document) {
    this.isLoadingDocuments = true;
    this.documentService()
      .update(document)
      .then(res => {
        this.newDocument = res;

        const message = this.$t('care4YouApp.customer.updated', { param: res.parentKey });
        (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });

        this.isLoadingDocuments = false;
      })
      .catch(error => {
        this.isLoadingDocuments = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public deleteFile(document: Document) {
    this.isLoadingDocuments = true;
    this.documentService()
      .delete(document.id)
      .then(res => {
        const message = this.$t('care4YouApp.document.deleted', { param: document.id });
        this.removeDocumentFromList(document.id);
        (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });

        this.isLoadingDocuments = false;
      })
      .catch(error => {
        this.isLoadingDocuments = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public deleteFileByIndex(index: number) {
    this.documents.splice(index, 1);
  }

  protected removeDocumentFromList(id): void {
    this.documents = this.documents.filter(value => value.id != id);
  }

  protected addDocumentToData(doc) {
    this.documents.push(this.newDocument);

    const newDoc = new Document();
    newDoc.documentName = 'Neues Dokument';
    newDoc.parentKey = this.customer.id;
    newDoc.parentType = EntityType.CUSTOMER;
    this.newDocument = newDoc;
    (<any>this).$refs['document_file_new'].value = null;
  }

  public generateDynamicFieldAndAdd(patientId) {
    const newField: IDynamicField = {
      fieldKey: 'QUESTIONNAIRE',
      fieldValue: uuidv4(),
      datatype: FieldType.STRING,
    };
    this.patientService()
      .addDynamicFieldsToPatient(patientId, [newField])
      .then(res => {
        this.loadDynamicFields(this.customerId);

        this.settingService()
          .getBaseUrl()
          .then(res2 => {
            const h = this.$createElement;

            const link = '/online-fragebogen/' + res[0].fieldValue;
            const baseText = this.$t('care4YouApp.attribute.QUESTIONNAIRE_CREATED').toString();

            // Create the custom close button
            const toastContent = h('span', [
              baseText,
              h(
                'a',
                {
                  attrs: {
                    href: link,
                    target: '_blank',
                  },
                },
                res2 + link
              ),
            ]);

            // Create the toast
            (this.$root as any).$bvToast.toast([toastContent], {
              toaster: 'b-toaster-top-center',
              title: 'Info',
              variant: 'info',
              noAutoHide: true,
              solid: true,
            });
          })
          .catch(error2 => {
            this.alertService().showHttpError(this, error2.response);
          });
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public addContactPerson(): void {
    this.customer.contactPersons.push(deepCopy(this.contactPersonTemplate));
  }

  public addPatient(): void {
    this.customer.patients.push(deepCopy(this.patientTemplate));
  }

  public removeContactPerson(index: number): void {
    this.customer.contactPersons.splice(index, 1);
  }

  public addCommunication(contactIndex: number): void {
    if (this.customer.contactPersons[contactIndex].contact.communications == null) {
      this.customer.contactPersons[contactIndex].contact.communications = [];
    }
    this.customer.contactPersons[contactIndex].contact.communications.push(new Communication());
  }

  public removeCommunication(contactIndex: number, comIndex: number): void {
    this.customer.contactPersons[contactIndex].contact.communications.splice(comIndex, 1);
  }

  public addTask(): void {
    this.push('CreateCustomerTask', {
      customerId: this.customerId,
      customerName: this.customer.contractPerson.firstName + ' ' + this.customer.contractPerson.lastName,
    });
  }

  public transferDataFromContractPersonToPatient(patient: IPatient) {
    if (this.customer.contractPerson) {
      patient.contact.title = this.customer.contractPerson.title;
      patient.contact.firstName = this.customer.contractPerson.firstName;
      patient.contact.lastName = this.customer.contractPerson.lastName;
      patient.contact.gender = this.customer.contractPerson.gender;
      patient.contact.salutation = this.customer.contractPerson.salutation;
    }
  }

  private sortCustomerUserTasks() {
    const result = [this.customerUserTaskPropOrder + ',' + (this.customerUserTaskReverse ? 'desc' : 'asc')];
    if (this.customerUserTaskPropOrder !== 'id') {
      result.push('id');
    }

    return result;
  }

  public switchCarer(): void {
    this.carerSwitch = new CarerSwitch();
    // @ts-ignore
    this.carerSwitch.endDate = dayjs(new Date()).format('YYYY-MM-DD');
    this.carerSwitch.note = '';

    this.$bvModal.show('switchCarer');
  }

  public closeSwitchCarerDialog(): void {
    this.$bvModal.hide('switchCarer');
  }

  protected loadCustomerFlags(customerId): void {
    this.customerDetailService()
      .retrieveFlags(customerId)
      .then(param => {
        this.isCarerSwitchRunning = param['CARER_SWITCH_RUNNING'];
      })
      .catch(error => {
        this.isLoadingData = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public terminateCareAssignment(careAssignment: ICareAssignment): void {
    this.careAssignmentTermination = new CareAssignmentTermination();
    // @ts-ignore
    this.careAssignmentTermination.terminationDate = dayjs(careAssignment.departureAt ? careAssignment.departureAt : new Date()).format(
      'YYYY-MM-DD'
    );
    this.careAssignmentTermination.userTaskDate = JSON.stringify(dayjs(new Date()).format('YYYY-MM-DD'));
    this.careAssignmentTermination.note = '';
    this.careAssignmentTermination.terminationReason = CareTerminationReason.CANCELLATION;
    this.careAssignmentTermination.careAssignmentId = careAssignment.id;

    this.showTerminateCareAssignment = true;

    this.$bvModal.show('terminateCareAssignment');
  }

  public deleteCareAssignment(careAssignment: ICareAssignment): void {
    this.careAssignmentForDeletion = careAssignment;
    this.$bvModal.show('deleteCareAssignment');
  }

  public closeTerminateCareAssignmentDialog(): void {
    this.$bvModal.hide('terminateCareAssignment');
  }

  public closeDeleteCareAssignmentDialog(): void {
    this.$bvModal.hide('deleteCareAssignment');
  }

  public doTerminateCareAssignment(): void {
    const parameters = {
      USER_TASK_DUE_DATE: this.careAssignmentTermination.userTaskDate,
      CARE_ASSIGNMENT_ID: this.careAssignmentTermination.careAssignmentId,
      CUSTOMER_ID: this.customerId,
      TERMINATION_DATE: JSON.stringify(this.careAssignmentTermination.terminationDate),
      TERMINATION_NOTE: JSON.stringify(this.careAssignmentTermination.note),
      TERMINATION_REASON: JSON.stringify(this.careAssignmentTermination.terminationReason),
      TERMINATION_REASON_TEXT: JSON.stringify(
        this.$t('care4YouApp.careTerminationReason.' + this.careAssignmentTermination.terminationReason).toString()
      ),
    };

    this.bpmProcessManagementService()
      .startProcess('terminate-care-assignment', parameters)
      .then(res => {
        this.closeTerminateCareAssignmentDialog();

        (this.$root as any).$bvToast.toast(this.$t('care4YouApp.postbox.process.started', { processid: res }), {
          toaster: 'b-toaster-bottom-right',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public doDeleteCareAssignment(): void {
    this.careAssignmentService()
      .delete(this.careAssignmentForDeletion.id)
      .then(res => {
        this.closeDeleteCareAssignmentDialog();

        (this.$root as any).$bvToast.toast(this.$t('care4YouApp.careAssignment.deleted', { param: this.careAssignmentForDeletion.id }), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
        this.careAssignmentForDeletion = null;
        this.doInitialization(this, this.callParams, '');
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public userHasManagerRole(): boolean {
    const authorities = 'ROLE_MANAGER';
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        if (this.hasAnyAuthorityValues[authorities] !== value) {
          this.hasAnyAuthorityValues = { ...this.hasAnyAuthorityValues, [authorities]: value };
        }
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }

  protected prepareData(): any {
    const custcopy = JSON.parse(JSON.stringify(this.customer)) as typeof this.customer;

    let processVariables = [
      new ProcessVariable('WITH_PROCESS', this.withProcess),
      new ProcessVariable('ONLINE_QUESTIONNAIRE', this.onlineQuestionnaire),
    ];

    if (this.onlineQuestionnaire) {
      let uuids = [];
      if (custcopy.patients.length > 0) {
        for (const currPatient of custcopy.patients) {
          currPatient.careAttributes.forEach(function (pca: PatientCareAttribute) {
            if (pca.attribute.valueType !== ValueType.STRING) {
              pca.value = JSON.stringify(pca.value);
            }
          });

          uuids.push(uuidv4());
        }
      }

      processVariables.push(new ProcessVariable('UUIDS', uuids));
      this.settingService()
        .getBaseUrl()
        .then(res => {
          for (let uuid of uuids) {
            const h = this.$createElement;

            const link = '/online-fragebogen/' + uuid;
            const baseText = this.$t('care4YouApp.attribute.QUESTIONNAIRE_CREATED').toString();

            // Create the custom close button
            const toastContent = h('span', [
              baseText,
              h(
                'a',
                {
                  attrs: {
                    href: link,
                    target: '_blank',
                  },
                },
                res + link
              ),
            ]);

            // Create the toast
            (this.$root as any).$bvToast.toast([toastContent], {
              toaster: 'b-toaster-top-center',
              title: 'Info',
              variant: 'info',
              noAutoHide: true,
              solid: true,
            });
          }
        })
        .catch(error2 => {
          this.alertService().showHttpError(this, error2.response);
        });
    }

    const dataObj = this.buildDataObject(
      true,
      processVariables,
      [new EntityData('Customer', [custcopy]), new EntityData('Document', this.documents)],
      []
    );

    return dataObj;
  }

  public loadDynamicFields(customerId): void {
    this.customerDetailService()
      .getPatientsDynamicFields(customerId)
      .then(res => {
        this.dynamicFields = res.data;
        this.hasQuestionnaire = [];

        if (this.dynamicFields) {
          for (const currDynamicField in this.dynamicFields) {
            this.setHasRunningQuestionnaire(currDynamicField);
          }
        }

        this.isLoadingData = false;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
        this.isLoadingData = false;
      });
  }

  public setHasRunningQuestionnaire(patientId): void {
    if (this.dynamicFields && this.dynamicFields[patientId]) {
      for (var currDynamicField of this.dynamicFields[patientId]) {
        if (currDynamicField.fieldKey === 'QUESTIONNAIRE') {
          this.hasQuestionnaire[patientId] = true;
          return;
        }
      }
    }

    this.hasQuestionnaire[patientId] = false;
  }

  public isNewCustomerActivity(activity): boolean {
    return activity?.type == 'NEW_CUSTOMER_PROCESS_PROTOCOL';
  }

  public stopQuestionnaire(patientId): void {
    this.patientService()
      .deleteDynamicField(patientId, 'QUESTIONNAIRE')
      .then(res => {
        this.loadDynamicFields(this.customerId);

        (this.$root as any).$bvToast.toast(this.$t('care4YouApp.attribute.QUESTIONNAIRE_STOPPED'), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }
}
