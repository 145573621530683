export enum EntityType {
  CUSTOMER = 'CUSTOMER',

  CARER = 'CARER',

  PATIENT = 'PATIENT',

  APPLICATIONUSER = 'APPLICATIONUSER',

  CAREASSIGNMENT = 'CAREASSIGNMENT',

  CARESUGGESTION = 'CARESUGGESTION',

  AGENCY = 'AGENCY',

  CONTACT = 'CONTACT',

  USERTASK = 'USERTASK',

  EMAIL = 'EMAIL',
}
