import { Component, Inject, Provide, Vue } from 'vue-property-decorator';
import AlertService from '@/shared/alert/alert.service';
import { ISalary } from '@/shared/model/salary.model';
import SalaryService from '@/entities/salary/salary.service';

/**
 * An utility service.
 */
@Component
export default class SalaryUtils extends Vue {
  public editSalary: ISalary = null;
  public salaries: ISalary[] = [];
  public editSalaryCustomer = null;
  public showEditSalary = false;

  @Inject('alertService') protected alertService: () => AlertService;
  protected editSalaryIndex = -1;

  public prepareEditSalary(instance: ISalary, index: number): void {
    this.editSalaryIndex = index;
    //create copy to not modify reference in table
    this.editSalary = JSON.parse(JSON.stringify(instance)) as typeof instance;
    this.showEditSalary = true;
  }

  public confirmSalaryRemoval(instance: ISalary, index: number, cb: (data: ISalary, index: number) => any): void {
    this.$bvModal
      .msgBoxConfirm(this.$t('care4YouApp.salary.delete.question', { id: instance.id }).toString(), {
        title: this.$t('entity.delete.title').toString(),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('entity.delete.yes').toString(),
        cancelTitle: this.$t('entity.delete.no').toString(),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if (value === true) {
          this.salaryService()
            .delete(instance.id)
            .then(
              res => {
                if (cb) {
                  cb(instance, index);
                }
                const message = this.$t('care4YouApp.salary.deleted', { param: instance.id });
                (this.$root as any).$bvToast.toast(message.toString(), {
                  toaster: 'b-toaster-top-center',
                  title: 'Info',
                  variant: 'info',
                  solid: true,
                  autoHideDelay: 5000,
                });
              },
              err => {
                this.alertService().showHttpError(this, err.response);
              }
            );
        }
      })
      .catch(err => {
        // An error occurred
      });
  }

  public showModalForNewSalary(custId: number, newSalary): void {
    this.editSalaryIndex = -1;
    this.editSalary = newSalary;
    this.editSalaryCustomer = custId;
    this.showEditSalary = true;
  }

  public replaceWhiteSpace(text): string {
    return text.replace('\\n', '\n');
  }

  public updateSalary(instance: ISalary, cb: (data: ISalary) => any): void {
    this.salaryService()
      .update(instance)
      .then(
        res => {
          if (cb) {
            cb(res);
          }
          const message = this.$t('care4YouApp.salary.updated', { param: res.id });
          (this.$root as any).$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'info',
            solid: true,
            autoHideDelay: 5000,
          });
        },
        err => {
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public saveEditSalary(): void {
    const salaryCopy = JSON.parse(JSON.stringify(this.editSalary)) as typeof this.editSalary;
    if (this.editSalary.id) {
      //update
      this.salaryService()
        .update(salaryCopy)
        .then(
          res => {
            const message = this.$t('care4YouApp.salary.updated', { param: this.editSalary.id });
            (this.$root as any).$bvToast.toast(message.toString(), {
              toaster: 'b-toaster-top-center',
              title: 'Info',
              variant: 'info',
              solid: true,
              autoHideDelay: 5000,
            });
            this.resetEditSalary(res);
            this.closeEditSalaryDialog();
          },
          err => {
            this.alertService().showHttpError(this, err.response);
          }
        );
    } else {
      //create
      this.salaryService()
        .create(this.editSalaryCustomer, salaryCopy)
        .then(
          res => {
            this.resetEditSalary(res);
            this.closeEditSalaryDialog();
            this.loadSalaries();
          },
          err => {
            this.alertService().showHttpError(this, err.response);
          }
        );
    }
  }

  public loadSalaries() {}

  public closeEditSalaryDialog(): void {
    this.editSalary = null;
  }

  public getPropertyOfObject(obj, propName): string {
    if (obj.hasOwnProperty(propName)) {
      return obj[propName];
    }
    return '';
  }

  @Provide('salaryService') public salaryService = () => new SalaryService();

  private resetEditSalary(updatedSalary) {
    if (this.editSalaryIndex >= 0) {
      this.salaries[this.editSalaryIndex] = updatedSalary;
    } else {
      this.salaries.push(updatedSalary);
    }
    this.editSalary = null;
    this.editSalaryIndex = -1;
  }
}
