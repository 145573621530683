import { Authority } from '@/shared/security/authority';

const CustomerOverview = () => import('@/customer-overview/customer-overview.vue');
const CustomerDetail = () => import('@/customer-detail/customer-detail.vue');
const CustomerContractPersonUpdateComponent = () => import('@/customer-detail/customer-contract-person-update.vue');
const CustomerContactPersonUpdateComponent = () => import('@/customer-detail/customer-contact-person-update.vue');
const CustomerCoreUpdateComponent = () => import('@/customer-detail/customer-core-update.vue');
const CustomerPatientUpdateComponent = () => import('@/customer-detail/customer-patient-update.vue');
const CustomerCareAssignmentUpdateComponent = () => import('@/customer-detail/customer-care-assignment-update.vue');
const HandleCareAssignment = () => import('@/handle-care-assignment/handle-care-assignment.vue');

// prettier-ignore
export default [
  {
    path: '/customer-overview',
    name: 'CustomerOverview',
    component: CustomerOverview,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/customer-create/onlineQuestionnaire',
    name: 'CreateOnlineQuestionnaire',
    component: CustomerDetail,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/customer-create/:withProcess?',
    name: 'CreateCustomer',
    component: CustomerDetail,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/customer-detail/:customerId/:tabIndex/:contactPersonId/:patientId',
    name: 'CustomerDetail',
    component: CustomerDetail,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/customer-detail/:customerId/editCustomerCore',
    name: 'CustomerCoreUpdate',
    component: CustomerCoreUpdateComponent,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/customer-detail/:customerId/editContractPerson',
    name: 'CustomerContractPersonUpdate',
    component: CustomerContractPersonUpdateComponent,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/customer-detail/:customerId/editContactPerson/:contactPersonId',
    name: 'CustomerContactPersonUpdate',
    component: CustomerContactPersonUpdateComponent,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/create-care-assignments-for-customer/:customerId',
    name: 'CreateCareAssignments',
    component: HandleCareAssignment,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/customer-detail/:customerId/createCareAssignment',
    name: 'CreateCareAssignment',
    component: CustomerCareAssignmentUpdateComponent,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/customer-detail/:customerId/editCareAssignment/:careAssignmentId',
    name: 'EditCareAssignment',
    component: CustomerCareAssignmentUpdateComponent,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/customer-detail/:customerId/editPatient/:patientId',
    name: 'CustomerPatientUpdate',
    component: CustomerPatientUpdateComponent,
    meta: { authorities: [Authority.USER] }
  },
  {
    path: '/customer-detail/:customerId/addPatient',
    name: 'CustomerPatientAdd',
    component: CustomerPatientUpdateComponent,
    meta: { authorities: [Authority.USER] }
  }
];
