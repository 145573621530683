export enum DocumentType {
  CONTRACT = 'CONTRACT',
  ID = 'ID',
  INVOICE = 'INVOICE',
  MINIJOB = 'MINIJOB',
  OTHER = 'OTHER',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  TAX = 'TAX',
  TEMPLATE = 'TEMPLATE',
}
